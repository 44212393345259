<template>
  <v-container app class="my-0" fluid>
    <v-app id="inspire">
      <v-alert
          v-model="showIncompleteFormAlert"
          text
          border="left"
          dense
          color="red"
          icon="mdi-alert"
      >
        In order to submit, please ensure valid serial numbers have been entered or attach electronic on-hand inventory
        reports with your claim.
      </v-alert>
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step step="1" :complete="e1 > 1" class="py-0">
            {{ $t('labels.draft') }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2" :complete="e1 > 2" class="py-0">
            {{ $t('labels.submitted') }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3" :complete="e1 >= 3" class="py-0">
            {{ getVerdict }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1" class="pt-0 pb-4">
            <DealerDraftRequest
                @advance-step="e1 += 1"
                @loading="showOverlay = true"
                @loaded="showOverlay = false"
                @show-incomplete-form-alert="showIncompleteFormAlert = true"
                @hide-incomplete-form-alert="showIncompleteFormAlert = false"
            ></DealerDraftRequest>
          </v-stepper-content>
          <v-stepper-content step="2">
            <DealerSubmittedRequest
                @loading="showOverlay = true"
                @loaded="showOverlay = false"></DealerSubmittedRequest>
          </v-stepper-content>
          <v-stepper-content step="3">
            <DealerApprovedRequest
                @loading="showOverlay = true"
                @loaded="showOverlay = false"></DealerApprovedRequest>
          </v-stepper-content>
          <v-stepper-content step="4">
            <DealerRejectedRequest
                @loading="showOverlay = true"
                @loaded="showOverlay = false"></DealerRejectedRequest>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-app>

    <v-alert
        class="mt-4"
        v-model="showIncompleteFormAlert"
        text
        border="left"
        dense
        color="red"
        icon="mdi-alert"
    >
      In order to submit, please ensure valid serial numbers have been entered or attach electronic on-hand inventory
      reports with your claim.
    </v-alert>

    <v-overlay
        :opacity="0.75"
        :value="showOverlay"
    >
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import {storeToRefs} from "pinia";
import _ from "lodash";
import DealerDraftRequest from "@/components/DealerDraftRequest";
import {useDealerStore} from "@/stores/dealer";
import DealerSubmittedRequest from "@/components/DealerSubmittedRequest";
import DealerApprovedRequest from "@/components/DealerApprovedRequest";
import DealerRejectedRequest from "@/components/DealerRejectedRequest";

export default {
  name: "DealerProtectionRequest",
  components: {
    DealerSubmittedRequest,
    DealerDraftRequest,
    DealerApprovedRequest,
    DealerRejectedRequest,
  },
  setup() {
    const dealerStore = useDealerStore();
    let {activePlan, activeRequest} = storeToRefs(dealerStore);

    console.log("ProtectionRequest setup");

    return {
      activePlan,
      activeRequest,
      dealerStore
    };
  },
  computed: {
    getVerdict() {
      const t = this.$t.bind(this);
      if (!_.isEmpty(useDealerStore().activeRequest)) {
        const activeGroup = this.dealerStore.activeRequest.requestStatus;

        if (activeGroup !== null) {
          switch (activeGroup) {
            case 3:
              return t('labels.audited');
            case 4:
              return t('labels.approved');
            case 5:
              return t('labels.transferred');
            case 6:
              return t('labels.rejected');
            case 7:
              return t('labels.transferfailed');
            default:
              return t('labels.processing');
          }
        }
      }
      return t('labels.processing');
    }
  },
  async mounted() {
    this.updateStep();
  },
  data() {
    let stepNum = 1; // case DRAFT
    let showOverlay = false;
    let showIncompleteFormAlert = false;
    if (!_.isEmpty(useDealerStore().activeRequest)) {
      const activeGroup = this.dealerStore.activeRequest.requestStatus;

      if (activeGroup !== null) {
        switch (activeGroup) {
          case 1:
            stepNum = 1;
            break;
          case 2:
            stepNum = 2;
            break;
          case 3:
          case 4:
          case 5:
          case 7:
            // audited
            // approved
            stepNum = 3;
            break;
          case 6:
            // rejected
            stepNum = 4;
            break;
        }
      }
    }

    return {
      e1: stepNum,
      showIncompleteFormAlert,
      showOverlay
    };
  },
  methods: {
    updateStep() {
      let stepNum = 1; // case DRAFT
      if (!_.isEmpty(this.dealerStore.activeRequest)) {
        const status = this.dealerStore.activeRequest.requestStatus;

        if (status !== null) {
          switch (status) {
            case 1:
              stepNum = 1;
              break;
            case 2:
              stepNum = 2;
              break;
            case 3:
            case 4:
            case 5:
            case 7:
              // approved
              stepNum = 3;
              break;
            case 6:
              // rejected
              stepNum = 4;
              break;
          }
        }
      }
      this.e1 = stepNum;
    }
  }
};
</script>