<template>
  <div v-if="activeRequest !== null">
    <v-card class="my-0" elevation="0">
      <v-list-item class="pr-0">
        <v-list-item-content class="pb-0">
          <div class="text-overline mb-1">Price Protection Request</div>

          <v-list-item-subtitle class="mt-0 mb-3">
            {{ $t('labels.pprNo') }} {{ request.id }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="mt-1" v-if="request.dateSubmitted">
            {{ $t('labels.submittedBy') }} {{ request.createdByUser }} on {{ request.dateSubmitted }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="mt-1" v-if="request.lastModifiedByUser !== null">
            {{ $t('labels.lastModifiedBy') }} {{ request.lastModifiedByUser }} on {{ request.dateLastModified }}
          </v-list-item-subtitle>

          <v-list-item-title class="mt-4">
            <v-text-field
                :label="$t('labels.protectionPlan')"
                v-model="request.planName"
                readonly
            ></v-text-field>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="pt-0 mt-0">
        <v-text-field
            :label="$t('labels.dealerName')"
            class="pt-0 mt-0"
            v-model="request.dealerName"
            readonly
        ></v-text-field>
      </v-list-item>
      <v-list-item class="pt-0 mt-0">
        <v-text-field
            :label="$t('labels.customerCode')"
            class="pt-0 mt-0"
            v-model="request.customerCode"
            min=0
            max=6
            readonly
        ></v-text-field>
      </v-list-item>
      <v-list-item class="pt-0 mt-0">
        <v-text-field
            :label="$t('labels.dealerReferenceNumber')"
            class="pt-0 mt-0"
            v-model="request.dealerReferenceNo"
            readonly
        ></v-text-field>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="py-0 my-0 mx-0 px-0">
          <v-row>
            <v-col cols="12" sm="6">
              <v-menu
                  ref="dateMenu"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="formattedStartDate"
                      :label="$t('labels.startDate')"
                      prepend-icon="mdi-calendar-month-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
              </v-menu>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pt-3 pb-9 my-0 mx-0 px-0">
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn-toggle
                  v-model="activeRequest.taxRateType"
                  color="red darken-2"
                  :disabled="true"
              >
                <v-btn :value="0">
                  {{ $t("buttons.taxExempt") }}
                </v-btn>

                <v-btn :value="1" :disabled="true">
                  {{ $t("buttons.oldRate") }}
                </v-btn>

                <v-btn :value="2" :disabled="true">
                  {{ $t("buttons.s21Default") }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <v-data-table
        :headers="getHeaders"
        :items="request.items"
        sort-by="itemCode"
        class="elevation-0 mb-5"
    >
      <template v-slot:top v-if="activeRequest.items.length > 0">
        <v-spacer></v-spacer>

        <v-dialog v-model="serialNumberDialog" persistent max-width="500px">
          <v-card>
            <v-card-title>Serial Numbers for {{ editedItem.description }} ({{
                editedItem.itemCode
              }})
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-data-table
                    :headers="getSnHeader"
                    :items="activeRequest.items[editedIndex === -1 ? 0 : editedIndex].serialNumbers">
                  <template v-slot:[`item.value`]="{ item }">
                    <span>{{ item.value }}</span>
                  </template>
                </v-data-table>

                <v-card
                    class="d-flex pt-5"
                    flat
                >
                  <v-spacer></v-spacer>
                  <div>
                    <v-btn @click="serialNumberDialog = false">CLOSE</v-btn>
                  </div>
                </v-card>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:[`item.invoicePrice`]="{ item }">
        <div class="d-flex justify-space-evenly bg-surface-variant">
          {{ item.invoicePrice | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.newPrice`]="{ item }">
        <div class="d-flex justify-space-evenly bg-surface-variant">
          {{ item.newPrice | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.lastPromo`]="{ item }">
        <div>
          {{ item.lastPromo | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.newPromo`]="{ item }">
        <div>
          {{ item.newPromo | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.discountPercentage`]="{ item }">
        <div class="d-flex justify-space-evenly bg-surface-variant">
          {{ item.discountPercentage + "%" }}
        </div>
      </template>

      <template v-slot:[`item.differencePerUnit`]="{ item }">
        <div class="d-flex justify-space-evenly bg-surface-variant">
          {{ item.differencePerUnit | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.total`]="{ item }">
        <div class="d-flex justify-space-evenly bg-surface-variant">
          {{ item.total | toCAD }}
        </div>
      </template>

      <template v-slot:footer>
        <v-divider></v-divider>
        <v-card
            class="d-flex pr-4"
            elevation="0"
        >
          <v-spacer></v-spacer>
          <div>
            <v-chip label color="lightgrey darken-1" text-color="black" class="font-weight-medium my-3">
              Grand Total
              (CAD): {{ request.grandTotal | toCAD }}
            </v-chip>
          </div>
        </v-card>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
            small
            :title="$t('iconTitles.viewSerialNos')"
            class="mr-2"
            @click="viewSerialNumbers(item)"
            :disabled="item.serialNumbers.length === 0"
        >
          mdi-clipboard-list-outline
        </v-icon>
        <v-icon small :title="$t('iconTitles.seeFax')" class="mr-2" v-if="activeRequest.snEntryMethod === 2">
          mdi-fax
        </v-icon>
      </template>
    </v-data-table>

    <v-divider></v-divider>

    <div>
      <v-card-text>
        <div class="text-overline">
          Attachments
          <v-tooltip
              top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  left
                  size="large"
                  v-bind="attrs"
                  v-on="on"
                  @click="downloadAttachments"
                  :disabled="!!activeRequest.attachmentFilePath === false"
              >
                mdi-download
              </v-icon>
            </template>
            <span>Download attachments zip</span>
          </v-tooltip>
        </div>

        <v-chip-group column v-if="!!activeRequest.attachmentFilePath">
          <div
              v-for="file in activeRequest.attachmentFilePath.split('|')"
              :key="file"
          >
            <v-chip
                class="my-2 text-h7 mx-2"
                color="#c0c4c6"
                label>
              {{ file }}
            </v-chip>
          </div>
        </v-chip-group>
        <div v-else
             class="my-2 text-h7 mx-2">
          No attachments available
        </div>
      </v-card-text>

      <v-divider></v-divider>
    </div>

    <v-container fluid class="pt-5 px-0">
      <v-textarea
          v-model="request.auditorsComments"
          counter
          filled
          auto-grow
          readonly
          :label="$t('labels.auditorsComments')"
      ></v-textarea>
    </v-container>
    <v-container fluid class="pt-0 px-0">
      <v-textarea
          v-model="request.approversComments"
          counter
          filled
          auto-grow
          readonly
          :label="$t('labels.approversComments')"
      ></v-textarea>
    </v-container>
    <v-container fluid class="pt-0 px-0">
      <v-textarea
          v-model="request.invoiceComments"
          counter
          filled
          auto-grow
          readonly
          :label="$t('labels.invoiceComments')"
      ></v-textarea>
    </v-container>
    <v-container fluid class="pt-0 px-0">
      <v-textarea
          v-model="activeRequest.internalComments"
          counter
          filled
          auto-grow
          :label="$t('labels.internalComments')"
      ></v-textarea>
    </v-container>
    <v-container fluid class="pt-0 px-0">
      <v-textarea
          v-model="request.commentsHistory"
          counter
          filled
          auto-grow
          readonly
          :label="$t('labels.commentsHistory')"
      ></v-textarea>
    </v-container>

    <v-divider></v-divider>

    <v-row class="pt-5 pb-3">
      <v-col cols="12" class="text-center">
        <v-btn @click="printWindow" :titles="$t('linkTitles.printWindow')" class="mr-4 mb-4">
          {{ $t("buttons.print") }}
        </v-btn>
        <v-btn class="mr-4 mb-4">
          <router-link to="/existing-requests" :title="$t('linkTitles.backToExistingRequests')"
                       class="text-decoration-none">
            {{ $t("buttons.back") }}
          </router-link>
        </v-btn>
      </v-col>
    </v-row>
  </div>
  <div v-else>

  </div>
</template>

<script>
import {storeToRefs} from "pinia";
import {useExistingRequestsStore} from "@/stores/existingrequests";
import {defineComponent} from "vue-demi";
import {useUserStore} from "@/stores/user";

export default defineComponent({
  name: "RequestApproved",
  setup() {
    const requestsStore = useExistingRequestsStore();
    const {activeRequest} = storeToRefs(requestsStore);
    const userStore = useUserStore();
    const {isApprover} = storeToRefs(userStore);

    console.log("RequestApproved setup");

    return {
      activeRequest,
      requestsStore,
      isApprover
    };
  },
  data: () => ({
    serialNumberDialog: false,
    dateMenu: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    editedIndex: -1,
    editedItem: {},
    request: {
      auditorsComments: "",
      approversComments: "",
      invoiceComments: "",
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    formattedStartDate() {
      return (this.activeRequest.startDate.split(" "))[0];
    },
    getHeaders() {
      const t = this.$t.bind(this)

      if (this.activeRequest.items.length < 1) {
        return [];
      } else if ((this.activeRequest.items[0].newPrice === 0 && this.activeRequest.items[0].invoicePrice === 0) ||
          (this.activeRequest.items[0].newPrice === null && this.activeRequest.items[0].invoicePrice === null)) {
        return [
          {text: t('headers.qty'), value: "qtyOnHand"},
          {
            text: t('headers.itemCode'),
            align: "start",
            value: "itemCode",
          },
          {text: t('headers.description'), value: "description"},
          {text: t('headers.creditCode'), value: "creditCode"},
          {text: t('headers.lastPromoCad'), value: "lastPromo"},
          {text: t('headers.newPromoCad'), value: "newPromo"},
          {text: t('headers.diffUnitCad'), value: "differencePerUnit"},
          {text: t('headers.totalCad'), value: "total"},
          {text: t('headers.actions'), value: "actions", sortable: false},
        ]
      } else {
        return [
          {text: t('headers.qty'), value: "qtyOnHand"},
          {
            text: t('headers.itemCode'),
            align: "start",
            value: "itemCode",
          },
          {text: t('headers.description'), value: "description"},
          {text: t('headers.creditCode'), value: "creditCode"},
          {text: t('headers.invoicePriceCad'), value: "invoicePrice"},
          {text: t('headers.newPriceCad'), value: "newPrice"},
          {text: t('headers.discountPercentage'), value: "discountPercentage"},
          {text: t('headers.diffUnitCad'), value: "differencePerUnit"},
          {text: t('headers.totalCad'), value: "total"},
          {text: t('headers.actions'), value: "actions", sortable: false},
        ]
      }
    },
    getSnHeader() {
      const t = this.$t.bind(this)
      return [
        {text: t('headers.serialNumber'), value: "value"}
      ]
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.request = this.activeRequest;
    },
    viewSerialNumbers(item) {
      this.editedIndex = this.activeRequest.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.serialNumberDialog = true;
    },
    async downloadAttachments() {
      await this.requestsStore.downloadAttachment();
    },
    printWindow() {
      window.print();
    }
  },
});
</script>