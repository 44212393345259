<template>
  <div v-if="activeRequest !== null">
    <v-form
        ref="form"
        v-model="valid">
      <v-card class="my-4" elevation="0">
        <v-list-item class="mb-2">
          <v-list-item-content class="pt-0">
            <div class="text-overline mb-1">Price Protection Request</div>

            <v-list-item-subtitle class="mt-0 mb-3">
              {{ $t('labels.pprNo') }} {{ activeRequest.id }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="mt-1" v-if="activeRequest.dateSubmitted">
              {{ $t('labels.submittedBy') }} {{ activeRequest.createdByUser }} on {{ activeRequest.dateSubmitted }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="mt-1" v-if="activeRequest.lastModifiedByUser !== null">
              {{ $t('labels.lastModifiedBy') }} {{ activeRequest.lastModifiedByUser }} on
              {{ activeRequest.dateLastModified }}
            </v-list-item-subtitle>

          </v-list-item-content>
        </v-list-item>
        <v-list-item
        >
          <v-text-field
              :label="$t('labels.protectionPlan')"
              v-model="activeRequest.planName"
              :rules="requiredRule"
              readonly
          ></v-text-field>
        </v-list-item>
        <v-list-item class="pt-0 mt-0">
          <v-text-field
              :label="$t('labels.dealerName')"
              class="pt-0 mt-0"
              v-model="activeRequest.dealerName"
              :rules="requiredRule"
              readonly
          ></v-text-field>
        </v-list-item>
        <v-list-item class="pt-0 mt-0">
          <v-text-field
              :label="$t('labels.customerCode')"
              class="pt-0 mt-0"
              v-model="activeRequest.customerCode"
              maxlength="6"
              :rules="requiredRule"
          ></v-text-field>
        </v-list-item>
        <v-list-item class="pt-0 mt-0">
          <v-text-field
              :label="$t('labels.dealerReferenceNumber')"
              class="pt-0 mt-0"
              v-model="activeRequest.dealerReferenceNo"
              :counter="21"
              :rules="[rules.max21Chars]"
          ></v-text-field>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="py-0 my-0 mx-0 px-0">
            <v-row>
              <v-col cols="12" sm="6">
                <v-menu
                    ref="dateMenu"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="formattedStartDate"
                        :label="$t('labels.startDate')"
                        prepend-icon="mdi-calendar-month-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="activeRequest.startDate"
                      no-title
                      scrollable
                      @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="py-3 my-0 mx-0 px-0">
            <v-row>
              <v-col cols="12" sm="6">
                <v-btn-toggle
                    v-model="activeRequest.taxRateType"
                    color="red darken-2"
                    mandatory
                >
                  <v-btn :value="0">
                    {{ $t("buttons.taxExempt") }}
                  </v-btn>

                  <v-btn :value="1">
                    {{ $t("buttons.oldRate") }}
                  </v-btn>

                  <v-btn :value="2">
                    {{ $t("buttons.s21Default") }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-data-table
          :headers="getHeaders"
          :items="activeRequest.items"
          sort-by="itemCode"
          class="elevation-0 mb-5"
          @current-items="currentItems"
      >
        <template v-slot:top v-if="activeRequest.items.length > 0">
          <v-spacer></v-spacer>
          <v-form
              ref="newItemForm"
              v-model="newItemDialogValid"
          >
            <v-dialog v-model="newItemDialog" persistent max-width="500px">

              <v-card>
                <v-card-title>
                  <v-dialog
                      width="500"
                  >
                    <v-card>
                      <v-card-title class="grey lighten-2">
                        {{ $t("vcard.badSearchResults.title") }}
                      </v-card-title>
                      <v-card-text>
                        {{ $t("vcard.badSearchResults.text") }}
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                            v-model="editedItem.qtyOnHand"
                            type="number"
                            :label="$t('labels.quantity')"
                            min="0"
                            :rules="[rules.gtZero]"
                            @change="calcUnitPrice; calcTotalPrice"
                            @input="quantityChanged"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            v-model="editedItem.itemCode"
                            :rules="[rules.required, rules.itemCode]"
                            type="alphanumeric"
                            :label="$t('labels.itemCode')"
                            readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            v-model="editedItem.creditCode"
                            :rules="[rules.required]"
                            :label="$t('labels.creditCode')"
                            readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                            v-model="editedItem.description"
                            :rules="[rules.required]"
                            :label="$t('labels.description')"
                            readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="!ifDealerCreatedRequest">
                        <v-text-field
                            v-model="editedItem.invoicePrice"
                            type="number"
                            :rules="[rules.gteZero]"
                            :label="$t('labels.invoicePriceCad')"
                            min="0.00"
                            prefix="$"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="!ifDealerCreatedRequest">
                        <v-text-field
                            v-model="editedItem.discountPercentage"
                            type="number"
                            :rules="[rules.required]"
                            :label="$t('labels.percentage')"
                            suffix="%"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="!ifDealerCreatedRequest">
                        <v-text-field
                            :value="calcNewPrice"
                            type="number"
                            :rules="[rules.gteZero]"
                            :label="$t('labels.newPriceCad')"
                            min="0.00"
                            prefix="$"
                            readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            :value="calcUnitPrice"
                            type="number"
                            :rules="[rules.gteZero]"
                            :label="$t('labels.diffUnitCad')"
                            prefix="$"
                            min="0.00"
                            readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            :value="calcTotalPrice"
                            type="number"
                            :rules="[rules.gteZero]"
                            :label="$t('labels.totalCad')"
                            prefix="$"
                            min="0.00"
                            readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    {{ $t("buttons.cancel") }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save" :disabled="!newItemDialogValid">
                    {{ $t("buttons.save") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-form>

          <v-dialog v-model="serialNumberDialog" persistent max-width="500px">
            <v-card>
              <v-card-title>Serial Numbers for {{ editedItem.description }} ({{
                  editedItem.itemCode
                }})
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-data-table
                      :headers="getSnHeader"
                      :items="activeRequest.items[editedIndex === -1 ? 0 : editedIndex].serialNumbers">
                    <template v-slot:[`item.value`]="{ item }">
                      <v-text-field
                          class="pt-0 mt-0"
                          v-model="item.value"
                          type="string"
                          :rules="[rules.nonempty]"
                      ></v-text-field>
                    </template>
                  </v-data-table>

                  <v-card
                      class="d-flex pt-5"
                      flat
                  >
                    <v-spacer></v-spacer>
                    <div>
                      <v-btn @click="serialNumberDialog = false">CLOSE</v-btn>
                    </div>
                  </v-card>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>

        <template v-slot:footer>
          <v-divider></v-divider>
          <v-card
              class="d-flex pr-4"
              elevation="0"
          >
            <v-spacer></v-spacer>
            <div>
              <v-chip label color="lightgrey darken-1" text-color="black" class="font-weight-medium my-3">
                Grand Total
                (CAD): {{ activeRequest.grandTotal | toCAD }}
              </v-chip>
            </div>
          </v-card>
        </template>

        <template v-slot:[`item.invoicePrice`]="{ item }">
          <div class="d-flex justify-space-evenly bg-surface-variant">
            {{ item.invoicePrice | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.newPrice`]="{ item }">
          <div class="d-flex justify-space-evenly bg-surface-variant">
            {{ item.newPrice | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.lastPromo`]="{ item }">
          <div>
            {{ item.lastPromo | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.newPromo`]="{ item }">
          <div>
            {{ item.newPromo | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.discountPercentage`]="{ item }">
          <div class="d-flex justify-space-evenly bg-surface-variant">
            {{ item.discountPercentage + "%" }}
          </div>
        </template>

        <template v-slot:[`item.differencePerUnit`]="{ item }">
          <div class="d-flex justify-space-evenly bg-surface-variant">
            {{ item.differencePerUnit | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.total`]="{ item }">
          <div class="d-flex justify-space-evenly bg-surface-variant">
            {{ item.total | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small :title="$t('iconTitles.editItem')" class="mr-2" @click="editItem(item)">
            mdi-pencil-outline
          </v-icon>
          <v-icon
              small
              :title="$t('iconTitles.viewSerialNos')"
              class="mr-2"
              @click="viewSerialNumbers(item)"
              :disabled="item.serialNumbers.length === 0"
          >
            mdi-clipboard-list-outline
          </v-icon>
          <v-icon small :title="$t('iconTitles.seeFax')" class="mr-2"
                  v-if="activeRequest.snEntryMethod === 2">
            mdi-fax
          </v-icon>
        </template>
      </v-data-table>

      <v-divider></v-divider>

      <div>
        <v-card-text>
          <div class="text-overline">
            Attachments
            <v-tooltip
                top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    left
                    size="large"
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadAttachments"
                    :disabled="!!activeRequest.attachmentFilePath === false"
                >
                  mdi-download
                </v-icon>
              </template>
              <span>Download attachments zip</span>
            </v-tooltip>
          </div>
        </v-card-text>
      </div>

      <v-card elevation="0" class="mb-3">
        <v-list-item class="px-0">
          <v-list-item-content class="d-flex pa-0">
            <v-card
                class="d-flex flex-row mb-1"
                flat
                tile
            >
              <v-file-input
                  v-model="currentFile"
                  small-chips
                  show-size
                  counter
                  accept="*"
                  :label="$t('labels.selectSnFile')"
              ></v-file-input>
              <v-btn
                  class="my-auto mx-4"
                  @click="pushAttachmentToQueue"
                  :disabled="currentFile === null || currentFile === ''"
              >
                {{ $t('buttons.upload') }}
              </v-btn>
            </v-card>

          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-chip-group column v-if="activeRequest.attachmentFilePath">
            <div
                v-for="file in splitAttachments"
                :key="file"
            >
              <v-chip
                  class="my-2 text-h7 mx-2"
                  color="#C0C4C6"
                  label
                  close
                  @click:close="deleteAttachment(file)"
              >
                <v-icon left>
                  mdi-file
                </v-icon>
                {{ file }}
              </v-chip>
            </div>
          </v-chip-group>
          <v-chip-group column v-if="uploadQueue.length > 0">
            <div
                v-for="el in uploadQueue"
                :key="el.name"
            >
              <v-chip
                  class="my-2 text-h7 mx-2"
                  color="#C6C2C0"
                  label
                  close
                  @click:close="popAttachmentFromQueue(el)"
              >
                <v-icon left>
                  mdi-file-plus-outline
                </v-icon>
                {{ el.name }}
              </v-chip>
            </div>
          </v-chip-group>
        </v-list-item>
      </v-card>

      <v-divider></v-divider>

      <v-container fluid class="pt-5 px-0">
        <v-textarea
            v-model="activeRequest.auditorsComments"
            counter
            filled
            auto-grow
            :readonly="!isAuditor"
            :label="$t('labels.auditorsComments')"
        >
          <template v-slot:append>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="moveAuditorsCommentsToHistory"
                    :disabled="noAuditorsComments"
                >
                  <v-icon>
                    mdi-receipt-text-send
                  </v-icon>
                </v-btn>
              </template>
              <span>Add to comments history</span>
            </v-tooltip>
          </template>
        </v-textarea>
      </v-container>
      <v-container fluid class="pt-0 px-0">
        <v-textarea
            v-model="activeRequest.invoiceComments"
            counter
            filled
            auto-grow
            :label="$t('labels.invoiceComments')"
        ></v-textarea>
      </v-container>
      <v-container fluid class="pt-0 px-0">
        <v-textarea
            v-model="activeRequest.internalComments"
            counter
            filled
            auto-grow
            :label="$t('labels.internalComments')"
        ></v-textarea>
      </v-container>
      <v-container fluid class="pt-0 px-0">
        <v-textarea
            v-model="activeRequest.commentsHistory"
            counter
            filled
            auto-grow
            readonly
            :label="$t('labels.commentsHistory')"
        ></v-textarea>
      </v-container>
      <div v-if="isAuditor">

        <v-divider></v-divider>

        <div class="text-overline pb-0">
          Approver
        </div>

        <v-select class="pt-0"
                  v-model="selectedApprover"
                  :items="approvers"
                  :item-text="item => item.firstName + ' ' + item.lastName"
                  item-value="email"
                  return-object
                  :label="$t('labels.selectApprover')"
                  required
                  persistent-hint
                  single-line
                  @change="approver.firstName = selectedApprover.firstName; approver.lastName = selectedApprover.lastName; approver.email = selectedApprover.email"
        >
        </v-select>
      </div>
    </v-form>

    <v-divider></v-divider>

    <v-row class="pt-5 pb-3">
      <v-col cols="12" class="text-center">
        <v-btn @click="printWindow" :titles="$t('linkTitles.printWindow')" class="mr-4 mb-4">
          {{ $t("buttons.print") }}
        </v-btn>
        <v-dialog v-model="saveDialog" persistent max-width="350">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" v-bind="attrs" v-on="on" :disabled="disableSave" class="mr-4 mb-4">
              {{ $t("buttons.save") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("vcard.saveRequest.title") }}
            </v-card-title>
            <v-card-text>
              {{ $t("vcard.saveRequest.text") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="saveDialog = false">
                {{ $t("buttons.cancel") }}
              </v-btn>
              <v-btn color="red darken-1" text @click="saveRequest">
                {{ $t("buttons.yes") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="auditDialog" persistent max-width="350">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" class="mr-4 mb-4" :disabled="disableSubmit"
                   v-if="isAuditor">
              {{ $t("buttons.approve") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              Audit this Request?
            </v-card-title>
            <v-card-text>
              Upon submission, this request will receive first-level approval. Secondary approval must be
              given before
              the request can be accepted.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="auditDialog = false">
                {{ $t("buttons.disagree") }}
              </v-btn>
              <v-btn color="red darken-1" text @click="auditRequest()">
                {{ $t("buttons.agree") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="rejectDialog" persistent max-width="350">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="error" v-bind="attrs" v-on="on" v-if="isAuditor" class="mr-4 mb-4">
              {{ $t("buttons.reject") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              Reject Request?
            </v-card-title>
            <v-card-text>
              Upon rejection, this request will no longer be available for edit and will not go live.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="rejectDialog = false">
                {{ $t("buttons.disagree") }}
              </v-btn>
              <v-btn color="red darken-1" text @click="rejectRequest()">
                {{ $t("buttons.agree") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn class="mr-4 mb-4">
          <router-link to="/existing-requests" :title="$t('linkTitles.backToExistingRequests')"
                       class="text-decoration-none">
            {{ $t("buttons.back") }}
          </router-link>
        </v-btn>
      </v-col>
    </v-row>
  </div>
  <div v-else></div>
</template>

<script>
import {storeToRefs} from "pinia";
import {useExistingRequestsStore} from "@/stores/existingrequests";
import {defineComponent} from "vue-demi";
import {useUserStore} from "@/stores/user";
import randomString from "randomstring";
import _ from "lodash";

export default defineComponent({
  name: "RequestSubmitted",
  setup() {
    const requestsStore = useExistingRequestsStore();
    const {activeRequest, approver} = storeToRefs(requestsStore);
    const userStore = useUserStore();
    let {isAuditor, approvers} = storeToRefs(userStore);

    console.log("RequestsSubmitted setup");

    return {
      activeRequest,
      requestsStore,
      isAuditor,
      approvers,
      approver
    };
  },
  data: () => ({
    confirmDeleteAttachmentDialog: false,
    uploadQueue: [],
    newItemDialog: false,
    newItemDialogValid: true,
    serialNumberDialog: false,
    dateMenu: false,
    auditDialog: false,
    saveDialog: false,
    rejectDialog: false,
    valid: true,
    currentFile: null,
    rowCount: 0,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    editedIndex: -1,
    editedItem: {},
    rules: {
      max21Chars: (value) =>
          (value === null || value.length <= 21) || "Character max reached!",
      requiredSelect: v => Boolean(Object.values(v || {})[0]) || 'ruleViolations.approverNotSelected',
    },
    selectedApprover: {firstName: '', lastName: ''},
  }),

  computed: {
    splitAttachments() {
      return this.activeRequest.attachmentFilePath.split('|');
    },
    noAuditorsComments() {
      return this.activeRequest.auditorsComments === null || this.activeRequest.auditorsComments.length === 0;
    },
    enableChip() {
      return (!(this.activeRequest.attachmentFilePath === null) && !(this.activeRequest.attachmentFilePath === ''));
    },
    ifDealerCreatedRequest() {
      return this.activeRequest.plan !== undefined && this.activeRequest.plan !== null && this.activeRequest.plan !== 0;
    },
    requiredRule() {
      const t = this.$t.bind(this)
      return [(value) => !!value || t('ruleViolations.missingRequiredField')];
    },
    formattedStartDate() {
      return (this.activeRequest.startDate.split(" "))[0];
    },
    calcUnitPrice() {
      if (this.ifDealerCreatedRequest) {
        return (this.editedItem.lastPromo - this.editedItem.newPromo).toFixed(2);
      }
      return (this.editedItem.invoicePrice - (this.editedItem.invoicePrice - ((this.editedItem.invoicePrice * this.editedItem.discountPercentage) / 100))).toFixed(2);
    },
    calcNewPrice() {
      if (this.ifDealerCreatedRequest) {
        return 0.0;
      }
      return ((this.editedItem.invoicePrice - ((this.editedItem.invoicePrice * this.editedItem.discountPercentage) / 100))).toFixed(2);
    },
    calcTotalPrice() {
      if (this.ifDealerCreatedRequest) {
        return (this.editedItem.qtyOnHand * this.editedItem.differencePerUnit).toFixed(2);
      }
      return (this.editedItem.qtyOnHand * (this.editedItem.invoicePrice - (this.editedItem.invoicePrice - ((this.editedItem.invoicePrice * this.editedItem.discountPercentage) / 100)))).toFixed(2);
    },
    disableSave() {
      return this.activeRequest.dealerName === "";
    },
    disableSubmit() {
      // LEAVE THIS IN JUST IN CASE SN VALIDATION COME BACK INTO PLAY
      // return !this.valid || this.rowCount <= 0 || this.activeRequest.validationCode !== "Y";
      return !this.valid || this.rowCount <= 0 || this.selectedApprover.firstName === "";
    },
    getHeaders() {
      const t = this.$t.bind(this)

      if (this.activeRequest.items.length < 1) {
        return [];
      } else if ((this.activeRequest.items[0].newPrice === 0 && this.activeRequest.items[0].invoicePrice === 0) ||
          (this.activeRequest.items[0].newPrice === null && this.activeRequest.items[0].invoicePrice === null)) {
        return [
          {text: t('headers.qty'), value: "qtyOnHand"},
          {
            text: t('headers.itemCode'),
            align: "start",
            value: "itemCode",
          },
          {text: t('headers.description'), value: "description"},
          {text: t('headers.creditCode'), value: "creditCode"},
          {text: t('headers.lastPromoCad'), value: "lastPromo"},
          {text: t('headers.newPromoCad'), value: "newPromo"},
          {text: t('headers.diffUnitCad'), value: "differencePerUnit"},
          {text: t('headers.totalCad'), value: "total"},
          {text: t('headers.actions'), value: "actions", sortable: false},
        ]
      } else {
        return [
          {text: t('headers.qty'), value: "qtyOnHand"},
          {
            text: t('headers.itemCode'),
            align: "start",
            value: "itemCode",
          },
          {text: t('headers.description'), value: "description"},
          {text: t('headers.creditCode'), value: "creditCode"},
          {text: t('headers.invoicePriceCad'), value: "invoicePrice"},
          {text: t('headers.newPriceCad'), value: "newPrice"},
          {text: t('headers.discountPercentage'), value: "discountPercentage"},
          {text: t('headers.diffUnitCad'), value: "differencePerUnit"},
          {text: t('headers.totalCad'), value: "total"},
          {text: t('headers.actions'), value: "actions", sortable: false},
        ]
      }
    },
    getSnHeader() {
      const t = this.$t.bind(this)
      return [
        {text: t('headers.serialNumber'), value: "value"}
      ]
    },
  },
  methods: {
    currentItems: function (value) {
      this.rowCount = value.length;
    },
    close() {
      this.newItemDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      if (!this.ifDealerCreatedRequest) {
        this.editedItem.newPrice = (this.editedItem.invoicePrice - ((this.editedItem.invoicePrice * this.editedItem.discountPercentage) / 100)).toFixed(2);
        this.editedItem.total = (this.editedItem.qtyOnHand * ((this.editedItem.invoicePrice) - ((this.editedItem.invoicePrice) - ((this.editedItem.invoicePrice * this.editedItem.discountPercentage) / 100)))).toFixed(2);
        this.editedItem.differencePerUnit = (this.editedItem.invoicePrice - (this.editedItem.invoicePrice - ((this.editedItem.invoicePrice * this.editedItem.discountPercentage) / 100))).toFixed(2);
      } else {
        this.editedItem.total = this.editedItem.qtyOnHand * this.editedItem.differencePerUnit;
      }

      if (this.editedIndex > -1) {
        Object.assign(this.activeRequest.items[this.editedIndex], this.editedItem);
      } else {
        this.activeRequest.items.push(this.editedItem);
      }

      await this.requestsStore.addRequestItem(this.editedIndex);

      await this.saveRequest();

      this.close();
    },
    calcGrandTotal() {
      this.activeRequest.grandTotal = Number(0);
      let items = this.activeRequest.items;

      for (let it of items) {
        it.total = (it.qtyOnHand * it.differencePerUnit).toFixed(2);
        this.activeRequest.grandTotal += Number(it.total);
      }
    },
    editItem(item) {
      this.editedIndex = this.activeRequest.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.newItemDialog = true;
    },
    async saveRequest(section = null, isUploadSaved = true) {
      this.$emit('loading');

      // persist to the backend
      this.saveDialog = false;
      console.log("Saving as submitted request...");

      this.calcGrandTotal();

      if (isUploadSaved) {
        // switch to uploadQueue
        if (this.uploadQueue.length > 0) {
          console.log("Let's upload");
          for (let i = 0; i < this.uploadQueue.length; i++) {
            if (await this.requestsStore.uploadAttachment(this.uploadQueue[i])) {
              console.log("Uploading now " + this.uploadQueue[i]);
            } else {
              console.log("Couldn't upload " + this.uploadQueue[i]);
            }
          }
          this.currentFile = null;
          this.uploadQueue = [];
        } else {
          console.log("Couldn't upload files from the queue.");
        }
      }

      await this.requestsStore.saveChangesToActiveRequest(section);

      this.$emit('loaded');
    },
    async uploadAttachment() {
      this.activeRequest.attachmentFilePath = this.currentFile.name;

      this.serialNumberDialog = false;
    },
    async deleteAttachment(filename) {
      const t = this.$t.bind(this)
      if (confirm(t('confirm.delete.attachment')) === true) {
        if (await this.requestsStore.deleteAttachment(filename)) {
          await this.saveRequest(null, false);
        }
      }
      this.confirmDeleteAttachmentDialog = false;
    },
    viewSerialNumbers(item) {
      this.editedIndex = this.activeRequest.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.serialNumberDialog = true;
    },
    async quantityChanged() {
      console.log("QTY Changed");
      const oldQty = this.activeRequest.items[this.editedIndex].serialNumbers.length;
      const newQty = this.editedItem.qtyOnHand;
      const diffQty = newQty - oldQty;

      if (diffQty < 0) {
        for (let i = 0; i < Math.abs(diffQty); ++i) {
          this.activeRequest.items[this.editedIndex].serialNumbers.pop();
        }
      } else {
        for (let i = 0; i < diffQty; ++i) {
          // HACK. Needed to add a fake/temp id to the serial number
          // because the NEW serial numbers kept having the same typed-in
          // value.
          const newSn = {
            value: "",
            requestItemId: this.activeRequest.items[this.editedIndex].id,
            id: randomString.generate(),
            isDirty: true
          }
          this.activeRequest.items[this.editedIndex].serialNumbers.push(newSn)
        }
      }
    },
    /**
     * Submit the request.
     */
    async auditRequest() {
      this.auditDialog = false;

      await this.saveRequest();

      this.$emit('loading');

      console.log("Auditing request...");

      if (await this.requestsStore.auditRequest()) {
        this.$emit('advance-step')
      } else {
        console.log("Cannot audit dealer request");
      }
      this.$emit('loaded');
    },
    async rejectRequest() {
      this.$emit('loading');

      this.rejectDialog = false;
      console.log("Rejected request...");

      if (await this.requestsStore.rejectRequest()) {
        this.$emit('reject-step');
      } else {
        console.log("Cannot reject dealer request");
      }

      this.$emit('loaded');
    },
    async downloadAttachment() {
      await this.requestsStore.downloadAttachment();
    },
    async downloadAttachments() {
      await this.requestsStore.downloadAttachment();
    },
    popAttachmentFromQueue(el) {
      let index = _.findIndex(this.uploadQueue, (e) => {
        return e === el;
      }, 0);

      this.uploadQueue = this.uploadQueue.toSpliced(index, 1);
    },
    pushAttachmentToQueue() {
      if (this.currentFile.size > process.env.VUE_APP_UPLOAD_MAX_BYTES) {
        alert('File size cannot exceed 10MB.');
      } else {
        if (!this.uploadQueue.includes(this.currentFile)) {
          this.uploadQueue.push(this.currentFile);
        }
      }

      this.currentFile = null;
      this.serialNumberDialog = false;
    },
    printWindow() {
      window.print();
    },
    async moveAuditorsCommentsToHistory() {
      await this.saveRequest("AUDITOR");
    }
  },
});
</script>