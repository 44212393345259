<template>
  <div v-if="activePlan !== null">
    <v-card class="my-0" elevation="0">
      <v-list-item class="pr-0">
        <v-list-item-content class="pb-0">
          <div class="text-overline mb-1">Price Protection Plan
          </div>
          <v-list-item-title class="text-h6">
            {{ activePlan.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="my-2">
            Plan ID {{ activePlan.pid }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="py-0 my-0 mx-0 px-0">
          <v-row>
            <v-col cols="12" sm="6" class="pl-0">
              <v-menu
                  ref="dateRangeMenu"
                  v-model="dateRangeMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" class="ml-0 pl-1">
                    <v-icon right dark class="mr-2">
                      mdi-calendar-month-outline
                    </v-icon>
                    {{ formattedLifeTime }}
                  </v-btn>
                </template>
                <v-date-picker
                    v-model="activePlan.lifeTime"
                    range
                    readonly
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <v-data-table
        :headers="getHeaders"
        :items="activePlan.items"
        sort-by="itemCode"
        class="elevation-0 mb-5"
    >
      <template v-slot:[`item.lastPromo`]="{ item }">
        <div class="d-flex justify-space-evenly bg-surface-variant">
          {{ item.lastPromo | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.newPromo`]="{ item }">
        <div class="d-flex justify-space-evenly bg-surface-variant">
          {{ item.newPromo | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.unitPrice`]="{ item }">
        <div class="d-flex justify-space-evenly bg-surface-variant">
          {{ item.unitPrice | toCAD }}
        </div>
      </template>
    </v-data-table>

    <v-divider></v-divider>

    <v-row class="pt-5 pb-3">
      <v-col cols="12" class="text-center">
        <v-btn @click="printWindow" :titles="$t('linkTitles.printWindow')" class="mr-4 mb-4">
          {{ $t("buttons.print") }}
        </v-btn>
        <v-dialog v-model="editPlanDialog" persistent max-width="350" v-if="!this.isExpired && !this.wasAnExpiredPlan">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" class="mr-4 mb-4">
              {{ $t("buttons.edit") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              Edit this Plan?
            </v-card-title>
            <v-card-text>
              If you decide to edit this plan, it will be put back to draft status and the approval process will restart
              from the beginning.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="editPlanDialog = false">
                {{ $t("buttons.disagree") }}
              </v-btn>
              <v-btn color="red darken-1" text @click="editPlan">
                {{ $t("buttons.agree") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="reactivationDialog" persistent max-width="400" v-if="this.isExpired && this.isApprover">
          <template v-slot:activator="{ on, attrs }">
            <v-btn :titles="'Activate Plan Until Midnight'" class="mr-4 mb-4" v-bind="attrs" v-on="on">
              {{ 'Reactivate' }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              {{ 'Reactivate Plan' }}
            </v-card-title>
            <v-card-text>
              {{ 'This plan will be available until midnight tonight. Would you like to proceed?' }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="reactivationDialog = false">
                {{ $t("buttons.cancel") }}
              </v-btn>
              <v-btn color="red darken-1" text @click="activateActiveExpiredPlan">
                {{ $t("buttons.yes") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn class="mr-4 mb-4">
          <router-link to="/existing-plans" :title="$t('linkTitles.backToExistingPlans')" class="text-decoration-none">
            {{ $t("buttons.back") }}
          </router-link>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-center">
        <v-dialog v-model="deletePlanDialog" persistent max-width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="red darken-2" large :title="$t('iconTitles.deletePlan')" v-bind="attrs" v-on="on">
              mdi-delete-circle
            </v-icon>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("vcard.deletePlan.title") }}
            </v-card-title>
            <v-card-text>
              {{ $t("vcard.deletePlan.text") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="deletePlanDialog = false">
                {{ $t("buttons.cancel") }}
              </v-btn>
              <v-btn color="red darken-1" text @click="deleteActivePlan">
                {{ $t("buttons.delete") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {storeToRefs} from "pinia";
import {useExistingPlansStore} from "@/stores/existingplans";
import {defineComponent} from "vue-demi";
import {useUserStore} from "@/stores/user";
import _ from "underscore";
import moment from 'moment';


export default defineComponent({
  name: "PlanApproved",
  setup() {
    const plansStore = useExistingPlansStore();
    let {activePlan} = storeToRefs(plansStore);
    const userStore = useUserStore();
    const {isApprover} = storeToRefs(userStore);

    console.log("PlanApproved setup");

    return {
      activePlan,
      plansStore,
      isApprover
    };
  },
  beforeRouteEnter(to, from, next) {
    const userStore = useUserStore();
    const {user} = storeToRefs(userStore);

    const {authorize} = to.meta;
    const roles = user.value.roles;

    if (_.intersection(authorize, roles).length > 0) {
      next();
    } else {
      return false;
    }
  },
  data: () => ({
    dateRangeMenu: false,
    deletePlanDialog: false,
    reactivationDialog: false,
    editPlanDialog: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    headers: [
      {
        text: "Item Code",
        align: "start",
        sortable: false,
        value: "itemCode",
      },
      {text: "Description", value: "description"},
      {text: "Credit Code", value: "creditCode"},
      {text: "Last Promo (CAD)", value: "lastPromo"},
      {text: "New Promo (CAD)", value: "newPromo"},
      {text: "Diff/Unit (CAD)", value: "unitPrice"},
    ],
    defaultItem: {
      pid: "0000000",
      name: "",
      lifeTime: ["01-01-1970", "01-31-1970"],
      status: -1,
      items: [],
    },
  }),

  computed: {
    formattedLifeTime() {
      return this.activePlan.lifeTime[0] + ", " + this.activePlan.lifeTime[1];
    },
    wasAnExpiredPlan() {
      return moment(this.activePlan.lifeTime[1]).isBefore(moment());
    },
    isExpired() {
      return this.activePlan.status === 5;
    },
    getHeaders() {
      const t = this.$t.bind(this)
      return [
        {
          text: t('headers.itemCode'),
          align: "start",
          sortable: false,
          value: "itemCode",
        },
        {text: t('headers.description'), value: "description"},
        {text: t('headers.creditCode'), value: "creditCode"},
        {text: t('headers.lastPromoCad'), value: "lastPromo"},
        {text: t('headers.newPromoCad'), value: "newPromo"},
        {text: t('headers.diffUnitCad'), value: "unitPrice"},
      ]
    }
  },
  watch: {},
  async mounted() {
    this.$emit('update-step');
  },
  methods: {
    async deleteActivePlan() {
      await this.plansStore.deleteCurrentPlan();
      await this.$router.push('existing-plans');
    },
    async editPlan() {
      this.editPlanDialog = false;

      if (await this.plansStore.editPlan()) {
        this.$emit('reset-step');
      } else {
        console.log("Cannot edit plan");
      }
    },
    async activateActiveExpiredPlan() {
      this.activePlan.status = 4;
      await this.plansStore.saveChangesToActivePlan();
    },
    printWindow() {
      window.print();
    }
  }
});
</script>