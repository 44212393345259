<template>
  <div v-if="activeRequest !== null">
    <v-container app class="my-0" fluid>
      <v-app id="inspire" v-if="activeRequest !== undefined">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step step="1" :complete="e1 > 1" class="py-0">
              {{ $t('labels.draft') }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" :complete="e1 > 2" class="py-0">
              {{ $t('labels.submitted') }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3" :complete="e1 > 3" class="py-0">
              {{ $t('labels.audited') }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4" :complete="e1 >= 4" class="py-0">
              {{ getVerdict }}
            </v-stepper-step>
            <v-divider></v-divider>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <RequestDraft
                  @advance-step="e1 += 1"
                  @loading="showOverlay = true"
                  @loaded="showOverlay = false"
                  @update-step="updateStep"
                  @request-audited="e1 = 3"></RequestDraft>
            </v-stepper-content>
            <v-stepper-content step="2">
              <RequestSubmitted
                  @advance-step="e1 += 1"
                  @loading="showOverlay = true"
                  @loaded="showOverlay = false"
                  @reject-step="e1 = 6"></RequestSubmitted>
            </v-stepper-content>
            <v-stepper-content step="3">
              <RequestAudited
                  @advance-step="e1 += 1"
                  @loading="showOverlay = true"
                  @loaded="showOverlay = false"
                  @admin-demote-step="e1 = 1"
                  @dealer-demote-step="e1 = 2"
                  @reject-step="e1 = 6"></RequestAudited>
            </v-stepper-content>
            <v-stepper-content step="4">
              <RequestApproved
                  @advance-step="e1 += 1"
                  @loading="showOverlay = true"
                  @loaded="showOverlay = false"
                  @reject-step="e1 = 6"></RequestApproved>
            </v-stepper-content>
            <v-stepper-content step="5">
              <RequestApproved
                  @advance-step="e1 += 1"
                  @loading="showOverlay = true"
                  @loaded="showOverlay = false"
                  @reject-step="e1 = 6"></RequestApproved>
            </v-stepper-content>
            <v-stepper-content step="7">
              <RequestApproved
                  @advance-step="e1 += 1"
                  @loading="showOverlay = true"
                  @loaded="showOverlay = false"
                  @reject-step="e1 = 6"></RequestApproved>
            </v-stepper-content>
            <v-stepper-content step="6">
              <RequestRejected></RequestRejected>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-app>
    </v-container>

    <v-overlay
        :opacity="0.75"
        :value="showOverlay"
    >
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
  </div>
  <div v-else></div>
</template>

<script>
import {storeToRefs} from "pinia";
import _ from "lodash";
import {useExistingRequestsStore} from "@/stores/existingrequests";
import RequestDraft from "@/components/RequestDraft";
import RequestSubmitted from "@/components/RequestSubmitted";
import RequestApproved from "@/components/RequestApproved";
import RequestAudited from "@/components/RequestAudited";
import RequestRejected from "@/components/RequestRejected";
import {useUserStore} from "@/stores/user";

export default {
  name: "ProtectionRequest",
  components: {
    RequestAudited,
    RequestApproved,
    RequestSubmitted,
    RequestDraft,
    RequestRejected
  },
  setup() {
    const requestsStore = useExistingRequestsStore();
    const userStore = useUserStore();
    let {activeRequest} = storeToRefs(requestsStore);
    const {user} = storeToRefs(userStore);

    console.log("ProtectionRequest setup");

    return {
      activeRequest,
      requestsStore,
      user
    };
  },
  async mounted() {
    this.updateStep();
  },
  data() {
    let showOverlay = false;
    let stepNum = 1; // case DRAFT
    if (!_.isEmpty(this.requestsStore.activeRequest)) {
      const status = this.requestsStore.activeRequest.requestStatus;

      if (status !== null) {
        switch (status) {
          case 1:
            stepNum = 1;
            break;
          case 2:
            stepNum = 2;
            break;
          case 3:
            stepNum = 3;
            break;
          case 4:
            stepNum = 4;
            break;
          case 5:
            stepNum = 5;
            break;
          case 6:
            // rejected
            stepNum = 6;
            break;
          case 7:
            stepNum = 7;
            break;
        }
      }
    }

    return {
      e1: stepNum,
      showOverlay
    };
  },
  computed: {
    getVerdict() {
      const t = this.$t.bind(this);
      if (!_.isEmpty(this.requestsStore.activeRequest)) {
        const activeGroup = this.requestsStore.activeRequest.requestStatus;

        if (activeGroup !== null) {
          switch (activeGroup) {
            case 4:
              return t('labels.pending');
            case 5:
              return t('labels.approved')
            case 6:
              return t('labels.rejected');
            case 7:
              return t('labels.error');
            default:
              return t('labels.processing');
          }
        }
      }
      return t('labels.processing');
    }
  },
  methods: {
    updateStep() {
      let stepNum = 1; // case DRAFT
      if (!_.isEmpty(this.requestsStore.activeRequest)) {
        const status = this.requestsStore.activeRequest.requestStatus;

        if (status !== null) {
          switch (status) {
            case 1:
              stepNum = 1;
              break;
            case 2:
              stepNum = 2;
              break;
            case 3:
              stepNum = 3;
              break;
            case 4:
              // approved; transfer pending
              stepNum = 4;
              break;
            case 5:
              // approved; transfer success
              stepNum = 5;
              break;
            case 6:
              // rejected
              stepNum = 6;
              break;
            case 7:
              // error on transmission
              stepNum = 7;
              break;
          }
        }
      }
      this.e1 = stepNum;
    }
  }
};
</script>