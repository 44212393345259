import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ExistingPlans from '../views/ExistingPlans.vue'
import ExistingRequests from '../views/ExistingRequests.vue'
import UserProfile from '../views/UserProfile.vue'
import ProtectionPlan from '../views/ProtectionPlan.vue'
import ProtectionRequest from '../views/ProtectionRequest.vue'
import DealerView from '../views/DealerView.vue'
import DealerPlan from "@/components/DealerPlan";
import DealerProtectionRequest from "@/views/DealerProtectionRequest";
import {Role} from "@/_helpers/role";
import {useUserStore} from "@/stores/user";
import {storeToRefs} from "pinia";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            authorize: [Role.DEALER, Role.APPROVER, Role.AUDITOR],
            isAuthenticated: true
        }
    },
    {
        path: '/user-profile',
        name: 'user-profile',
        component: UserProfile,
        meta: {
            authorize: [Role.DEALER, Role.APPROVER, Role.AUDITOR],
            isAuthenticated: true
        }
    },
    {
        path: '/existing-requests',
        name: 'existing-requests',
        component: ExistingRequests,
        meta: {
            authorize: [Role.APPROVER, Role.AUDITOR],
            isAuthenticated: true
        }
    },
    {
        path: '/existing-plans',
        name: 'existing-plans',
        component: ExistingPlans,
        meta: {
            authorize: [Role.APPROVER, Role.AUDITOR],
            isAuthenticated: true
        }
    },
    {
        path: '/protection-plan',
        name: 'Protection Plan',
        component: ProtectionPlan,
        meta: {
            authorize: [Role.APPROVER, Role.AUDITOR],
            isAuthenticated: true
        }
    },
    {
        path: '/protection-request',
        name: 'Protection Plan Request',
        component: ProtectionRequest,
        meta: {
            authorize: [Role.APPROVER, Role.AUDITOR],
            isAuthenticated: true
        }
    },
    {
        path: '/my-requests',
        name: 'My Requests',
        component: DealerView,
        meta: {
            authorize: [Role.DEALER],
            isAuthenticated: true
        }
    },
    {
        path: '/dealer-request',
        name: 'Dealer Request',
        component: DealerProtectionRequest,
        meta: {
            authorize: [Role.DEALER],
            isAuthenticated: true
        }
    },
    {
        path: '/dealer-plan',
        name: 'Dealer Plan',
        component: DealerPlan,
        meta: {
            authorize: [Role.DEALER],
            isAuthenticated: true
        }
    },
    // otherwise redirect to home
    {
        path: '*',
        redirect: '/',
        meta: {
            isAuthenticated: false
        }
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    try {
        const keyCloak = Vue.prototype.$keycloak;
        const userStore = useUserStore();
        const {user} = storeToRefs(userStore);

        if (to.meta.isAuthenticated) {
            // Get the actual url of the app, it's needed for Keycloak
            {
                keyCloak.updateToken(60)
                    .then((refreshed) => {
                        if (refreshed) {
                            console.log('Token was successfully refreshed');
                            user.value.accessToken = keyCloak.token;
                        } else {
                            console.log('Token is still valid');
                        }
                        next()
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            }
        } else {
            // This page did not require authentication
            next()
        }
    } catch (e) {
        // pinia may not have been created yet
        next();
    }
})

export default router
