import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import {createPinia, PiniaVuePlugin, storeToRefs} from 'pinia'
import Router from "vue-router";
import i18n from './i18n'
import Keycloak from "keycloak-js";
import {useUserStore} from "@/stores/user";
import _ from "lodash";

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.config.productionTip = false

Vue.use(Router)

const keycloakUrl =  _.isEqual(window.location.origin, process.env.VUE_APP_PP_BASE_URL) ?
    process.env.VUE_APP_PP_KEYCLOAK_URL :
    process.env.VUE_APP_PP_KEYCLOAK_DEV_URL;

const initOptions = {
    url: keycloakUrl,
    realm: 'CanonRealm',
    clientId: 'pp-app',
    grantType: 'password',
    onLoad: 'login-required'
}

let keycloak = new Keycloak(initOptions);
Vue.prototype.$keycloak = keycloak;

Vue.filter('toCAD', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    const formatter = new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD'
    });
    return formatter.format(value);
});

keycloak.init({onLoad: initOptions.onLoad}).then((auth) => {
    if (!auth) {
        window.location.reload();
    } else {
        console.log("Authenticated");

        new Vue({
            router,
            vuetify,
            pinia,
            i18n,
            render: h => h(App, {props: {keycloak: keycloak}})
        }).$mount('#app')
    }

    //Token Refresh
    setInterval(async () => {

        const keyCloak = Vue.prototype.$keycloak;

        await keyCloak.updateToken(60).then((refreshed) => {
            const userStore = useUserStore();
            const {user} = storeToRefs(userStore);

            if (refreshed) {
                console.info('Token refreshed ' + refreshed);
                user.value.accessToken = keyCloak.token;
            } else {
                console.warn('Token not refreshed, valid for '
                    + Math.round(keyCloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
            }
        }).catch((e) => {
            console.error(e);
            Vue.$log.error('Failed to refresh token');
        });
    }, 60000)

}).catch((e) => {
    console.error("Authentication Failed");
    console.error(e);
})