<template>
  <v-container app fluid>
    <div class="text-center">
      <v-dialog
          v-model="reportDialog"
          width="350"
      >
        <v-card>
          <v-card-title class="text-h6 grey lighten-2">
            REQUEST TOTALS
          </v-card-title>

          <v-card-text>
            <v-simple-table
                fixed-header>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    STAGE
                  </th>
                  <th class="text-right">
                    TOTAL
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="item in totals"
                    :key="item.name"
                >
                  <td>{{ item.name }}</td>
                  <td class="text-right">{{ item.total | toCAD }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>

          <v-divider></v-divider>

        </v-card>
      </v-dialog>
    </div>
    <v-row class="text-center">
      <v-col cols="0" sm="3" class="mb-0 pb-0">
        <v-list dense>
          <v-subheader class="pl-0 subtitle-2">PRICE PROTECTION REQUESTS</v-subheader>
          <v-list-item-group v-model="selectedItem" color="primary" mandatory>
            <template v-slot:default>
              <v-list-item
                  v-for="(item, i) in requestTypes"
                  :key="i"
                  class="pl-0"
                  @change="setActiveGroup(item.text)"
              >
                <v-list-item-icon class="ml-1 mr-5">
                  <v-icon v-text="item.icon" :color="item.color"></v-icon>
                </v-list-item-icon>
                <v-list-item-content class="text-left">
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>

        <v-divider></v-divider>
        <v-list dense>
          <v-list-item
              class="pl-0"
              @click="createNewRequest()"
          >
            <v-list-item-icon class="ml-1 mr-5">
              <v-icon v-text="'mdi-plus-thick'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-left">
              <v-list-item-title
                  v-text="$t('titles.createNewRequest')">
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              class="pl-0"
              @click="openRequestTotalsDialog"
          >
            <v-list-item-icon class="ml-1 mr-5">
              <v-icon v-text="'mdi-file-table-box'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-left">
              <v-list-item-title
                  v-text="$t('titles.viewRequestTotals')">
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" sm="9">
        <v-card-title>
          <v-text-field
              v-model="search"
              append-icon="fa-solid fa-magnifying-glass"
              :label="$t('labels.search')"
              single-line
              hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="showHeaders"
            :items="activeGroup"
            :search="search"
            item-key="id"
            class="elevation-0"
            :sort-by="['dateLastModified', 'dateApproved']"
            :sort-desc="[true, true]"
            @current-items="getFiltered"
        >
          <template v-slot:[`item.dateLastModified`]="{ item }">
            <div>
              {{ item.dateLastModified.split(" ")[0] }}
            </div>
          </template>
          <template v-slot:[`item.dateApproved`]="{ item }">
            <div>
              {{ containsApprovedDate(item) }}
            </div>
          </template>
          <template v-slot:[`item.grandTotal`]="{ item }">
            <div class="d-flex justify-space-evenly bg-surface-variant">
              {{ item.grandTotal | toCAD }}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="setActiveRequest(item)">
              mdi-eye
            </v-icon>
          </template>

          <template v-slot:footer>
            <v-divider></v-divider>
            <v-card
                class="d-flex pr-4"
                elevation="0"
            >
              <v-spacer></v-spacer>
              <div>
                <v-chip label color="lightgrey darken-1" text-color="black" class="font-weight-medium my-3">
                  Total
                  (CAD): {{ sumTotal | toCAD }}
                </v-chip>
              </div>
            </v-card>
          </template>

          <template v-slot:[`footer.prepend`]>
            <v-btn
                @click="exportCsv"
                elevation="0"
            >
              <v-icon
                  left
                  v-text="'mdi-file-delimited-outline'"/>
              CSV
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <v-overlay
        :opacity="0.75"
        :value="showOverlay"
    >
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import {storeToRefs} from "pinia";
import {useExistingRequestsStore} from "@/stores/existingrequests";
import {defineComponent} from "vue-demi";
import {useUserStore} from "@/stores/user";
import {json2csv} from 'json-2-csv';


export default defineComponent({
  name: "ExistingRequests",
  setup() {
    const requestsStore = useExistingRequestsStore();
    const {activeGroup, activeRequest, drafts} = storeToRefs(requestsStore);
    const userStore = useUserStore();

    requestsStore.$reset();

    return {
      activeGroup,
      activeRequest,
      drafts,
      requestsStore,
      userStore
    };
  },
  async mounted() {
    const userStore = useUserStore();
    const {user} = storeToRefs(userStore);

    if (user.accessToken !== null) {
      if (sessionStorage.requestGroup === undefined) {
        sessionStorage.requestGroup = 0;
      }
      await this.setActiveGroupWithSession();
    } else {
      await this.$router.push('home');
    }
  },
  methods: {
    containsApprovedDate(item) {
      return (item.dateApproved) ? item.dateApproved.split(" ")[0] : "N/A";
    },
    async setActiveGroupWithSession() {
      const userStore = useUserStore();
      const {user} = storeToRefs(userStore);

      if (user.accessToken !== null) {
        console.log('Request Group in Session ' + sessionStorage.requestGroup);

        switch (sessionStorage.requestGroup) {
          case "1":
            await this.setActiveGroup("SUBMITTED");
            break;
          case "2":
            await this.setActiveGroup("AUDITED");
            break;
          case "3":
            await this.setActiveGroup("APPROVED");
            break;
          case "4":
            await this.setActiveGroup("TX SUCCESSFUL");
            break;
          case "6":
            await this.setActiveGroup("REJECTED");
            break;
          case "5":
            await this.setActiveGroup("TX FAILED");
            break;
          case "0":
          default:
            await this.setActiveGroup("DRAFTS");
            break;
        }
      } else {
        await this.$router.push('/home');
      }
    },
    async setActiveGroup(type) {
      console.log(type);
      this.showOverlay = true;

      switch (type) {
        case "SUBMITTED": {
          await this.requestsStore.getSubmittedRequests();
          this.requestsStore.setSubmittedAsActiveGroup(true);
          this.selectedItem = sessionStorage.requestGroup = 1;

          this.selectedHeaders = Object.values(this.headersMap);
          this.selectedHeaders.splice(3, 1);
          break;
        }
        case "REJECTED": {
          await this.requestsStore.getRejectedRequests();
          this.requestsStore.setRejectedAsActiveGroup(true);
          this.selectedItem = sessionStorage.requestGroup = 6;

          this.selectedHeaders = Object.values(this.headersMap);
          this.selectedHeaders.splice(3, 1);
          break;
        }
        case "AUDITED": {
          await this.requestsStore.getAuditedRequests();
          this.requestsStore.setAuditedAsActiveGroup(true);
          this.selectedItem = sessionStorage.requestGroup = 2;

          this.selectedHeaders = Object.values(this.headersMap);
          this.selectedHeaders.splice(3, 1);
          break;
        }
        case "APPROVED": {
          await this.requestsStore.getApprovedRequests();
          this.requestsStore.setApprovedAsActiveGroup(true);
          this.selectedItem = sessionStorage.requestGroup = 3;

          this.selectedHeaders = Object.values(this.headersMap);
          this.selectedHeaders.splice(4, 1);

          break;
        }
        case "TX SUCCESSFUL": {
          await this.requestsStore.getTxSuccessRequests();
          this.requestsStore.setTxSuccessAsActiveGroup(true);
          this.selectedItem = sessionStorage.requestGroup = 4;

          this.selectedHeaders = Object.values(this.headersMap);
          this.selectedHeaders.splice(4, 1);
          break;
        }
        case "TX FAILED": {
          await this.requestsStore.getTxErrorRequests();
          this.requestsStore.setTxErrorAsActiveGroup(true);
          this.selectedItem = sessionStorage.requestGroup = 5;

          this.selectedHeaders = Object.values(this.headersMap);
          this.selectedHeaders.splice(4, 1);
          break;
        }
        default: {
          await this.requestsStore.getDraftRequests();
          this.requestsStore.setDraftsAsActiveGroup(true);
          this.selectedItem = sessionStorage.requestGroup = 0;

          this.selectedHeaders = Object.values(this.headersMap);
          this.selectedHeaders.splice(3, 1);
          break;
        }
      }

      this.sumTotal = this.sumRequestTotals();
      this.showOverlay = false;
    },
    async setActiveRequest(item) {
      const pprNo = item.id;
      await this.requestsStore.setActiveRequest(pprNo);
      await this.$router.push('/protection-request');
    },
    async setActiveRequestById(pid) {
      await this.requestsStore.setActiveRequest(pid);
    },
    clearActiveRequest() {
      this.requestsStore.clearActiveRequest();
    },
    async createNewRequest() {
      await this.requestsStore.createNewRequest();

      await this.setActiveRequestById(this.drafts[this.drafts.length - 1].id);

      await this.$router.push('/protection-request');
    },
    sumRequestTotals() {
      return this.activeGroup.reduce(
          (a, b) =>
              a + (b['grandTotal'] || 0)
          , 0);
    },
    async openRequestTotalsDialog() {
      this.totals = await this.requestsStore.getRequestTotals();
      this.reportDialog = true;
    },
    getFiltered(e) {
      this.filteredData = e;

      this.sumTotal = e.reduce(
          (a, b) =>
              a + (b['grandTotal'] || 0)
          , 0);
    },
    async exportCsv() {
      let options = {
        keys: [
          'id', 'customerCode', 'planName', 'dateLastModified', 'grandTotal'
        ]
      }
      const dataWithCleanedDates = this.filteredData;

      dataWithCleanedDates.forEach(entry => {
        entry.dateLastModified = entry.dateLastModified.substring(0, 10);
      });

      const text = await json2csv(dataWithCleanedDates, options);

      let filename = 'pp_request_export.csv'
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

      console.log(text);
    }
  },
  computed: {
    showHeaders () {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    },
  },
  watch: {},
  created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  data: () => ({
    search: "",
    selectedItem: sessionStorage.requestGroup,
    showOverlay: true,
    dialog: false,
    reportDialog: false,
    sumTotal: 0,
    filteredData: [],
    headers: [],
    selectedHeaders: [],
    headersMap: {
      id: {text: ('PPR No.'), align: "start", value: "id",},
      customerCode: {text:('Customer Code'), value: "customerCode"},
      planName: {text: ('Plan name'), value: "planName"},
      dateApproved: {text: ('Approved Date'), value: "dateApproved"},
      dateModified: {text: ('Modified Date'), value: "dateLastModified"},
      grandTotal: {text: ('Total (CAD)'), value: "grandTotal"},
      actions: {text: ('Actions'), value: "actions", sortable: false,}
    },
    totals: [
      {
        name: 'DRAFTS',
        total: 0,
      },
      {
        name: 'SUBMITTED',
        total: 0,
      },
      {
        name: 'AUDITED',
        total: 0,
      },
      {
        name: 'APPROVED',
        total: 0,
      },
      {
        name: 'TX SUCCESSFUL',
        total: 0,
      },
      {
        name: 'TX FAILED',
        total: 0,
      },
      {
        name: 'REJECTED',
        total: 0,
      },
    ],
    requestTypes: [
      {
        text: "DRAFTS",
        icon: "mdi-file-edit-outline",
        color: "#757575"
      },
      {
        text: "SUBMITTED",
        icon: "mdi-file-move-outline",
        color: "#FFA726"
      },
      {
        text: "AUDITED",
        icon: "mdi-file-check-outline",
        color: "#FFA726"
      },
      {
        text: "APPROVED",
        icon: "mdi-file-move",
        color: "#FFA726"
      },
      {
        text: "TX SUCCESSFUL",
        icon: "mdi-file-check",
        color: "#1B5E20"
      },
      {
        text: "TX FAILED",
        icon: "mdi-file-remove",
        color: "#B71C1C"
      },
      {
        text: "REJECTED",
        icon: "mdi-file-remove-outline",
        color: "#B71C1C"
      },
    ],
  }),
});
</script>