<template>
	<v-container app class="my-0" fluid>
		<v-app id="inspire">
			<v-stepper v-model="e1">
				<v-stepper-header>
					<v-stepper-step step="1" :complete="e1 > 1" class="py-0">
            {{ $t('labels.draft') }}
          </v-stepper-step>
					<v-divider></v-divider>
					<v-stepper-step step="2" :complete="e1 > 2" class="py-0">
            {{ $t('labels.submitted') }}
          </v-stepper-step>
					<v-divider></v-divider>
					<v-stepper-step step="3" :complete="e1 >= 3" class="py-0">
						{{ getVerdict }}
					</v-stepper-step>
				</v-stepper-header>

				<v-stepper-items>
					<v-stepper-content step="1">
						<PlanDraft @advance-step="e1 += 1" @update-step="updateStep"></PlanDraft>
					</v-stepper-content>
					<v-stepper-content step="2">
            <PlanSubmitted @advance-step="e1 += 1" @reset-step="e1 = 1"></PlanSubmitted>
					</v-stepper-content>
					<v-stepper-content step="3">
						<PlanApproved @advance-step="e1 += 1" @reset-step="e1 = 1"></PlanApproved>
					</v-stepper-content>
				</v-stepper-items>
			</v-stepper>
		</v-app>
	</v-container>
</template>

<script>
import {storeToRefs} from "pinia";
import {useExistingPlansStore} from "@/stores/existingplans";
import _ from "lodash";

import PlanDraft from "@/components/PlanDraft.vue";
import PlanSubmitted from "@/components/PlanSubmitted.vue";
import PlanApproved from "@/components/PlanApproved";

export default {
	name: "ProtectionPlan",
	components: {
		PlanApproved,
		PlanDraft,
		PlanSubmitted,
	},
	setup() {
		const plansStore = useExistingPlansStore();
		let {activePlan} = storeToRefs(plansStore);

		console.log("ProtectionPlan setup");

		return {
			activePlan,
			plansStore
		};
	},
	computed: {
		getVerdict() {
      const t = this.$t.bind(this);
			if (!_.isEmpty(this.activePlan)) {
				const planStatus = this.activePlan.status;

				if (planStatus !== null) {
					switch (planStatus) {
						case 3:
              return t('labels.approved');
						case 4:
              return t('labels.active');
						case 5:
              return t('labels.expired');
						default:
              return t('labels.processing');
					}
				}
			}
      return t('labels.processing');
		}
	},
	data() {
		let stepNum = 1; // case DRAFT
		if (!_.isEmpty(this.activePlan)) {
			const planStatus = this.activePlan.status;

			if (planStatus !== null) {
				switch (planStatus) {
          case 1:
            // draft
            stepNum = 1;
            break;
					case 2:
						// submitted
						stepNum = 2;
						break;
					case 3:
						// approved
						stepNum = 3;
						break;
					case 4:
						// active
						// don't advance past 3. Reuse the APPROVED screen
						stepNum = 3;
						break;
					case 5:
						// expired
						// don't advance past 3. Reuse the APPROVED screen
						stepNum = 3;
						break;
				}
			}
		}

		return {
			e1: stepNum,
		};
	},
	methods: {
		updateStep() {
			let stepNum = 1; // case DRAFT
			if (!_.isEmpty(this.activePlan)) {
				const planStatus = this.activePlan.status;

				if (planStatus !== null) {
					switch (planStatus) {
            case 1:
              // draft
              stepNum = 1;
              break;
						case 2:
							// submitted
							stepNum = 2;
							break;
						case 3:
							// approved
							stepNum = 3;
							break;
						case 4:
							// active
							// don't advance past 3. Reuse the APPROVED screen
							stepNum = 3;
							break;
						case 5:
							// expired
							// don't advance past 3. Reuse the APPROVED screen
							stepNum = 3;
							break;
					}
				}
			}

			this.e1 = stepNum;
		}
	}
};
</script>