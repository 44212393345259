import {defineStore} from 'pinia'
import _ from 'lodash'
import axios from "axios";
import {useUserStore} from "@/stores/user";
import {add} from 'date-fns'

export const useExistingPlansStore = defineStore('existingPlansStore', {
    state: () => ({
        activeGroup: [],
        activePlan: null,
        defaultItem: {
            pid: "0000000",
            name: "",
            lifeTime: ["2022-01-01", "2022-01-30"],
            status: -1,
            items: []
        },
        drafts:
            [],
        submitted:
            [],
        approved:
            [],
        active:
            [],
        expired:
            [],
        approver: {
            firstName: '',
            lastName: '',
            email: '',
        },
        auditor: {
            firstName: '',
            lastName: '',
            email: '',
        }
    }),
    actions: {
        async getDraftPlans() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(userStore.apiUrl + 'plans/status/1', options);
                this.drafts = response.data;
            } catch (e) {
                console.log(e.message);
            }
        },
        async getSubmittedPlans() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(userStore.apiUrl + 'plans/status/2', options);
                this.submitted = response.data;
            } catch (e) {
                console.log(e.message);
            }
        },
        async getApprovedPlans() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(userStore.apiUrl + 'plans/status/3', options);
                this.approved = response.data;
            } catch (e) {
                this.approved = [];
                console.log(e.message);
            }
        },
        async getActivePlans() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(userStore.apiUrl + 'plans/status/4', options);
                this.active = response.data;
            } catch (e) {
                this.active = [];
                console.log(e.message);
            }
        },
        async getExpiredPlans() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(userStore.apiUrl + 'plans/status/5', options);
                this.expired = response.data;
            } catch (e) {
                this.expired = [];
                console.log(e.message);
            }
        },
        async getProduct(itemCode) {
            let response;
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
            };
            try {
                response = await axios.get(userStore.apiUrl + 'products/' + itemCode, options);

                return response.data;
            } catch (e) {
                console.log(e);
                return null;
            }
        },
        async addPlanItem(planItemIndex) {
            try {
                if (planItemIndex < 0) {
                    const length = this.activePlan.items.length;
                    planItemIndex = length - 1;
                }

                const pid = this.activePlan.pid;
                const planItem = this.activePlan.items[planItemIndex];

                planItem.unitPrice = planItem.lastPromo - planItem.newPromo;

                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const options = {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken
                    }
                };

                if (planItem.id === undefined) {
                    const postResponse = await axios.post(
                        userStore.apiUrl + 'plans/' + pid + '/items',
                        planItem,
                        options);

                    if (postResponse.status === 200) {
                        planItem.id = postResponse.data.id;
                    }
                } else {
                    await axios.put(
                        userStore.apiUrl + 'plans/' + pid + '/items/' + planItem.id,
                        planItem,
                        options);
                }
            } catch (e) {
                console.log(e.message);
            }
        },
        async saveChangesToActivePlan() {
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;

                const options = {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken
                    }
                };
                const pid = this.activePlan.pid;
                const response = await axios.put(
                    userStore.apiUrl + 'plans/' + pid,
                    this.activePlan,
                    options);
                return response;
            } catch (e) {
                console.log(e.message);
            }
        },
        async deleteCurrentPlan() {
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const options = {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken
                    }
                };
                const id = this.activePlan.pid;
                const type = this.activePlan.status;

                const response = await axios.delete(
                    userStore.apiUrl + 'plans/' + id,
                    options);

                if (response.status === 200) {
                    console.log('Plan deleted successfully');

                    try {
                        let response = await axios.get(
                            userStore.apiUrl + 'plans/status/' + type,
                            options);
                        this.activeGroup = response.data;
                    } catch (e) {
                        console.log(e.message);
                    }

                    const clearFlag = false;
                    this.setDraftsAsActiveGroup(clearFlag);
                }

            } catch (e) {
                console.log(e);
            }
        },
        async deletePlanItem(itemId) {
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const options = {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken
                    }
                };
                const response = await axios.delete(
                    userStore.apiUrl + 'items/' + itemId,
                    options);

                if (response.status === 200) {
                    console.log('Plan deleted successfully');

                    const clearFlag = false;
                    this.setDraftsAsActiveGroup(clearFlag);
                }
            } catch (e) {
                console.log(e.message);
            }
        },
        async createNewPlan() {
            const sd = new Date();
            const ed = add(new Date(), {days: 30});

            // Timestamp yyyy-mm-dd hh:mm:ss[.fffffffff]
            const startDate = sd.getFullYear() + "-" + (sd.getMonth() + 1) + "-" + sd.getDate();
            const endDate = ed.getFullYear() + "-" + (ed.getMonth() + 1) + "-" + ed.getDate();

            const skeletonPlan = {
                name: "New Price Protection Plan",
                lifeTime: [startDate, endDate],
                status: 1,
                items: [],
            };

            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };
            try {
                const response = await axios.post(
                    userStore.apiUrl + 'plans/',
                    skeletonPlan,
                    options);

                if (response.status === 200) {
                    console.log('Plan created successfully');

                    this.drafts = _.concat(this.drafts, response.data);

                    const clearFlag = false;
                    this.setDraftsAsActiveGroup(clearFlag);
                }
            } catch (e) {
                console.log(e.message);
            }
        },
        async getPlanById(planId) {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(
                    userStore.apiUrl + 'plans/' + planId,
                    options
                );
                return response.data;
            } catch (e) {
                console.log(e.message);
                return {};

            }
        },
        async setActivePlan(pid) {
            this.activePlan = await this.getPlanById(pid);
        },
        async editPlan() {
            let current_plan_status = this.activePlan.status;

            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const options = {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken
                    }
                };

                const pid = this.activePlan.pid;
                this.activePlan.status = 1;
                const response = await axios.put(
                    userStore.apiUrl + 'plans/' + pid,
                    this.activePlan,
                    options);

                if (response.status === 200) {
                    console.log('Record submitted successfully');

                    _.remove(this.activeGroup, {'pid': pid});

                    this.drafts = _.concat(this.submitted, response.data);

                    const clearFlag = false;
                    this.setDraftsAsActiveGroup(clearFlag);

                    return true;
                } else {
                    console.error(JSON.stringify(response));
                    this.activePlan.status = current_plan_status;
                }
            } catch (e) {
                // roll back
                this.activePlan.status = current_plan_status;
                console.log(e.message);
            }
            return false;
        },
        async submitPlan() {
            // todo
            // create a clone of the active plan
            // change the status to active
            // attempt to save the active plan
            // if successful, then remove from the pinia store

            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const options = {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken
                    }
                };
                const pid = this.activePlan.pid;
                this.activePlan.status = 2;
                const response = await axios.put(
                    userStore.apiUrl + 'plans/' + pid,
                    this.activePlan,
                    options);

                if (response.status === 200) {
                    console.log('Record submitted successfully');

                    _.remove(this.activeGroup, {'pid': pid});

                    this.submitted = _.concat(this.submitted, response.data);

                    const clearFlag = false;
                    this.setSubmittedAsActiveGroup(clearFlag);

                    return true;
                } else {
                    console.error(JSON.stringify(response));
                    this.activePlan.status = 1;
                }
            } catch (e) {
                // roll back
                this.activePlan.status = 1;
                console.log(e.message);
            }
            return false;
        },
        async approvePlan() {
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const options = {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken
                    }
                };
                const pid = this.activePlan.pid;
                this.activePlan.status = 3;
                const response = await axios.put(
                    userStore.apiUrl + 'plans/' + pid,
                    this.activePlan,
                    options);

                if (response.status === 200) {
                    console.log('Record approved successfully');

                    _.remove(this.activeGroup, {'pid': pid});

                    this.approved = _.concat(this.approved, response.data);

                    const clearFlag = false;
                    this.setApprovedAsActiveGroup(clearFlag);

                    return true;
                } else {
                    console.error(JSON.stringify(response));
                    this.activePlan.status = 2;
                }
            } catch (e) {
                // roll back
                this.activePlan.status = 2;
                console.log(e.message);
            }
            return false;
        },
        setDraftsAsActiveGroup(clear) {
            this.activeGroup = this.drafts;
            if (clear) this.clearActivePlan();
        },
        setSubmittedAsActiveGroup(clear) {
            this.activeGroup = this.submitted;
            if (clear) this.clearActivePlan();
        },
        setApprovedAsActiveGroup(clear) {
            this.activeGroup = this.approved;
            if (clear) this.clearActivePlan();
        },
        setActiveAsActiveGroup(clear) {
            this.activeGroup = this.active;
            if (clear) this.clearActivePlan();
        },
        setExpiredAsActiveGroup(clear) {
            this.activeGroup = this.expired;
            if (clear) this.clearActivePlan();
        },
        clearActivePlan() {
            this.activePlan = null;
        }
    }
})
