<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-img src="../assets/iStock-1163500880_low.webp"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pa-4">
        {{ $t('home.introMessage') }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {useUserStore} from "@/stores/user";
import {storeToRefs} from "pinia";

export default {
  name: "LandingPage", setup() {
    const userStore = useUserStore();
    let {user} = storeToRefs(userStore);

    console.log("LandingPage setup");

    return {
      userStore,
      user
    };
  },
  async mounted() {
    await this.userStore.setUserData(this.$keycloak);

    await this.userStore.initStore();
  },
};
</script>