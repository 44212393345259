<template>
  <div id="app">
    <v-app>
      <v-app-bar app dense flat>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <img src="./assets/Canon_wordmark.svg" alt="Canon WordMark" class="nav-wordmark"/>
        <v-toolbar-title class="antialiased-smoothing"
        >PRICE PROTECTION
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <div class="text-overline" v-if='user.firstName'>
          {{ user.firstName }} {{ getRole }}
        </div>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" app>
        <v-list nav dense>
          <v-list-item-group
              v-model="group"
              active-class="deep-purple--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-title>
                <router-link to="/">{{ $t("menuItems.home") }}</router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-show="isAuditor || isApprover || isDealer">
              <v-list-item-title>
                <router-link to="/user-profile">{{ $t("menuItems.profile") }}</router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-show="isAuditor || isApprover">
              <v-list-item-title>
                <router-link to="/existing-plans">{{ $t("menuItems.plans") }}</router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-show="isAuditor || isApprover">
              <v-list-item-title>
                <router-link to="/existing-requests">
                  {{ $t("menuItems.requests") }}
                </router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-show="isDealer">
              <v-list-item-title>
                <router-link to="/my-requests">{{ $t("menuItems.myRequests") }}</router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-show="isAuditor || isApprover || isDealer">
              <v-list-item-title>
                <a @click="logout">{{ $t("menuItems.logOut") }}</a>
              </v-list-item-title>
            </v-list-item>

          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-container fluid class="mt-0 pt-0">
          <router-view></router-view>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import {storeToRefs} from "pinia";
import {useUserStore} from "@/stores/user";
import {defineComponent} from "vue-demi";
import {useExistingRequestsStore} from "@/stores/existingrequests";

export default defineComponent({
  name: "App",
  data: () => ({
    drawer: false,
    group: null,
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
  async mounted() {
    // THIS IS USED FOR DIRECT-TO-REQUEST email links
    if (this.$route.query.pprNo !== undefined && this.user.accessToken !== "") {
      sessionStorage.currentPprNo = this.$route.query.pprNo;

      await this.setActiveRequestById(this.$route.query.pprNo);
      await this.$router.push('protection-request');
      sessionStorage.currentPprNo = null;
    } else {
      await this.$router.push('home');
    }
  },
  setup() {
    const requestsStore = useExistingRequestsStore();
    const userStore = useUserStore();
    const {user, isDealer, isAuditor, isApprover} = storeToRefs(userStore);

    return {
      user,
      isDealer,
      isAuditor,
      isApprover,
      userStore,
      requestsStore
    };
  },
  computed: {
    getRole() {
      let role = '';

      this.userStore.isApprover ? role = 'approver' : this.userStore.isAuditor ? role = 'auditor' : this.userStore.isDealer ? role = 'dealer' : '';

      if (role !== '') {
        role = '(' + role + ')';
      }

      return role;
    },
  },
  methods: {
    async sendMail() {
      await this.userStore.sendMail();
    },
    async setActiveRequestById(pid) {
      await this.requestsStore.setActiveRequest(pid);
    },
    logout() {
      this.userStore.$reset();
      this.$keycloak.logout({redirectUri: this.redirectUrl}).then((success) => {
        console.log("--> log: logout success ", success);
      }).catch((error) => {
        console.log("--> log: logout error ", error);
      });
    }
  }
});
</script>
