<template>
  <v-container fluid app>
    <v-row class="text-center">
      <v-col cols="0" sm="3" class="mb-0 pb-0">
        <v-list dense>
          <v-subheader class="pl-0 subtitle-2">PRICE PROTECTION PLANS</v-subheader>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item
                v-for="(item, i) in planTypes"
                :key="i"
                class="pl-0"
                @click="setActiveGroup(item.text)"
            >
              <v-list-item-icon class="ml-1 mr-5">
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content class="text-left">
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-divider></v-divider>
        <v-list dense>
          <v-list-item
              class="pl-0"
              @click="createNewPlan"
          >
            <v-list-item-icon class="ml-1 mr-5">
              <v-icon v-text="'mdi-plus-thick'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-left">
              <v-list-item-title
                  v-text="$t('titles.createNewPlan')"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-col>
      <v-col cols="12" sm="9">
        <v-card-title>
          <v-text-field
              v-model="search"
              append-icon="fa-solid fa-magnifying-glass"
              :label="$t('labels.search')"
              single-line
              hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="getPlanHeaders"
            :items="activeGroup"
            :search="search"
            item-key="pid"
            class="elevation-0"
            :sort-by="['pid']"
            :sort-desc="[true]"
        >
          <template v-slot:[`item.lifeTime`]="{ item }">
            <div class="d-flex justify-space-evenly bg-surface-variant">
              {{ item.lifeTime[0] + ' - ' + item.lifeTime[1] }}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="setActivePlan(item)">
              mdi-eye
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-overlay
        :opacity="0.75"
        :value="showOverlay"
    >
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import {storeToRefs} from "pinia";
import {useExistingPlansStore} from "@/stores/existingplans";
import {defineComponent} from "vue-demi";
import {useUserStore} from "@/stores/user";
import _ from "underscore";

export default defineComponent({
  name: "ExistingPlans",
  setup() {
    const plansStore = useExistingPlansStore();
    const {activeGroup, activePlan, drafts} = storeToRefs(plansStore);

    plansStore.$reset();

    return {
      activeGroup,
      activePlan,
      drafts,
      plansStore
    }
  },
  async mounted() {
    const userStore = useUserStore();
    const {user} = storeToRefs(userStore);

    if (user.accessToken !== null) {
      if (sessionStorage.planGroup === undefined) {
        sessionStorage.planGroup = 0;
      }
      await this.setActiveGroupWithSession();
    } else {
      await this.$router.push('home');
    }
  },
  beforeRouteEnter(to, from, next) {
    const userStore = useUserStore();
    const {user} = storeToRefs(userStore);

    const {authorize} = to.meta;
    const roles = user.value.roles;

    if (_.intersection(authorize, roles).length > 0) {
      next();
    } else {
      return false;
    }
  },
  methods: {
    async setActiveGroupWithSession() {
      const userStore = useUserStore();
      const {user} = storeToRefs(userStore);

      if (user.accessToken !== null) {
        switch (sessionStorage.planGroup) {
          case "1":
            await this.setActiveGroup("SUBMITTED");
            break;
          case "2":
            await this.setActiveGroup("APPROVED");
            break;
          case "3":
            await this.setActiveGroup("ACTIVE");
            break;
          case "4":
            await this.setActiveGroup("EXPIRED");
            break;
          case "0":
          default:
            await this.setActiveGroup("DRAFTS");
            break;
        }
      } else {
        await this.$router.push('/home');
      }
    },
    async setActiveGroup(type) {
      this.showOverlay = true;
      switch (type) {
        case "SUBMITTED": {
          await this.plansStore.getSubmittedPlans();
          this.plansStore.setSubmittedAsActiveGroup(true);
          this.selectedItem = sessionStorage.planGroup = 1;
          break;
        }
        case "APPROVED": {
          await this.plansStore.getApprovedPlans();
          this.plansStore.setApprovedAsActiveGroup(true);
          this.selectedItem = sessionStorage.planGroup = 2;
          break;
        }
        case "ACTIVE":
          await this.plansStore.getActivePlans();
          this.plansStore.setActiveAsActiveGroup(true);
          this.selectedItem = sessionStorage.planGroup = 3;
          break;
        case "EXPIRED": {
          await this.plansStore.getExpiredPlans();
          this.plansStore.setExpiredAsActiveGroup(true);
          this.selectedItem = sessionStorage.planGroup = 4;
          break;
        }
        default: {
          await this.plansStore.getDraftPlans();
          this.plansStore.setDraftsAsActiveGroup(true);
          this.selectedItem = sessionStorage.planGroup = 0;
          break;
        }
      }
      this.showOverlay = false;
    },

    async setActivePlan(value) {
      const pid = value.pid;
      await this.setActivePlanById(pid);
    },
    async setActivePlanById(pid) {
      await this.plansStore.setActivePlan(pid);

      await this.$router.push('protection-plan');
    },
    clearActivePlan() {
      this.plansStore.clearActivePlan();
    },
    async createNewPlan() {
      await this.plansStore.createNewPlan();

      await this.setActivePlanById(this.drafts[this.drafts.length - 1].pid);

      await this.$router.push('protection-plan');
    },
  },
  computed: {
    getPlanHeaders() {
      const t = this.$t.bind(this)
      return [
        {
          text: t('headers.planId'),
          align: "start",
          value: "pid",
        },
        {text: t('headers.name'), value: "name"},
        {text: t('headers.activeDates'), value: "lifeTime"},
        {
          text: t('headers.actions'),
          value: "actions",
          sortable: false,
        },
      ]
    }
  },
  data: () => ({
    search: "",
    selectedItem: sessionStorage.planGroup,
    showOverlay: true,
    planTypes: [
      {
        text: "DRAFTS",
        icon: "mdi-numeric-0-circle-outline",
      },
      {
        text: "SUBMITTED",
        icon: "mdi-numeric-1-circle-outline",
      },
      {
        text: "APPROVED",
        icon: "mdi-numeric-2-circle-outline",
      },
      {
        text: "ACTIVE",
        icon: "mdi-clock-time-two-outline",
      },
      {
        text: "EXPIRED",
        icon: "mdi-clock-remove-outline",
      },
    ],
  }),
});
</script>