import {defineStore} from 'pinia'
import _ from "lodash"
import axios from "axios";
import {Role} from "@/_helpers/role";

export const useUserStore = defineStore('userStore', {
    state: () => ({
        user:
            {
                firstName: '',
                lastName: '',
                email: '',
                roles: [],
                customerCode: '',
                dealerName: '',
                accessToken: '',
                username: ''
            },
        isDealer: true,
        isAuditor: false,
        isApprover: false,
        shops: [{}],
        auditors: [{}],
        approvers: [{}],
        redirectUrl: '',
        apiUrl: '',
    }),
    actions: {
        async setUserData(keycloak) {
            const uData = keycloak.tokenParsed;

            if (_.startsWith(process.env.VUE_APP_PP_BASE_URL, window.location.origin)) {
                this.redirectUrl = process.env.VUE_APP_PP_REDIRECT_URL;
                this.apiUrl = process.env.VUE_APP_PP_API_URL;
            } else {
                this.redirectUrl = process.env.VUE_APP_PP_REDIRECT_DEV_URL;
                this.apiUrl = process.env.VUE_APP_PP_API_DEV_URL;
            }

            this.user.accessToken = keycloak.token;

            this.user.firstName = uData.given_name;
            this.user.lastName = uData.family_name;
            this.user.email = uData.email;
            this.user.username = uData.preferred_username;

            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const username = userStore.user.username;

            this.user.kid = uData.sub;

            try {
                this.user.roles = uData.resource_access['pp-app'].roles;

                this.isDealer = _.includes(this.user.roles, Role.DEALER);
                this.isAuditor = _.includes(this.user.roles, Role.AUDITOR);
                this.isApprover = _.includes(this.user.roles, Role.APPROVER);

                if (this.isAuditor || this.isApprover) {
                    try {
                        const skeletonRequest = {
                            firstName: this.user.firstName,
                            lastName: this.user.lastName,
                            email: this.user.email,
                            role: this.isAuditor ? 'AUDITOR' : 'APPROVER'
                        };

                        await axios.post(
                            this.apiUrl + 'admins',
                            skeletonRequest,
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + accessToken,
                                }
                            }
                        );
                    } catch (e) {
                        console.error('Error locating user ' + e);
                    }
                }
            } catch (e) {
                this.user.roles[0] = Role.DEALER;
                this.isDealer = true;
            }

            let response;

            response = await axios.get(
                this.apiUrl + 'admins?role=APPROVER',
                {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }
            );

            if (response.status === 200) {
                this.approvers = response.data;
            }

            response = await axios.get(
                this.apiUrl + 'admins?role=AUDITOR',
                {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }
            );

            if (response.status === 200) {
                this.auditors = response.data;
            }

            try {
                response = await axios.get(
                    this.apiUrl + 'shopusers/' + username,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken,
                        }
                    }
                );

                if (response.status === 200) {
                    const shop = response.data;
                    const clientKeySoldTo = shop.clientKeySoldTo !== null ? (shop.clientKeySoldTo.split(","))[0] : null;
                    const shopName = shop.name;

                    this.user.customerCode = clientKeySoldTo !== null ? clientKeySoldTo : shop.clientKey;
                    this.user.dealerName = shopName !== null ? shopName : '';
                } else {
                    this.user.customerCode = '';
                    this.user.dealerName = '';
                }
            } catch (e) {
                console.error(e);
                this.user.customerCode = '';
                this.user.dealerName = '';
            }
        },
        async initStore() {
            let response;
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;

                response = await axios.get(
                    this.apiUrl + 'shops',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken,
                        }
                    }
                );

                if (response.status === 200) {
                    this.shops = response.data;
                }
            } catch (e) {
                console.error(e);
            }
        },
        async sendMail() {
            console.log('Sending mail');
            let response;
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;

                response = await axios.get(
                    this.apiUrl + 'email/messages',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken,
                        }
                    }
                );

                if (response.status === 200) {
                    console.log('Message sent!');
                }
            } catch (e) {
                console.error(e);
            }
        },
        logout() {
            this.$reset();
            this.$keycloak.logout({redirectUri: this.redirectUrl}).then((success) => {
                console.log("--> log: logout success ", success);
            }).catch((error) => {
                console.log("--> log: logout error ", error);
            });
        }
    }
})
