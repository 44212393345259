import {defineStore} from 'pinia'
import _ from "lodash";
import axios from "axios";
import {useUserStore} from "@/stores/user";


export const useExistingRequestsStore = defineStore('existingRequestsStore', {
    data: () => ({
        t: this.$t.bind(this)
    }),
    state: () => ({
        activeGroup: [],
        activeRequest: null,
        drafts:
            [],
        submitted:
            [],
        rejected:
            [],
        audited:
            [],
        approved:
            [],
        txSuccess:
            [],
        txError:
            [],
        approver: {
            firstName: '',
            lastName: '',
            email: '',
        },
        auditor: {
            firstName: '',
            lastName: '',
            email: '',
        }
    }),

    actions: {
        async getRequestTotals() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(
                    userStore.apiUrl + 'requests/totals',
                    options
                );
                return response.data;
            } catch (e) {
                console.log(e.message);
                return [];
            }
        },
        async deleteAttachment(filename) {
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const id = this.activeRequest.id;

                const response = await axios.delete(
                    userStore.apiUrl + 'requests/' + id + '/attachments?filename=' + encodeURIComponent(filename),
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        },
                        validateStatus: () => true
                    });

                if (response.status === 200) {
                    console.log('Attachment deleted successfully');
                    this.activeRequest.attachmentFilePath = response.data;
                    return true;
                } else {
                    console.log('Delete attachment failed');
                    this.activeRequest.attachmentFilePath = response.data;
                }

            } catch (e) {
                console.log(e);
            }
            return false;
        },
        async uploadAttachment(attachment) {
            console.log("Uploading attachment " + attachment);
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                let formData = new FormData();

                const id = this.activeRequest.id;

                formData.append("sn-attachment", attachment);

                const filePath = 'requests/' + id + '/attachments';

                const response = await axios.post(
                    userStore.apiUrl + filePath,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            'Authorization': 'Bearer ' + accessToken
                        },
                        validateStatus: () => true
                    });

                if (response.status === 200) {
                    // TODO change the attachment chip to reflect the upload
                    this.activeRequest.attachmentFilePath = response.data;
                    console.log('Attachment uploaded successfully');
                } else {
                    this.activeRequest.attachmentFilePath = response.data;
                    return false;
                }
            } catch (e) {
                console.log(e);
                return false;
            }
            return true;
        },
        async downloadAttachment() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                responseType: 'arraybuffer',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'responseType': 'blob'
                }
            };

            try {
                axios
                    .get(
                        userStore.apiUrl + 'requests/' + this.activeRequest.id + '/attachments',
                        options
                    )
                    .then(response => {

                        let blob = new Blob(
                            [response.data],
                            {type: response.headers['content-type']}
                        )

                        let imgUrl = URL.createObjectURL(blob)
                        let a = document.createElement('a');
                        a.href = imgUrl;
                        a.download = "pprno" + this.activeRequest.id + "_download.zip";
                        a.click();
                        window['URL'].revokeObjectURL(imgUrl);
                    })
                    .catch(error => {
                        alert("something goes wrong! Maybe image url broken, try another img url." + error)
                    })
            } catch (e) {
                console.log(e.message);
            }
        },
        async getRequestById(requestId) {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(
                    userStore.apiUrl + 'requests/' + requestId,
                    options
                );
                return response.data;
            } catch (e) {
                console.log(e.message);
                return [];
            }
        },
        async getDraftRequests() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(
                    userStore.apiUrl + 'requests/status/1',
                    options
                );
                this.drafts = response.data;
            } catch (e) {
                console.log(e.message);
            }
        },
        async getSubmittedRequests() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(userStore.apiUrl + 'requests/status/2',
                    options
                );
                this.submitted = response.data;
            } catch (e) {
                console.log(e.message);
            }
        },
        async getAuditedRequests() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(userStore.apiUrl + 'requests/status/3',
                    options);
                this.audited = response.data;
            } catch (e) {
                console.log(e.message);
            }
        },
        async getApprovedRequests() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(userStore.apiUrl + 'requests/status/4',
                    options);
                this.approved = response.data;
            } catch (e) {
                console.log(e.message);
            }
        },
        async getTxSuccessRequests() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(userStore.apiUrl + 'requests/status/5',
                    options);
                this.txSuccess = response.data;
            } catch (e) {
                console.log(e.message);
            }
        },
        async getTxErrorRequests() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(userStore.apiUrl + 'requests/status/7',
                    options);
                this.txError = response.data;
            } catch (e) {
                console.log(e.message);
            }
        },
        async getRejectedRequests() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(userStore.apiUrl + 'requests/status/6',
                    options);
                this.rejected = response.data;
            } catch (e) {
                console.log(e.message);
            }
        },
        async getProduct(itemCode) {
            let response;
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
            };
            try {
                response = await axios.get(userStore.apiUrl + 'products/' + itemCode,
                    options);

                return response.data;
            } catch (e) {
                console.log(e);
                return null;
            }
        },
        async deleteActiveRequest() {
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const id = this.activeRequest.id;
                const type = this.activeRequest.requestStatus;

                const response = await axios.delete(
                    userStore.apiUrl + 'requests/' + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });

                if (response.status === 200) {
                    console.log('Request deleted successfully');

                    try {
                        let response = await axios.get(userStore.apiUrl + 'requests/status/' + type, {
                            headers: {
                                'Authorization': 'Bearer ' + accessToken
                            }
                        });
                        this.activeGroup = response.data;
                    } catch (e) {
                        console.log(e.message);
                    }

                    const clearFlag = false;
                    this.setDraftsAsActiveGroup(clearFlag);
                }

            } catch (e) {
                console.log(e);
            }
        },
        async addRequestItem(requestItemIndex) {
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                if (requestItemIndex < 0) {
                    const length = this.activeRequest.items.length;
                    requestItemIndex = length - 1;
                }

                const id = this.activeRequest.id;
                const requestItem = this.activeRequest.items[requestItemIndex];
                this.activeRequest.lastModifiedByUser = userStore.user.username;

                if (requestItem.id === undefined) {
                    const postResponse = await axios.post(
                        userStore.apiUrl + 'requests/' + id + '/items',
                        requestItem,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + accessToken
                            }
                        });

                    if (postResponse.status === 200) {
                        requestItem.id = postResponse.data.id;

                        return postResponse.data;
                    }
                } else {
                    await axios.put(
                        userStore.apiUrl + 'requests/' + id + '/items/' + requestItem.id,
                        requestItem,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + accessToken
                            }
                        });
                }
            } catch (e) {
                console.log(e.message);
            }
        },
        async deleteRequestItem(itemId) {
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;

                const response = await axios.delete(
                    userStore.apiUrl + 'requestitems/' + itemId,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });
                if (response.status === 200) {
                    console.log('Deleted request item successfully');
                }
            } catch (e) {
                console.log('Could not delete request item id ' + itemId);
            }
        },
        async saveChangesToActiveRequest(commentsSectionToCopy = null) {
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const id = this.activeRequest.id;
                let cleanActiveRequest = this.activeRequest;

                this.activeRequest.lastModifiedByUser = userStore.user.username;

                // HACK. Needed to add a fake/temp id to the serial number
                // because the NEW serial numbers kept having the same typed-in
                // value.
                for (let i = 0; i < cleanActiveRequest.items.length; i++) {
                    let item = cleanActiveRequest.items[i];
                    for (let j = 0; j < item.serialNumbers.length; j++) {
                        let sn = item.serialNumbers[j];
                        if (sn.isDirty === true) {
                            sn.id = null;
                        }
                    }
                }

                const response = await axios.put(
                    userStore.apiUrl + 'requests/' + id + ((commentsSectionToCopy !== null) ? '?copyCommentSection=' + commentsSectionToCopy : ''),
                    cleanActiveRequest,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        },
                        validateStatus: () => true
                    });

                if (response.status === 200) {
                    console.log('Request saved successfully');
                    this.activeRequest = response.data;

                    return {
                        success: true,
                        message: response.data
                    };
                } else {
                    return {
                        success: false,
                        message: response.data,
                        title: "Error During Save"
                    };
                }
            } catch
                (e) {
                console.log(e.message);
            }
        }
        ,
        async createNewRequest() {
            // todo take this out; constants
            // eliminate side-effects

            const skeletonRequest = {
                planName: "",
                startDate: new Date().toISOString().slice(0, 10),
                requestStatus: 1,
                items: [],
            };

            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const response = await axios.post(
                    userStore.apiUrl + 'requests/',
                    skeletonRequest,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });

                if (response.status === 200) {
                    console.log('Request created successfully');

                    this.drafts = _.concat(this.drafts, response.data);

                    const clearFlag = false;
                    this.setDraftsAsActiveGroup(clearFlag);
                }
            } catch (e) {
                console.log(e.message);
            }
        }
        ,
        setDraftsAsActiveGroup(clear) {
            this.activeGroup = this.drafts;
            if (clear) this.clearActiveRequest();
        }
        ,
        setSubmittedAsActiveGroup(clear) {
            this.activeGroup = this.submitted;
            if (clear) this.clearActiveRequest();
        }
        ,
        setRejectedAsActiveGroup(clear) {
            this.activeGroup = this.rejected;
            if (clear) this.clearActiveRequest();
        },
        setAuditedAsActiveGroup(clear) {
            this.activeGroup = this.audited;
            if (clear) this.clearActiveRequest();
        },
        setApprovedAsActiveGroup(clear) {
            this.activeGroup = this.approved;
            if (clear) this.clearActiveRequest();
        },
        setTxSuccessAsActiveGroup(clear) {
            this.activeGroup = this.txSuccess;
            if (clear) this.clearActiveRequest();
        },
        setTxErrorAsActiveGroup(clear) {
            this.activeGroup = this.txError;
            if (clear) this.clearActiveRequest();
        },
        async setActiveRequest(pprNo) {
            this.activeRequest = await this.getRequestById(pprNo);
        },
        async auditRequest() {
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const id = this.activeRequest.id;
                this.activeRequest.requestStatus = 3;
                this.activeRequest.lastModifiedByUser = userStore.user.username;
                const response = await axios.put(
                    userStore.apiUrl + 'requests/' + id,
                    this.activeRequest,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });

                if (response.status === 200) {
                    // todo maybe do this instead -> call getRequestsFromApi()
                    console.log('Record audited successfully');

                    _.remove(this.activeGroup, {'id': id});

                    this.audited = _.concat(this.audited, response.data);

                    this.activeRequest = response.data;

                    const clearFlag = false;
                    this.setAuditedAsActiveGroup(clearFlag);

                    return true;
                } else {
                    console.error(JSON.stringify(response));
                    this.activeRequest.status = 2;
                }
            } catch (e) {
                // roll back
                this.activeRequest.status = 2;
                console.log(e.message);
            }
            return false;
        },
        async approveRequest() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const id = this.activeRequest.id;
            this.activeRequest.requestStatus = 4;
            this.activeRequest.lastModifiedByUser = userStore.user.username;
            const response = await axios.put(
                userStore.apiUrl + 'requests/' + id,
                this.activeRequest,
                {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken
                    },
                    validateStatus: () => true
                });

            if (response.status === 200) {
                // todo maybe do this instead -> call getRequestsFromApi()
                console.log('Record approved successfully');

                _.remove(this.activeGroup, {'id': id});

                this.approved = _.concat(this.approved, response.data);

                this.activeRequest = response.data;

                const clearFlag = false;
                this.setApprovedAsActiveGroup(clearFlag);

                return {
                    success: true,
                    message: response.data
                };
            } else {
                const clearFlag = false;
                console.error(JSON.stringify(response));
                this.activeRequest.status = 3;
                this.setAuditedAsActiveGroup(clearFlag);

                return {
                    success: false,
                    message: response.data,
                    title: "Error During Approval"
                };
            }

        },
        async demoteRequest(newStatus) {
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const id = this.activeRequest.id;
                this.activeRequest.requestStatus = newStatus;
                this.activeRequest.lastModifiedByUser = userStore.user.username;
                const response = await axios.put(
                    userStore.apiUrl + 'requests/' + id,
                    this.activeRequest,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });

                if (response.status === 200) {
                    // todo maybe do this instead -> call getRequestsFromApi()
                    console.log('Record demoted successfully');

                    _.remove(this.activeGroup, {'id': id});

                    this.submitted = _.concat(this.submitted, response.data);

                    this.activeRequest = response.data;


                    const clearFlag = false;
                    this.setSubmittedAsActiveGroup(clearFlag);

                    return true;
                } else {
                    const clearFlag = false;
                    console.error(JSON.stringify(response));
                    this.activeRequest.status = 3;
                    this.setAuditedAsActiveGroup(clearFlag);
                }
            } catch (e) {
                // roll back
                this.activeRequest.status = 3;
                console.log(e.message);
            }
            return false;
        },
        async rejectRequest() {
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const id = this.activeRequest.id;
                this.activeRequest.requestStatus = 6;
                this.activeRequest.lastModifiedByUser = userStore.user.username;
                const response = await axios.put(
                    userStore.apiUrl + 'requests/' + id,
                    this.activeRequest,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });

                if (response.status === 200) {
                    // todo maybe do this instead -> call getRequestsFromApi()
                    console.log('Record rejected successfully');

                    _.remove(this.activeGroup, {'id': id});

                    this.rejected = _.concat(this.rejected, response.data);

                    this.activeRequest = response.data;

                    const clearFlag = false;
                    this.setApprovedAsActiveGroup(clearFlag);
                    return true;
                } else {
                    console.error(JSON.stringify(response));
                    this.activeRequest.status = 3;
                }
            } catch (e) {
                // roll back
                this.activeRequest.status = 3;
                console.error(e.message);
            }
            return false;
        },
        clearActiveRequest() {
            this.activeRequest = null;
        }
    }
})
