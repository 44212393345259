<template>
  <div v-if="activeRequest !== null">
    <v-form
        ref="form"
        v-model="valid">
      <v-card class="my-4" elevation="0">
        <v-list-item>
          <v-list-item-content class="pb-0">
            <div class="text-overline mb-1">Price Protection Request</div>

            <v-list-item-subtitle class="mt-0 mb-3">
              {{ $t('labels.pprNo') }} {{ activeRequest.id }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="mt-1" v-if="activeRequest.dateSubmitted">
              {{ $t('labels.submittedBy') }} {{ activeRequest.createdByUser }} on {{ activeRequest.dateSubmitted }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="mt-1" v-if="activeRequest.lastModifiedByUser !== null">
              {{ $t('labels.lastModifiedBy') }} {{ activeRequest.lastModifiedByUser }} on
              {{ activeRequest.dateLastModified }}
            </v-list-item-subtitle>

            <v-list-item-title class="mt-4">
              <v-text-field
                  :label="$t('labels.protectionPlan')"
                  v-model="activeRequest.planName"
                  :rules="[rules.required]"
              ></v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="pt-0 mt-0 mb-4">
          <v-select class="pt-0"
                    v-if="`${selectedShop.clientKeySoldTo} !== undefined`"
                    v-model="selectedShop"
                    :items="shops"
                    item-text="name"
                    item-value="name"
                    :label="$t('labels.dealer')"
                    return-object
                    :rules="[rules.requiredSelect]"
                    required
                    @change="activeRequest.customerCode = getCustomerCode(); activeRequest.dealerName = selectedShop.name"
          >
          </v-select>
        </v-list-item>
        <v-list-item class="pt-0 mt-0">
          <v-text-field
              :label="$t('labels.clientKeySoldTo')"
              class="pt-0 mt-0"
              v-model="activeRequest.customerCode"
              :rules="[rules.required]"
          ></v-text-field>
        </v-list-item>
        <v-list-item class="pt-0 mt-0">
          <v-text-field
              :label="$t('labels.dealerReferenceNumber')"
              class="pt-0 mt-0"
              v-model="activeRequest.dealerReferenceNo"
              :counter="21"
              :rules="[rules.max21Chars]"
          ></v-text-field>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="py-0 my-0 mx-0 px-0">
            <v-row>
              <v-col cols="12" sm="6">
                <v-menu
                    ref="dateMenu"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="formattedStartDate"
                        :label="$t('labels.startDate')"
                        prepend-icon="mdi-calendar-month-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="activeRequest.startDate"
                      no-title
                      scrollable
                      @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="py-3 my-0 mx-0 px-0">
            <v-row>
              <v-col cols="12" sm="6">
                <v-btn-toggle
                    v-model="activeRequest.taxRateType"
                    color="red darken-2"
                    mandatory
                >
                  <v-btn :value="0" rounded>
                    {{ $t("buttons.taxExempt") }}
                  </v-btn>

                  <v-btn :value="1">
                    {{ $t("buttons.oldRate") }}
                  </v-btn>

                  <v-btn :value="2">
                    {{ $t("buttons.s21Default") }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-data-table
          :headers="getHeaders"
          :items="activeRequest.items"
          sort-by="creditCode"
          class="elevation-0 mb-5"
          @current-items="currentItems"
      >
        <template v-slot:top>
          <v-toolbar flat class="pr-0">
            <v-spacer></v-spacer>
            <v-form
                ref="newItemForm"
                v-model="newItemDialogValid"
            >
              <v-dialog v-model="newItemDialog" persistent max-width="500px">
                <template v-slot:activator="{ on, attrs }" v-if="!ifDealerCreatedRequest">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="clear">
                    {{ $t("buttons.newItem") }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                    <v-dialog
                        v-model="productSearchAlert"
                        width="500"
                    >
                      <v-card>
                        <v-card-title class="grey lighten-2">
                          {{ $t("vcard.badSearchResults.title") }}
                        </v-card-title>
                        <v-card-text>
                          {{ $t("vcard.badSearchResults.text") }}
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                              v-model="editedItem.qtyOnHand"
                              type="number"
                              :label="$t('labels.quantity')"
                              min="0"
                              :rules="[rules.gtZero]"
                              @change="calcUnitPrice; calcTotalPrice"
                              @input="quantityChanged"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                              v-model="editedItem.itemCode"
                              :rules="[rules.itemCode]"
                              type="alphanumeric"
                              :label="$t('labels.itemCode')"
                              @keydown.enter.prevent="getProduct"
                              @blur="getProduct"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                              v-model="editedItem.creditCode"
                              :label="$t('labels.creditCode')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                              v-model="editedItem.description"
                              :label="$t('labels.description')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" v-if="!ifDealerCreatedRequest">
                          <v-text-field
                              v-model="editedItem.invoicePrice"
                              type="number"
                              :rules="[rules.gteZero]"
                              :label="$t('labels.invoicePriceCad')"
                              min="0.00"
                              prefix="$"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" v-if="!ifDealerCreatedRequest">
                          <v-text-field
                              v-model="editedItem.discountPercentage"
                              type="number"
                              :rules="[rules.required]"
                              :label="$t('labels.percentage')"
                              suffix="%"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" v-if="!ifDealerCreatedRequest">
                          <v-text-field
                              :value="calcNewPrice"
                              type="number"
                              :rules="[rules.gteZero]"
                              :label="$t('labels.newPriceCad')"
                              min="0.00"
                              prefix="$"
                              readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                              :value="calcUnitPrice"
                              type="number"
                              :rules="[rules.gteZero]"
                              :label="$t('labels.diffUnitCad')"
                              prefix="$"
                              min="0.00"
                              readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                              :value="calcTotalPrice"
                              type="number"
                              :rules="[rules.gteZero]"
                              :label="$t('labels.totalCad')"
                              prefix="$"
                              min="0.00"
                              readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      {{ $t("buttons.cancel") }}
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="saveNewRequestItem" :disabled="!newItemDialogValid">
                      {{ $t("buttons.save") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="serialNumberDialog" persistent max-width="500px" v-if="activeRequest.items.length > 0">
                <v-card>
                  <v-card-title>Serial Numbers for {{ editedItem.description }} ({{
                      editedItem.itemCode
                    }})
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-data-table
                          :headers="getSnHeader"
                          :items="activeRequest.items[editedIndex === -1 ? 0 : editedIndex].serialNumbers">
                        <template v-slot:[`item.value`]="{ item }">
                          <v-text-field
                              class="pt-0 mt-0"
                              v-model="item.value"
                              type="string"
                          ></v-text-field>
                        </template>
                      </v-data-table>

                      <v-card
                          class="d-flex pt-5"
                          flat
                      >
                        <v-spacer></v-spacer>
                        <div>
                          <v-btn @click="serialNumberDialog = false">CLOSE</v-btn>
                        </div>
                      </v-card>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-dialog v-model="deleteItemDialog" max-width="500px" v-if="activeRequest.items.length > 0">
                <v-card>
                  <v-card-title class="text-h5"
                  >Are you sure you want to delete this item?
                  </v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">
                      {{ $t("buttons.cancel") }}
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                      {{ $t("buttons.ok") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>

          </v-toolbar>
        </template>

        <template v-slot:[`item.invoicePrice`]="{ item }">
          <div>
            {{ item.invoicePrice | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.newPrice`]="{ item }">
          <div>
            {{ item.newPrice | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.lastPromo`]="{ item }">
          <div>
            {{ item.lastPromo | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.newPromo`]="{ item }">
          <div>
            {{ item.newPromo | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.discountPercentage`]="{ item }">
          <div>
            {{ item.discountPercentage + "%" }}
          </div>
        </template>

        <template v-slot:[`item.differencePerUnit`]="{ item }">
          <div>
            {{ item.differencePerUnit | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.total`]="{ item }">
          <div>
            {{ item.total | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small :title="$t('iconTitles.editItem')" class="mr-2" @click="editItem(item)">
            mdi-pencil-outline
          </v-icon>
          <v-icon
              small
              :title="$t('iconTitles.viewSerialNos')"
              class="mr-2"
              @click="viewSerialNumbers(item)"
          >
            mdi-clipboard-list-outline
          </v-icon>
          <v-icon small :title="$t('iconTitles.deleteItem')" @click="deleteItem(item)">
            mdi-trash-can-outline
          </v-icon>
        </template>

        <template v-slot:footer>
          <v-divider></v-divider>
          <v-card
              class="d-flex pr-4"
              elevation="0"
          >
            <v-spacer></v-spacer>
            <div>
              <v-chip label color="lightgrey darken-1" text-color="black" class="font-weight-medium my-3">
                Grand Total
                (CAD): {{ activeRequest.grandTotal | toCAD }}
              </v-chip>
            </div>
          </v-card>
        </template>
      </v-data-table>

      <v-divider></v-divider>

      <div>
        <v-card-text class="pb-0">
          <div class="text-overline">
            Attachments
          </div>
        </v-card-text>
      </div>

      <v-card elevation="0" class="mb-3">
        <v-list-item class="px-0">
          <v-list-item-content class="d-flex pa-0">
            <v-card
                class="d-flex flex-row mb-1"
                flat
                tile
            >
              <v-file-input
                  v-model="currentFile"
                  small-chips
                  show-size
                  counter
                  accept="*"
                  :label="$t('labels.selectSnFile')"
              ></v-file-input>
              <v-btn
                  class="my-auto mx-4"
                  @click="pushAttachmentToQueue"
                  :disabled="currentFile === null || currentFile === ''"
              >
                {{ $t('buttons.upload') }}
              </v-btn>
            </v-card>

          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-chip-group column v-if="activeRequest.attachmentFilePath">
            <div
                v-for="file in activeRequest.attachmentFilePath.split('|')"
                :key="file"
            >
              <v-chip
                  class="my-2 text-h7 mx-2"
                  color="#C0C4C6"
                  label
                  close
                  @click:close="deleteAttachment(file)"
              >
                <v-icon left>
                  mdi-file
                </v-icon>
                {{ file }}
              </v-chip>
            </div>
          </v-chip-group>
          <v-chip-group column v-if="uploadQueue.length > 0">
            <div
                v-for="el in uploadQueue"
                :key="el.name"
            >
              <v-chip
                  class="my-2 text-h7 mx-2"
                  color="#C6C2C0"
                  label
                  close
                  @click:close="popAttachmentFromQueue(el)"
              >
                <v-icon left>
                  mdi-file-plus-outline
                </v-icon>
                {{ el.name }}
              </v-chip>
            </div>
          </v-chip-group>
        </v-list-item>
      </v-card>

      <v-divider></v-divider>

      <v-container fluid class="pt-5 px-0">
        <v-textarea
            v-model="activeRequest.requestersComments"
            counter
            filled
            auto-grow
            :label="$t('labels.requestersComments')"
        ></v-textarea>
      </v-container>
      <v-container fluid class="pt-0 px-0">
        <v-textarea
            v-model="activeRequest.invoiceComments"
            counter
            filled
            auto-grow
            :label="$t('labels.invoiceComments')"
        ></v-textarea>
      </v-container>
      <v-container fluid class="pt-0 px-0">
        <v-textarea
            v-model="activeRequest.internalComments"
            counter
            filled
            auto-grow
            :label="$t('labels.internalComments')"
        ></v-textarea>
      </v-container>
      <v-container fluid class="pt-0 px-0">
        <v-textarea
            v-model="activeRequest.commentsHistory"
            counter
            filled
            auto-grow
            readonly
            :label="$t('labels.commentsHistory')"
        ></v-textarea>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-form
        ref="approverForm"
        v-model="approverValid">
      <div class="text-overline pb-0">Approver</div>
      <v-select class="pt-0"
                v-model="selectedApprover"
                :items="approvers"
                :item-text="item => item.firstName + ' ' + item.lastName"
                item-value="email"
                return-object
                :label="$t('labels.selectApprover')"
                :rules="[rules.requiredApproverSelect]"
                required
                persistent-hint
                single-line
                @change="approver.firstName = selectedApprover.firstName; approver.lastName = selectedApprover.lastName; approver.email = selectedApprover.email"
      >
      </v-select>
    </v-form>

    <v-divider></v-divider>

    <v-row class="pt-5 pb-3">
      <v-col cols="12" class="text-center">
        <v-btn @click="printWindow" :titles="$t('linkTitles.printWindow')" class="mr-4 mb-4">
          {{ $t("buttons.print") }}
        </v-btn>
        <v-dialog v-model="saveDialog" persistent max-width="350">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" v-bind="attrs" v-on="on" :disabled="disableSave" class="mr-4 mb-4">
              {{ $t("buttons.save") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("vcard.saveRequest.title") }}
            </v-card-title>
            <v-card-text>
              {{ $t("vcard.saveRequest.text") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="saveDialog = false">
                Cancel
              </v-btn>
              <v-btn color="red darken-1" text @click="saveRequest();">
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="submitDialog" persistent max-width="450">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" class="mr-4 mb-4" :disabled="disableSubmit">
              {{ $t("buttons.submit") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              Submit Request for Final Approval?
            </v-card-title>
            <v-card-text>
              As an admin, you are giving this request first approval. Upon second approval by an
              Approver, this
              request will be transferred to S21.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="submitDialog = false">
                {{ $t("buttons.disagree") }}
              </v-btn>
              <v-btn color="red darken-1" text @click="auditRequest(); $emit('request-audited');">
                {{ $t("buttons.agree") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn class="mr-4 mb-4">
          <router-link to="/existing-requests" :title="$t('linkTitles.backToExistingRequests')"
                       class="text-decoration-none">
            {{ $t("buttons.back") }}
          </router-link>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-dialog v-model="deleteRequestDialog" persistent max-width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="red darken-2" large :title="$t('iconTitles.deleteRequest')" v-bind="attrs"
                    v-on="on">
              mdi-delete-circle
            </v-icon>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              Are you sure you want to delete this request?
            </v-card-title>
            <v-card-text>
              Upon deletion, this request will be permanently removed and cannot be retrieved.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="deleteRequestDialog = false">
                Cancel
              </v-btn>
              <v-btn color="red darken-1" text @click="deleteActiveRequest">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog
          v-model="messageDialog"
          persistent
          width="512"
      >
        <v-card class="m-3">
          <v-card-title>
            {{ messageDialogTitle }}
          </v-card-title>
          <v-card-text>
            {{ messageDialogText }}
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="blue-darken-1"
                variant="text"
                @click="messageDialog = false"
                class="mb-3"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
  <div v-else>

  </div>
</template>

<script>
import {storeToRefs} from "pinia";
import {useExistingRequestsStore} from "@/stores/existingrequests";
import {defineComponent} from "vue-demi";
import {useUserStore} from "@/stores/user";
import _ from "lodash"
import randomString from "randomstring";

export default defineComponent({
  name: "RequestDraft",
  setup() {
    const requestsStore = useExistingRequestsStore();
    const userStore = useUserStore();
    const {activeRequest, approver} = storeToRefs(requestsStore);
    const {shops, approvers, isDealer} = storeToRefs(userStore);

    console.log("RequestsDraft setup");

    return {
      activeRequest,
      requestsStore,
      shops,
      approvers,
      approver,
      isDealer
    };
  },
  data: () => ({
    messageDialog: false,
    messageDialogTitle: "",
    messageDialogText: "",
    uploadQueue: [],
    productSearchAlert: false,
    deleteRequestDialog: false,
    newItemDialog: false,
    deleteItemDialog: false,
    saveDialog: false,
    submitDialog: false,
    dateMenu: false,
    valid: true,
    approverValid: false,
    newItemDialogValid: true,
    serialNumberDialog: false,
    rowCount: 0,
    currentFile: null,
    selectedShop: {code: '', name: '', clientKeySoldTo: '', clientKey: ''},
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    request: {},
    selectedApprover: {firstName: '', lastName: ''},
    editedIndex: -1,
    editedItem: {
      qtyOnHand: 0,
      serialNumbers: [],
      creditCode: "",
      description: "",
      itemCode: "",

      lastPromo: 0,
      newPromo: 0,

      invoicePrice: 0,
      newPrice: 0,

      requestersComments: "",

      discountPercentage: 100,
      differencePerUnit: 0,
      grandTotal: 0
    },
    defaultItem: {
      qtyOnHand: 0,
      serialNumbers: [],
      creditCode: "0000000000",
      description: "et netus et malesuada fames",
      itemCode: "0000000000",

      lastPromo: 0.00,
      newPromo: 0.00,

      invoicePrice: 0.00,
      newPrice: 0.00,

      discountPercentage: 100,
      differencePerUnit: 0.00,
      grandtotal: 0.00
    },
    blankItem: {
      qtyOnHand: 0,
      serialNumbers: [],
      creditCode: "",
      description: "",
      itemCode: "",

      lastPromo: 0.00,
      newPromo: 0.00,

      invoicePrice: 0.00,
      newPrice: 0.00,

      discountPercentage: 100,
      differencePerUnit: null,
      grandtotal: null
    },
    rules: {
      max21Chars: (value) =>
          (value === null || value.length <= 21) || "Character max reached!",
      required: (value) => !!value || "Required.",
      requiredSelect: v => Boolean(Object.values(v || {})[0]) || 'Please select a dealer.',
      requiredApproverSelect: v => Boolean(Object.values(v || {})[0]) || 'Please select an approver.',
      currency: (value) =>
          (!!value && !isNaN(value)) || "Please enter a valid currency value.",
      itemCode: (value) =>
          /^[a-zA-Z0-9_-]*$/.test(value) ||
          "Item code allows alphanumeric and hyphen characters.",
      creditCode: (value) =>
          /^[a-zA-Z0-9_]*$/.test(value) ||
          "Credit code allows alphanumeric and hyphen characters.",
      gteZero: (value) =>
          /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/.test(value) ||
          "Please adjust the invoice price and percentage.",
      gtZero: (value) =>
          /^[1-9][0-9]*$/.test(value) ||
          "Quantity must be greater than 0."
    },
  }),
  async mounted() {
    // TODO make a call to the backend asking for the shop name instead of searching
    if (this.activeRequest.dealerName !== null) {
      this.selectedShop = this.shops[_.findIndex(this.shops, {'name': this.activeRequest.dealerName})];
    } else {
      this.selectedShop = {code: '', name: ''};
    }
    this.$emit('update-step')
  },
  computed: {
    enableChip() {
      return (!(this.activeRequest.attachmentFilePath === null) && !(this.activeRequest.attachmentFilePath === ''));
    },
    ifDealerCreatedRequest() {
      return this.activeRequest.plan !== undefined && this.activeRequest.plan !== null && this.activeRequest.plan !== 0;
    },
    hasRequestItems() {
      return this.activeRequest.items.length > 0;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    formattedStartDate() {
      return (this.activeRequest.startDate.split(" "))[0];
    },
    calcUnitPrice() {
      if (this.ifDealerCreatedRequest) {
        return (this.editedItem.lastPromo - this.editedItem.newPromo).toFixed(2);
      }
      return (this.editedItem.invoicePrice - (this.editedItem.invoicePrice - ((this.editedItem.invoicePrice * this.editedItem.discountPercentage) / 100))).toFixed(2);
    },
    calcNewPrice() {
      if (this.ifDealerCreatedRequest) {
        return 0.0;
      }
      return ((this.editedItem.invoicePrice - ((this.editedItem.invoicePrice * this.editedItem.discountPercentage) / 100))).toFixed(2);
    },
    calcTotalPrice() {
      if (this.ifDealerCreatedRequest) {
        return (this.editedItem.qtyOnHand * this.editedItem.differencePerUnit).toFixed(2);
      }
      return (this.editedItem.qtyOnHand * (this.editedItem.invoicePrice - (this.editedItem.invoicePrice - ((this.editedItem.invoicePrice * this.editedItem.discountPercentage) / 100)))).toFixed(2);
    },
    disableSubmit() {
      return !this.valid || !this.approverValid || (Number(this.activeRequest.grandTotal) <= 0) || this.activeRequest.dealerName === "";
    },
    disableSave() {
      return !this.valid || this.activeRequest.dealerName === "";
    },
    getHeaders() {
      const t = this.$t.bind(this)

      if (this.activeRequest.items.length < 1) {
        return [];
      } else if ((this.activeRequest.items[0].newPrice === 0 && this.activeRequest.items[0].invoicePrice === 0) ||
          (this.activeRequest.items[0].newPrice === null && this.activeRequest.items[0].invoicePrice === null)) {
        return [
          {text: t('headers.qty'), value: "qtyOnHand"},
          {
            text: t('headers.itemCode'),
            align: "start",
            value: "itemCode",
          },
          {text: t('headers.description'), value: "description"},
          {text: t('headers.creditCode'), value: "creditCode"},
          {text: t('headers.lastPromoCad'), value: "lastPromo"},
          {text: t('headers.newPromoCad'), value: "newPromo"},
          {text: t('headers.diffUnitCad'), value: "differencePerUnit"},
          {text: t('headers.totalCad'), value: "total"},
          {text: t('headers.actions'), value: "actions", sortable: false},
        ]
      } else {
        return [
          {text: t('headers.qty'), value: "qtyOnHand"},
          {
            text: t('headers.itemCode'),
            align: "start",
            value: "itemCode",
          },
          {text: t('headers.description'), value: "description"},
          {text: t('headers.creditCode'), value: "creditCode"},
          {text: t('headers.invoicePriceCad'), value: "invoicePrice"},
          {text: t('headers.newPriceCad'), value: "newPrice"},
          {text: t('headers.discountPercentage'), value: "discountPercentage"},
          {text: t('headers.diffUnitCad'), value: "differencePerUnit"},
          {text: t('headers.totalCad'), value: "total"},
          {text: t('headers.actions'), value: "actions", sortable: false},
        ]
      }
    },
    getSnHeader() {
      const t = this.$t.bind(this)
      return [
        {text: t('headers.serialNumber'), value: "value"}
      ]
    }
  },

  watch: {
    newItemDialog(val) {
      val || this.close();
    },
    deleteItemDialog(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    getCustomerCode() {
      if (!!this.selectedShop.clientKeySoldTo === true) {
        const keys = this.selectedShop.clientKeySoldTo.split(',')
        return keys[0];
      } else if (!!this.selectedShop.clientKey === true) {
        return this.selectedShop.clientKey;
      }
      return "";
    },
    async deleteAttachment(filename) {
      const t = this.$t.bind(this)
      if (confirm(t('confirm.delete.attachment')) === true) {
        if (await this.requestsStore.deleteAttachment(filename)) {
          await this.saveRequest(false);
        }
      }
    },
    async getProduct() {
      this.editedItem.itemCode = this.editedItem.itemCode.toUpperCase();

      const itemCode = this.editedItem.itemCode;
      const productResponse = await this.requestsStore.getProduct(itemCode);

      try {
        this.editedItem.itemCode = String(productResponse.id.itemCode).trim()
        this.editedItem.description = String(productResponse.itemName).trim();
        this.editedItem.creditCode = String(productResponse.creditCode).trim();
      } catch (e) {
        this.productSearchAlert = true;
      }
    },
    viewSerialNumbers(item) {
      this.editedIndex = this.activeRequest.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.serialNumberDialog = true;
    },
    async quantityChanged() {
      if (this.hasRequestItems) {
        let oldQty = 0;

        try {
          oldQty = this.activeRequest.items[this.editedIndex].serialNumbers.length;
        } catch {
          // new RI, so serial numbers don't exist yet.
          return;
        }

        const newQty = this.editedItem.qtyOnHand;
        const diffQty = newQty - oldQty;

        if (diffQty < 0) {
          for (let i = 0; i < Math.abs(diffQty); ++i) {
            this.activeRequest.items[this.editedIndex].serialNumbers.pop();
          }
        } else {
          for (let i = 0; i < diffQty; ++i) {
            // HACK. Needed to add a fake/temp id to the serial number
            // because the NEW serial numbers kept having the same typed-in
            // value.
            let ri_id = null;
            try {
              ri_id = this.activeRequest.items[this.editedIndex].id;
            } catch (e) {
              ri_id = null;
            }

            const newSn = {
              value: "",
              requestItemId: ri_id,
              id: randomString.generate(),
              isDirty: true
            }
            this.activeRequest.items[this.editedIndex].serialNumbers.push(newSn);
          }
        }
      } else {
        console.log("Items list is empty.")
      }
    },
    currentItems: function (value) {
      this.rowCount = value.length;
    },
    editItem(item) {
      this.editedIndex = this.activeRequest.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.newItemDialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.activeRequest.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.deleteItemDialog = true;
    },
    async deleteItemConfirm() {
      const itemId = this.activeRequest.items[this.editedIndex].id;

      await this.requestsStore.deleteRequestItem(itemId);

      this.activeRequest.items.splice(this.editedIndex, 1);

      await this.saveRequest();

      this.closeDelete();
    },
    clear() {
      this.$refs.newItemForm.resetValidation();

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.blankItem);
        this.editedIndex = -1;
      });
    },
    close() {
      this.newItemDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.deleteItemDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async saveNewRequestItem() {
      if (!this.ifDealerCreatedRequest) {
        this.editedItem.newPrice = (this.editedItem.invoicePrice - ((this.editedItem.invoicePrice * this.editedItem.discountPercentage) / 100)).toFixed(2);
        this.editedItem.total = (this.editedItem.qtyOnHand * ((this.editedItem.invoicePrice) - ((this.editedItem.invoicePrice) - ((this.editedItem.invoicePrice * this.editedItem.discountPercentage) / 100)))).toFixed(2);
        this.editedItem.differencePerUnit = (this.editedItem.invoicePrice - (this.editedItem.invoicePrice - ((this.editedItem.invoicePrice * this.editedItem.discountPercentage) / 100))).toFixed(2);
      } else {
        this.editedItem.total = this.editedItem.qtyOnHand * this.editedItem.differencePerUnit;
      }

      if (this.editedIndex > -1) {
        Object.assign(this.activeRequest.items[this.editedIndex], this.editedItem);
      } else {
        this.activeRequest.items.push(this.editedItem);
      }

      let retVal = await this.requestsStore.addRequestItem(this.editedIndex);

      if (this.editedIndex < 0) {
        // update the newly created edited item
        this.activeRequest.items.pop();
        this.activeRequest.items.push(retVal);
      }

      await this.saveRequest();

      this.close();
    },
    calcGrandTotal() {
      this.activeRequest.grandTotal = Number(0);
      let items = this.activeRequest.items;

      for (let it of items) {
        it.total = (it.qtyOnHand * it.differencePerUnit).toFixed(2);
        this.activeRequest.grandTotal += Number(it.total);
      }
    },
    async saveRequest(isUploadSaved = true) {
      this.$emit('loading');

      this.saveDialog = false;
      console.log("Saving as request draft...");

      this.calcGrandTotal();

      if (isUploadSaved) {
        // switch to uploadQueue
        if (this.uploadQueue.length > 0) {
          console.log("Let's upload");
          for (let i = 0; i < this.uploadQueue.length; i++) {
            if (await this.requestsStore.uploadAttachment(this.uploadQueue[i])) {
              console.log("Uploading now " + this.uploadQueue[i]);
            } else {
              console.log("Couldn't upload " + this.uploadQueue[i]);
            }
          }
          this.currentFile = null;
          this.uploadQueue = [];
        } else {
          console.log("Couldn't upload files from the queue.");
        }
      }

      let response = await this.requestsStore.saveChangesToActiveRequest();

      if (response.success === false) {
        this.messageDialogText = response.message;
        this.messageDialog = true;
        this.messageDialogTitle = response.title;
      }

      this.$emit('loaded');
    },
    /**
     * Submit the request.
     */
    async auditRequest() {
      this.submitDialog = false;

      await this.saveRequest();

      this.$emit('loading');

      console.log("Submitting request...");

      if (await this.requestsStore.auditRequest()) {
        this.$emit('request-audited');
      } else {
        console.log("Cannot audit admin request");
      }
      this.$emit('loaded');
    },
    popAttachmentFromQueue(el) {
      let index = _.findIndex(this.uploadQueue, (e) => {
        return e === el;
      }, 0);

      this.uploadQueue = this.uploadQueue.toSpliced(index, 1);
    },
    pushAttachmentToQueue() {
      if (this.currentFile.size > process.env.VUE_APP_UPLOAD_MAX_BYTES) {
        alert('File size cannot exceed 10MB.');
      } else {
        if (!this.uploadQueue.includes(this.currentFile)) {
          this.uploadQueue.push(this.currentFile);
        }
      }
      this.currentFile = null;
      this.serialNumberDialog = false;
    },
    async deleteActiveRequest() {

      await this.requestsStore.deleteActiveRequest();

      await this.$router.push('existing-requests');
    },
    async downloadAttachments() {
      await this.requestsStore.downloadAttachment();
    },
    printWindow() {
      window.print();
    }
  },
});
</script>