<template>
  <div v-if="activeRequest.items !== undefined">
    <v-card class="my-4" elevation="0">
      <v-list-item>
        <v-list-item-content class="pb-0">
          <div class="text-overline mb-1">Price Protection Request</div>

          <v-list-item-subtitle class="mt-0">
            {{ $t("buttons.pprNo") }} {{ activeRequest.id }}
          </v-list-item-subtitle>

          <v-list-item-title class="mt-4">
            <v-text-field
                :label="$t('labels.protectionPlan')"
                v-model="activeRequest.planName"
                :rules="[rules.required]"
                readonly
            ></v-text-field>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="pt-0 mt-0">
        <v-text-field
            :label="$t('labels.dealerName')"
            class="pt-0 mt-0"
            v-model="activeRequest.dealerName"
            :rules="[rules.required]"
            readonly
        ></v-text-field>
      </v-list-item>
      <v-list-item class="pt-0 mt-0">
        <v-text-field
            :label="$t('labels.customerCode')"
            class="pt-0 mt-0"
            v-model="activeRequest.customerCode"
            :rules="[rules.required]"
            maxlength="6"
            readonly
        ></v-text-field>
      </v-list-item>
      <v-list-item class="pt-0 mt-0">
        <v-text-field
            :label="$t('labels.dealerReferenceNumber')"
            class="pt-0 mt-0"
            v-model="activeRequest.dealerReferenceNo"
            readonly
        ></v-text-field>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="py-0 my-0 mx-0 px-0">
          <v-row>
            <v-col cols="12" sm="6" class="pb-0 pb-sm-3">
              <v-menu
                  ref="startDateMenu"
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  readonly
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="formattedStartDate"
                      :label="$t('labels.startDate')"
                      prepend-icon="mdi-calendar-month-outline"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="activeRequest.startDate"
                    no-title
                    scrollable
                    @input="startDateMenu = false"
                    readonly
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" class="pt-0 pt-sm-3">
              <v-menu
                  ref="endDateMenu"
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  readonly
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="formattedEndDate"
                      :label="$t('labels.endDate')"
                      prepend-icon="mdi-calendar-month-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="activeRequest.endDate"
                    no-title
                    scrollable
                    @input="endDateMenu = false"
                    readonly
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <v-data-table
        :headers="getHeaders"
        :items="activeRequest.items"
        sort-by="creditCode"
        class="elevation-0 mb-5 pt-4"
    >
      <template v-slot:[`item.lastPromo`]="{ item }">
        <div>
          {{ item.lastPromo | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.newPromo`]="{ item }">
        <div>
          {{ item.newPromo | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.differencePerUnit`]="{ item }">
        <div>
          {{ item.differencePerUnit | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.total`]="{ item }">
        <div>
          {{ item.total | toCAD }}
        </div>
      </template>

      <template v-slot:footer>
        <v-divider></v-divider>
        <v-card
            class="d-flex pr-4"
            elevation="0"
        >
          <v-spacer></v-spacer>
          <div>
            <v-chip label color="lightgrey darken-1" text-color="black" class="font-weight-medium my-3">
              Grand Total
              (CAD): {{ activeRequest.grandTotal | toCAD }}
            </v-chip>
          </div>
        </v-card>
      </template>
    </v-data-table>

    <v-divider></v-divider>

    <div>
      <v-card-text>
        <div class="text-overline">
          Attachments
          <v-tooltip
              top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  left
                  size="large"
                  v-bind="attrs"
                  v-on="on"
                  @click="downloadAttachments"
                  :disabled="!!activeRequest.attachmentFilePath === false"
              >
                mdi-download
              </v-icon>
            </template>
            <span>Download attachments zip</span>
          </v-tooltip>
        </div>

        <v-chip-group column v-if="!!activeRequest.attachmentFilePath">
          <div
              v-for="file in activeRequest.attachmentFilePath.split('|')"
              :key="file"
          >
            <v-chip
                class="my-2 text-h7 mx-2"
                color="#c0c4c6"
                label>
              {{ file }}
            </v-chip>
          </div>
        </v-chip-group>
        <div v-else
             class="my-2 text-h7 mx-2">
          No attachments available
        </div>
      </v-card-text>

      <v-divider></v-divider>
    </div>


    <v-container fluid class="pt-5 px-0">
      <v-textarea
          v-model="activeRequest.commentsHistory"
          counter
          filled
          auto-grow
          readonly
          :label="$t('labels.commentsHistory')"
      ></v-textarea>
    </v-container>

    <v-divider></v-divider>

    <v-row class="pt-5 pb-3">
      <v-col cols="12" class="text-center">
        <v-btn @click="printWindow" :titles="$t('linkTitles.printWindow')" class="mr-4 mb-4">
          {{ $t("buttons.print") }}
        </v-btn>
        <v-btn class="mr-4 mb-4">
          <router-link to="/my-requests" :title="$t('linkTitles.backToMyRequests')" class="text-decoration-none">
            {{ $t("buttons.back") }}
          </router-link>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {storeToRefs} from "pinia";
import {useDealerStore} from "@/stores/dealer";
import {defineComponent} from "vue-demi";

export default defineComponent({
  name: "DealerSubmittedRequest",
  setup() {
    const dealerStore = useDealerStore();
    let {activeRequest} = storeToRefs(dealerStore);

    console.log("DealerSubmitted");

    return {
      activeRequest,
      dealerStore
    };
  },
  data: () => ({
    startDateMenu: false,
    endDateMenu: false,
    defaultItem: {
      pprNo: "0019003",
      customerCode: "DEF",
      dealerName: "Default Name",
      dealerReferenceNo: "",
      submissionDate: "2022-01-01",
      planName: "No Plan Name",
      totalCad: "0",
      items: [
        {
          qtyOnHand: "0",
          serialNumbers: [],
          creditCode: "",
          description: "",
          itemCode: "",
          lastPromo: "",
          differencePerUnit: "",
          newPrice: "77",
          total: "88"
        }
      ],
    },
    rules: {
      required: (value) => !!value || "Required.",
    },
  }),

  computed: {
    formattedStartDate() {
      return (this.activeRequest.startDate.split(" "))[0];
    },
    formattedEndDate() {
      return (this.activeRequest.endDate.split(" "))[0];
    },
    getHeaders() {
      const t = this.$t.bind(this)
      return [
        {text: t('headers.qty'), value: "qtyOnHand", width: "7%"},
        {text: t('headers.creditCode'), value: "creditCode"},
        {text: t('headers.description'), value: "description"},
        {text: t('headers.itemCode'), value: "itemCode"},
        {text: t('headers.lastPromoCad'), value: "lastPromo"},
        {text: t('headers.newPromoCad'), value: "newPromo"},
        {text: t('headers.diffUnitCad'), value: "differencePerUnit"},
        {text: t('headers.totalCad'), value: "total"},
      ]
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    async downloadAttachments() {
      await this.dealerStore.downloadAttachment();
    },
    printWindow() {
      window.print();
    }
  }
});
</script>