<template>
  <div v-if="activeRequest !== null">
    <v-form
        ref="form"
        v-model="valid">
      <v-card class="my-4" elevation="0">
        <v-list-item>
          <v-list-item-content class="pb-0">
            <div class="text-overline mb-1">Price Protection Request</div>

            <v-list-item-subtitle class="mt-0 mb-3">
              {{ $t('labels.pprNo') }} {{ activeRequest.id }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="mt-1" v-if="activeRequest.dateSubmitted">
              {{ $t('labels.submittedBy') }} {{ activeRequest.createdByUser }} on {{ activeRequest.dateSubmitted }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="mt-1" v-if="activeRequest.lastModifiedByUser !== null">
              {{ $t('labels.lastModifiedBy') }} {{ activeRequest.lastModifiedByUser }} on
              {{ activeRequest.dateLastModified }}
            </v-list-item-subtitle>

            <v-list-item-title class="mt-4">
              <v-text-field
                  :label="$t('labels.protectionPlan')"
                  v-model="activeRequest.planName"
                  readonly
              ></v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="pt-0 mt-0">
          <v-text-field
              :label="$t('labels.dealerName')"
              class="pt-0 mt-0"
              v-model="activeRequest.dealerName"
              readonly
          ></v-text-field>
        </v-list-item>
        <v-list-item class="pt-0 mt-0">
          <v-text-field
              :label="$t('labels.customerCode')"
              class="pt-0 mt-0"
              v-model="activeRequest.customerCode"
              maxlength="6"
              readonly
          ></v-text-field>
        </v-list-item>
        <v-list-item class="pt-0 mt-0">
          <v-text-field
              :label="$t('labels.dealerReferenceNumber')"
              class="pt-0 mt-0"
              v-model="activeRequest.dealerReferenceNo"
              :counter="21"
              :rules="[rules.max21Chars]"
          ></v-text-field>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="py-0 my-0 mx-0 px-0">
            <v-row>
              <v-col cols="12" sm="6">
                <v-menu
                    ref="dateMenu"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    readonly
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="formattedStartDate"
                        :label="$t('labels.startDate')"
                        prepend-icon="mdi-calendar-month-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="activeRequest.startDate"
                      no-title
                      scrollable
                      @input="dateMenu = false"
                      readonly
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="pt-3 pb-9 my-0 mx-0 px-0">
            <v-row>
              <v-col cols="12" sm="6">
                <v-btn-toggle
                    v-model="activeRequest.taxRateType"
                    color="red darken-2"
                    :disabled="true"
                >
                  <v-btn :value="0">
                    {{ $t("buttons.taxExempt") }}
                  </v-btn>

                  <v-btn :value="1" :disabled="true">
                    {{ $t("buttons.oldRate") }}
                  </v-btn>

                  <v-btn :value="2" :disabled="true">
                    {{ $t("buttons.s21Default") }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-form>
    <v-data-table
        :headers="getHeaders"
        :items="activeRequest.items"
        sort-by="itemCode"
        class="elevation-0 mb-5"
        @current-items="currentItems"
    >
      <template v-slot:top v-if="activeRequest.items.length > 0">
        <v-spacer></v-spacer>

        <v-dialog v-model="serialNumberDialog" persistent max-width="500px">
          <v-card>
            <v-card-title>Serial Numbers for {{ editedItem.description }} ({{
                editedItem.itemCode
              }})
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-data-table
                    :headers="getSnHeader"
                    :items="activeRequest.items[editedIndex === -1 ? 0 : editedIndex].serialNumbers">
                  <template v-slot:[`item.value`]="{ item }">
                    <span>{{ item.value }}</span>
                  </template>
                </v-data-table>

                <v-card
                    class="d-flex pt-5"
                    flat
                >
                  <v-spacer></v-spacer>
                  <div>
                    <v-btn @click="serialNumberDialog = false">{{ $t("labels.close") }}</v-btn>
                  </div>
                </v-card>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:[`item.invoicePrice`]="{ item }">
        <div class="d-flex justify-space-evenly bg-surface-variant">
          {{ item.invoicePrice | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.newPrice`]="{ item }">
        <div class="d-flex justify-space-evenly bg-surface-variant">
          {{ item.newPrice | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.lastPromo`]="{ item }">
        <div>
          {{ item.lastPromo | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.newPromo`]="{ item }">
        <div>
          {{ item.newPromo | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.discountPercentage`]="{ item }">
        <div class="d-flex justify-space-evenly bg-surface-variant">
          {{ item.discountPercentage + "%" }}
        </div>
      </template>

      <template v-slot:[`item.differencePerUnit`]="{ item }">
        <div class="d-flex justify-space-evenly bg-surface-variant">
          {{ item.differencePerUnit | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.total`]="{ item }">
        <div class="d-flex justify-space-evenly bg-surface-variant">
          {{ item.total | toCAD }}
        </div>
      </template>


      <template v-slot:footer>
        <v-divider></v-divider>
        <v-card
            class="d-flex pr-4"
            elevation="0"
        >
          <v-spacer></v-spacer>
          <div>
            <v-chip label color="lightgrey darken-1" text-color="black" class="font-weight-medium my-3">
              Grand Total
              (CAD): {{ activeRequest.grandTotal | toCAD }}
            </v-chip>
          </div>
        </v-card>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
            small
            :title="$t('iconTitles.viewSerialNos')"
            class="mr-2"
            @click="viewSerialNumbers(item)"
            :disabled="item.serialNumbers.length === 0"
        >
          mdi-clipboard-list-outline
        </v-icon>
        <v-icon small :title="$t('iconTitles.seeFax')" class="mr-2"
                v-if="activeRequest.snEntryMethod === 2">
          mdi-fax
        </v-icon>
      </template>
    </v-data-table>

    <v-divider></v-divider>

    <div>
      <v-card-text>
        <div class="text-overline">
          Attachments
          <v-tooltip
              top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  left
                  size="large"
                  v-bind="attrs"
                  v-on="on"
                  @click="downloadAttachments"
                  :disabled="!!activeRequest.attachmentFilePath === false"
              >
                mdi-download
              </v-icon>
            </template>
            <span>Download attachments zip</span>
          </v-tooltip>
        </div>

        <v-chip-group column v-if="!!activeRequest.attachmentFilePath">
          <div
              v-for="file in activeRequest.attachmentFilePath.split('|')"
              :key="file"
          >
            <v-chip
                class="my-2 text-h7 mx-2"
                color="#c0c4c6"
                label>
              {{ file }}
            </v-chip>
          </div>
        </v-chip-group>
        <div v-else
             class="my-2 text-h7 mx-2">
          No attachments available
        </div>
      </v-card-text>

      <v-divider></v-divider>
    </div>

    <v-container fluid class="pt-5 px-0">
      <v-textarea
          v-model="activeRequest.auditorsComments"
          counter
          filled
          auto-grow
          readonly
          :label="$t('labels.auditorsComments')"
      ></v-textarea>
    </v-container>
    <v-container fluid class="pt-0 px-0">
      <v-textarea
          v-model="activeRequest.approversComments"
          counter
          filled
          auto-grow
          :label="$t('labels.approversComments')"
          :readonly='!isApprover'
      >
        <template v-slot:append v-if="isApprover">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="moveApproversCommentsToHistory"
                  :disabled="noApproversComments"
              >
                <v-icon>
                  mdi-receipt-text-send
                </v-icon>
              </v-btn>
            </template>
            <span>Add to comments history</span>
          </v-tooltip>
        </template>
      </v-textarea>
    </v-container>
    <v-container fluid class="pt-0 px-0">
      <v-textarea
          v-model="activeRequest.invoiceComments"
          counter
          filled
          auto-grow
          :label="$t('labels.invoiceComments')"
          :readonly='!isApprover'
      ></v-textarea>
    </v-container>
    <v-container fluid class="pt-0 px-0">
      <v-textarea
          v-model="activeRequest.internalComments"
          counter
          filled
          auto-grow
          :label="$t('labels.internalComments')"
      ></v-textarea>
    </v-container>
    <v-container fluid class="pt-0 px-0">
      <v-textarea
          v-model="activeRequest.commentsHistory"
          counter
          filled
          auto-grow
          readonly
          :label="$t('labels.commentsHistory')"
      ></v-textarea>
    </v-container>

    <v-divider></v-divider>

    <v-row class="pt-5 pb-3">
      <v-col cols="12" class="text-center">
        <v-btn @click="printWindow" :titles="$t('linkTitles.printWindow')" class="mr-4 mb-4">
          {{ $t("buttons.print") }}
        </v-btn>
        <v-dialog v-model="saveDialog" persistent max-width="350">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" dark v-bind="attrs" v-on="on" v-if="isApprover" class="mr-4 mb-4">
              {{ $t("buttons.save") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("vcard.saveRequest.title") }}
            </v-card-title>
            <v-card-text>
              {{ $t("vcard.saveRequest.text") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="saveDialog = false">
                Cancel
              </v-btn>
              <v-btn color="red darken-1" text @click="saveRequest();">
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="approveDialog" persistent max-width="350">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" v-bind="attrs" v-on="on" class="mr-4 mb-4" :disabled="disableSubmit"
                   v-if="isApprover">
              APPROVE
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              Approve this Request?
            </v-card-title>
            <v-card-text>
              Upon submission, this request will be transferred to S21.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="approveDialog = false">
                {{ $t("buttons.disagree") }}
              </v-btn>
              <v-btn color="red darken-1" text @click="approveRequest()">
                {{ $t("buttons.agree") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="demoteDialog" persistent max-width="350">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="warning" v-bind="attrs" v-on="on" v-if="isApprover" class="mr-4 mb-4">
              {{ $t("buttons.demote") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              Demote Request?
            </v-card-title>
            <v-card-text>
              Upon demotion, this request will be sent back to an Auditor for modification.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="demoteDialog = false">
                {{ $t("buttons.disagree") }}
              </v-btn>
              <v-btn color="red darken-1" text @click="demoteRequest()">
                {{ $t("buttons.agree") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="rejectDialog" persistent max-width="350">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="error" v-bind="attrs" v-on="on" v-if="isApprover" class="mr-4 mb-4">
              {{ $t("buttons.reject") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              Reject Request?
            </v-card-title>
            <v-card-text>
              Upon rejection, this request will no longer be available for edit and will not go live.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="rejectDialog = false">
                {{ $t("buttons.disagree") }}
              </v-btn>
              <v-btn color="red darken-1" text @click="rejectRequest()">
                {{ $t("buttons.agree") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn class="mr-4 mb-4">
          <router-link to="/existing-requests" :title="$t('linkTitles.backToExistingRequests')"
                       class="text-decoration-none">
            {{ $t("buttons.back") }}
          </router-link>
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog
          v-model="messageDialog"
          persistent
          width="512"
      >
        <v-card class="m-3">
          <v-card-title>
            {{ messageDialogTitle }}
          </v-card-title>
          <v-card-text>
            {{ messageDialogText }}
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="blue-darken-1"
                variant="text"
                @click="messageDialog = false"
                class="mb-3"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
  <div v-else></div>
</template>

<script>
import {storeToRefs} from "pinia";
import {useExistingRequestsStore} from "@/stores/existingrequests";
import {defineComponent} from "vue-demi";
import {useUserStore} from "@/stores/user";

export default defineComponent({
  name: "RequestAudited",
  setup() {
    const requestsStore = useExistingRequestsStore();
    const {activeRequest} = storeToRefs(requestsStore);
    const userStore = useUserStore();
    const {isApprover} = storeToRefs(userStore);

    console.log("RequestAudited setup");

    return {
      activeRequest,
      requestsStore,
      isApprover
    };
  },
  data: () => ({
    serialNumberDialog: false,
    dateMenu: false,
    approveDialog: false,
    rejectDialog: false,
    demoteDialog: false,
    saveDialog: false,
    messageDialog: false,
    messageDialogTitle: "",
    messageDialogText: "",
    valid: true,
    rowCount: 0,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    editedIndex: -1,
    editedItem: {},
    rules: {
      max21Chars: (value) =>
          (value === null || value.length <= 21) || "Character max reached!",
    },
  }),
  computed: {
    noApproversComments() {
      return this.activeRequest.approversComments === null || this.activeRequest.approversComments.length === 0;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    formattedStartDate() {
      return (this.activeRequest.startDate.split(" "))[0];
    },
    disableSubmit() {
      return !this.valid || this.rowCount <= 0;
    },
    getHeaders() {
      const t = this.$t.bind(this)

      if (this.activeRequest.items.length < 1) {
        return [];
      } else if ((this.activeRequest.items[0].newPrice === 0 && this.activeRequest.items[0].invoicePrice === 0) ||
          (this.activeRequest.items[0].newPrice === null && this.activeRequest.items[0].invoicePrice === null)) {
        return [
          {text: t('headers.qty'), value: "qtyOnHand"},
          {
            text: t('headers.itemCode'),
            align: "start",
            value: "itemCode",
          },
          {text: t('headers.description'), value: "description"},
          {text: t('headers.creditCode'), value: "creditCode"},
          {text: t('headers.lastPromoCad'), value: "lastPromo"},
          {text: t('headers.newPromoCad'), value: "newPromo"},
          {text: t('headers.diffUnitCad'), value: "differencePerUnit"},
          {text: t('headers.totalCad'), value: "total"},
          {text: t('headers.actions'), value: "actions", sortable: false},
        ]
      } else {
        return [
          {text: t('headers.qty'), value: "qtyOnHand"},
          {
            text: t('headers.itemCode'),
            align: "start",
            value: "itemCode",
          },
          {text: t('headers.description'), value: "description"},
          {text: t('headers.creditCode'), value: "creditCode"},
          {text: t('headers.invoicePriceCad'), value: "invoicePrice"},
          {text: t('headers.newPriceCad'), value: "newPrice"},
          {text: t('headers.discountPercentage'), value: "discountPercentage"},
          {text: t('headers.diffUnitCad'), value: "differencePerUnit"},
          {text: t('headers.totalCad'), value: "total"},
          {text: t('headers.actions'), value: "actions", sortable: false},
        ]
      }
    },
    getSnHeader() {
      const t = this.$t.bind(this)
      return [
        {text: t('headers.serialNumber'), value: "value"}
      ]
    }
  },
  methods: {
    currentItems: function (value) {
      this.rowCount = value.length;
    },
    ifDealerCreatedRequest() {
      return this.activeRequest.plan !== undefined && this.activeRequest.plan !== null && this.activeRequest.plan !== 0;
    },
    async saveRequest(section = null) {
      console.log("Saving as audited request...");

      this.$emit('loading');
      // persist to the backend
      this.saveDialog = false;

      await this.requestsStore.saveChangesToActiveRequest(section);

      this.$emit('loaded');
    },
    viewSerialNumbers(item) {
      this.editedIndex = this.activeRequest.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.serialNumberDialog = true;
    },
    /**
     * Give final approval for the request.
     */
    async approveRequest() {
      this.$emit('loading');

      this.approveDialog = false;
      console.log("Approving request...");

      let response = await this.requestsStore.approveRequest();

      if (response.success === true) {
        this.$emit('advance-step')
      } else {
        this.messageDialogText = response.message;
        this.messageDialog = true;
        this.messageDialogTitle = response.title;
      }

      this.$emit('loaded');
    },
    async demoteRequest() {
      this.$emit('loading');

      this.demoteDialog = false;
      console.log("Demoting request...");

      if (this.ifDealerCreatedRequest() === true) {
        if (await this.requestsStore.demoteRequest(2)) {
          this.$emit('dealer-demote-step')
        }
      } else {
        if (await this.requestsStore.demoteRequest(1)) {
          this.$emit('admin-demote-step')
        }
      }

      this.$emit('loaded');
    },
    async rejectRequest() {
      this.$emit('loading');

      this.rejectDialog = false;
      console.log("Rejected request...");

      if (await this.requestsStore.rejectRequest()) {
        this.$emit('reject-step')
      } else {
        console.log("Cannot reject request");
      }

      this.$emit('loaded');
    },
    async downloadAttachments() {
      await this.requestsStore.downloadAttachment();
    },
    printWindow() {
      window.print();
    },
    async moveApproversCommentsToHistory() {
      await this.saveRequest("APPROVER");
    }
  },
});
</script>