<template>
  <div v-if="activeRequest.items !== undefined">
    <v-form
        ref="form"
        v-model="valid">
      <v-card class="my-4" elevation="0">
        <v-list-item>
          <v-list-item-content class="pb-0">
            <div class="text-overline mb-1">Price Protection Request</div>

            <v-list-item-subtitle class="mt-0">
              {{ $t('labels.pprNo') }} {{ activeRequest.id }}
            </v-list-item-subtitle>

            <v-list-item-title class="mt-4">
              <v-text-field
                  :label="$t('labels.protectionPlan')"
                  v-model="activeRequest.planName"
                  :rules="[rules.required]"
                  readonly
              ></v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="pt-0 mt-0">
          <v-text-field
              :label="$t('labels.dealerName')"
              class="pt-0 mt-0"
              v-model="activeRequest.dealerName"
              :rules="[rules.required]"
              readonly
          ></v-text-field>
        </v-list-item>
        <v-list-item class="pt-0 mt-0">
          <v-text-field
              :label="$t('labels.customerCode')"
              class="pt-0 mt-0"
              v-model="activeRequest.customerCode"
              maxlength="6"
              :rules="[rules.required]"
              readonly
          ></v-text-field>
        </v-list-item>
        <v-list-item class="pt-0 mt-0">
          <v-text-field
              :label="$t('labels.dealerReferenceNumber')"
              class="pt-0 mt-0"
              v-model="activeRequest.dealerReferenceNo"
              :counter="21"
              :rules="[rules.max21Chars]"
          ></v-text-field>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="py-0 my-0 mx-0 px-0">
            <v-row>
              <v-col cols="12" sm="6" class="pb-0 pb-sm-3">
                <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="formattedStartDate"
                        :label="$t('labels.startDate')"
                        prepend-icon="mdi-calendar-month-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="activeRequest.startDate"
                      no-title
                      scrollable
                      @input="startDateMenu = false"
                      readonly
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pt-sm-3">
                <v-menu
                    ref="endDateMenu"
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="formattedEndDate"
                        :label="$t('labels.endDate')"
                        prepend-icon="mdi-calendar-month-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="activeRequest.endDate"
                      no-title
                      scrollable
                      @input="endDateMenu = false"
                      readonly
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-card>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <div class="text-overline mb-4">
            Select Supporting Method
          </div>
          <v-btn-toggle
              v-model="activeRequest.snEntryMethod"
              color="red darken-2"
              mandatory
              class="pb-3"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :value="0" rounded
                       v-bind="attrs"
                       v-on="on"
                       @click="isAttachmentPanelEnabled = false; serialNumberMethod = 0; snEntryMethodChanged()">
                  <v-icon>mdi-keyboard</v-icon>
                </v-btn>
              </template>
              <span>Manually enter serial numbers for each on-hand item above.</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :value="1"
                       v-bind="attrs"
                       v-on="on"
                       @click="isAttachmentPanelEnabled = true; serialNumberMethod = 1; snEntryMethodChanged()">
                  <v-icon>mdi-paperclip</v-icon>
                </v-btn>
              </template>
              <span>Attach file</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :value="2"
                    v-bind="attrs"
                    v-on="on"
                    @click="isAttachmentPanelEnabled = false; serialNumberMethod = 2; snEntryMethodChanged()">
                  <v-icon>mdi-fax</v-icon>
                </v-btn>
              </template>
              <span>Fax your on-hand inventory report to 905-795-2041.</span>
            </v-tooltip>

          </v-btn-toggle>
        </v-list-item-content>
      </v-list-item>

      <v-card elevation="0" class="mb-3 ml-2"
              v-if="enableAttachmentPanel">
        <v-list-item class="px-0">
          <v-list-item-content class="d-flex pa-0">
            <v-card
                class="d-flex flex-row mb-1"
                flat
                tile
            >
              <v-file-input
                  v-model="currentFile"
                  small-chips
                  show-size
                  counter
                  accept="*"
                  :label="$t('labels.selectSnFile')"
              ></v-file-input>
              <v-btn
                  class="my-auto mx-4"
                  @click="pushAttachmentToQueue"
                  :disabled="currentFile === null || currentFile === ''"
              >
                {{ $t('buttons.upload') }}
              </v-btn>
            </v-card>

          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-chip-group column v-if="activeRequest.attachmentFilePath">
            <div
                v-for="file in activeRequest.attachmentFilePath.split('|')"
                :key="file"
            >
              <v-chip
                  class="my-2 text-h7 mx-2"
                  color="#C0C4C6"
                  label
                  close
                  @click:close="deleteAttachment(file)"
              >
                <v-icon left>
                  mdi-file
                </v-icon>
                {{ file }}
              </v-chip>
            </div>
          </v-chip-group>
          <v-chip-group column v-if="uploadQueue.length > 0">
            <div
                v-for="el in uploadQueue"
                :key="el.name"
            >
              <v-chip
                  class="my-2 text-h7 mx-2"
                  color="#C6C2C0"
                  label
                  close
                  @click:close="popAttachmentFromQueue(el)"
              >
                <v-icon left>
                  mdi-file-plus-outline
                </v-icon>
                {{ el.name }}
              </v-chip>
            </div>
          </v-chip-group>
        </v-list-item>
      </v-card>

      <v-divider></v-divider>

      <v-data-table
          :headers="getHeaders"
          :items="activeRequest.items"
          sort-by="creditCode"
          class="elevation-0 mt-6 mb-0"
      >
        <template v-slot:[`item.qtyOnHand`]="{ item }">
          <v-text-field
              v-model="item.qtyOnHand"
              :hide-details="true"
              dense
              min="0"
              single-line
              :autofocus="true"
              type="number"
              @input="quantityChanged(item)"
          >
          </v-text-field>

        </template>
        <template v-slot:top>
          <v-spacer></v-spacer>

          <v-form
              ref="serialNumberForm">
            <v-dialog v-model="serialNumberDialog" persistent max-width="500px">
              <v-card>
                <v-card-title>Serial Numbers for {{ editedItem.description }}</v-card-title>
                <v-card-subtitle class="pt-2">{{ editedItem.itemCode }}</v-card-subtitle>
                <v-card-text>
                  <v-container>
                    <v-data-table
                        v-if="activeRequest.items.length !== 0"
                        :headers="getSnHeader"
                        :items="activeRequest.items[editedIndex === -1 ? 0 : editedIndex].serialNumbers"
                    >
                      <template v-slot:[`item`]="{ item }">
                        <v-text-field
                            class="pt-0 mt-0"
                            v-model="item.value"
                            type="string"
                            :rules="[rules.nonempty]"
                        ></v-text-field>
                      </template>
                    </v-data-table>
                    <v-data-table
                        :headers="getSnHeader"
                        v-else
                    ></v-data-table>

                    <v-card
                        class="d-flex pt-5"
                        flat
                    >
                      <v-spacer></v-spacer>
                      <div>
                        <v-btn @click="serialNumberDialog = false">CLOSE</v-btn>
                      </div>
                    </v-card>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-form>
        </template>

        <template v-slot:[`item.lastPromo`]="{ item }">
          <div>
            {{ item.lastPromo | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.newPromo`]="{ item }">
          <div>
            {{ item.newPromo | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.differencePerUnit`]="{ item }">
          <div>
            {{ item.differencePerUnit | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.total`]="{ item }">
          <div>
            ${{ item.total }}
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  :title="$t('iconTitles.editSerialNos')"
                  class="mr-2"
                  @click="editSerialNumbers(item)"
                  :disabled="serialNumberMethod !== 0">
                mdi-clipboard-list-outline
              </v-icon>
            </template>
            <span>Select manual serial number entry from the options below</span>
          </v-tooltip>
        </template>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :value="0" rounded
                   v-bind="attrs"
                   v-on="on"
                   @click="isAttachmentPanelEnabled = false; serialNumberMethod = 0; snEntryMethodChanged()">
              <v-icon>mdi-keyboard</v-icon>
            </v-btn>
          </template>
          <span>Manually enter serial numbers for each on-hand item above.</span>
        </v-tooltip>

        <template v-slot:footer>
          <v-divider></v-divider>
          <v-card
              class="d-flex pr-4"
              elevation="0"
          >
            <v-spacer></v-spacer>
            <div>
              <v-chip label color="lightgrey darken-1" text-color="black" class="font-weight-medium my-3">
                Grand Total
                (CAD): {{ activeRequest.grandTotal | toCAD }}
              </v-chip>
            </div>
          </v-card>
        </template>
      </v-data-table>

      <v-divider></v-divider>

      <v-container fluid class="pt-5 px-0">
        <v-textarea
            v-model="activeRequest.requestersComments"
            counter
            filled
            auto-grow
            :label="$t('labels.requestersComments')"
        ></v-textarea>
      </v-container>

      <v-divider></v-divider>

      <v-row class="pt-5 pb-3">
        <v-col cols="12" class="text-center">

          <v-btn @click="printWindow" :titles="$t('linkTitles.printWindow')" class="mr-4 mb-4">
            {{ $t("buttons.print") }}
          </v-btn>

          <v-dialog v-model="saveDialog" persistent max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="secondary" v-bind="attrs" v-on="on" :disabled="disableSave" class="mr-4 mb-4"
              >
                {{ $t("buttons.save") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">
                {{ $t("vcard.saveRequest.title") }}
              </v-card-title>
              <v-card-text>
                {{ $t("vcard.saveRequest.text") }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="saveDialog = false">
                  {{ $t("buttons.cancel") }}
                </v-btn>
                <v-btn color="red darken-1" text @click="saveRequest">
                  {{ $t("buttons.yes") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="submitDialog" persistent max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on" class="mr-4 mb-4"
                     :disabled="disableSubmit">
                {{ $t("buttons.submit") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">
                {{ $t("vcard.submitRequestForApproval.title") }}
              </v-card-title>
              <v-card-text>
                {{ $t("vcard.submitRequestForApproval.text") }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="submitDialog = false">
                  {{ $t("buttons.disagree") }}
                </v-btn>
                <v-btn color="red darken-1" text @click="submitRequest();">
                  {{ $t("buttons.agree") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-btn class="mr-4 mb-4">
            <router-link to="/my-requests" :title="$t('linkTitles.backToMyRequests')"
                         class="text-decoration-none">
              {{ $t("buttons.back") }}
            </router-link>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-dialog v-model="deleteRequestDialog" persistent max-width="400">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="red darken-2" large :title="$t('iconTitles.deleteRequest')" v-bind="attrs"
                      v-on="on">
                mdi-delete-circle
              </v-icon>
            </template>
            <v-card>
              <v-card-title class="text-h5">
                {{ $t("vcard.deleteRequest.title") }}
              </v-card-title>
              <v-card-text>
                {{ $t("vcard.deleteRequest.text") }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="deleteRequestDialog = false">
                  Cancel
                </v-btn>
                <v-btn color="red darken-1" text @click="deleteActiveRequest">
                  Delete
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import {storeToRefs} from "pinia";
import {useDealerStore} from "@/stores/dealer";
import {defineComponent} from "vue-demi";
import {useUserStore} from "@/stores/user";
import _ from "underscore";
import randomString from "randomstring";


export default defineComponent({
  name: "DealerDraftRequest",
  setup() {
    const dealerStore = useDealerStore();
    let {activeRequest} = storeToRefs(dealerStore);

    console.log("DealerDraft");

    return {
      activeRequest,
      dealerStore,
      randomString
    };
  },
  beforeRouteEnter(to, from, next) {
    const userStore = useUserStore();
    const {user} = storeToRefs(userStore);

    const {authorize} = to.meta;
    const roles = user.value.roles;

    if (_.intersection(authorize, roles).length > 0) {
      next();
    } else {
      return false;
    }
  },
  data: () => ({
    uploadQueue: [],
    deleteRequestDialog: false,
    faxSnsDialog: false,
    startDateMenu: false,
    endDateMenu: false,
    saveDialog: false,
    submitDialog: false,
    serialNumberMethod: 0,
    serialNumberDialog: false,
    isAttachmentPanelEnabled: false,
    valid: true,
    hasInvalidSerialNumbers: false,
    currentFile: null,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    editedSN: {},
    defaultSN: {
      value: "",
    },
    editedIndex: -1,
    editedItem: {},
    defaultItem: {
      pprNo: "0019003",
      customerCode: "DEF",
      dealerName: "Default Name",
      dealerReferenceNo: "",
      submissionDate: "2022-01-01",
      planName: "No Plan Name",
      totalCad: "0",
      items: [
        {
          qtyOnHand: "0",
          serialNumbers: [],
          creditCode: "",
          description: "",
          itemCode: "",
          lastPromo: "",
          differencePerUnit: "",
          newPrice: "77",
          total: "88"
        }
      ],
    },
    rules: {
      max21Chars: (value) =>
          (value === null || value.length <= 21) || "Character max reached!",
      required: (value) => !!value || "Required.",
      nonempty: (value) =>
          /^(?!\s*$).+$/.test(value) || "Please enter a value.",
    },
  }),
  computed: {
    formattedStartDate() {
      return (this.activeRequest.startDate.split(" "))[0];
    },
    formattedEndDate() {
      return (this.activeRequest.endDate.split(" "))[0];
    },
    disableSubmit() {
      let retVal = false;
      switch (this.activeRequest.snEntryMethod) {
        case 0:
          retVal = !this.valid || this.hasInvalidSerialNumbers || (Number(this.activeRequest.grandTotal) <= 0);
          break;
        case 1:
          retVal = !this.valid || this.activeRequest.attachmentFilePath === null || (Number(this.activeRequest.grandTotal) <= 0);
          break;
        default:
          retVal = !this.valid || (Number(this.activeRequest.grandTotal) <= 0);
          break;
      }
      if (retVal) {
        this.$emit('show-incomplete-form-alert')
      } else {
        this.$emit('hide-incomplete-form-alert')

      }
      return retVal;
    },
    disableSave() {
      return !this.valid || this.activeRequest.dealerName === "";
    },
    enableChip() {
      return (!(this.activeRequest.attachmentFilePath === null) && !(this.activeRequest.attachmentFilePath === ''));
    },
    enableAttachmentPanel() {
      return this.activeRequest.snEntryMethod === 1;
    },
    getHeaders() {
      const t = this.$t.bind(this);
      return [
        {text: t('headers.qty'), value: "qtyOnHand", width: "7%"},
        {text: t('headers.itemCode'), value: "itemCode"},
        {text: t('headers.description'), value: "description"},
        {text: t('headers.creditCode'), value: "creditCode"},
        {text: t('headers.lastPromoCad'), value: "lastPromo"},
        {text: t('headers.newPromoCad'), value: "newPromo"},
        {text: t('headers.diffUnitCad'), value: "differencePerUnit"},
        {text: t('headers.totalCad'), value: "total"},
        {text: t('headers.serialNumbers'), value: "actions", sortable: false},
      ]
    },
    getSnHeader() {
      const t = this.$t.bind(this)
      return [
        {text: t('headers.serialNumbers'), value: "actions", sortable: false},
      ]
    },
  },
  watch: {
    serialNumberDialog(val) {
      val || this.closeSnDialog();
    },
  },
  created() {
    if (this.activeRequest !== null) {
      this.initialize();
      this.serialNumberMethod = this.activeRequest.snEntryMethod;
    }
  },
  methods: {
    initialize() {
      this.findInvalidSerialNumbers();
    },
    calcTotalPrice() {
      this.activeRequest.grandTotal = Number(0);
      let items = this.activeRequest.items;

      for (let it of items) {
        it.total = (it.qtyOnHand * it.differencePerUnit).toFixed(2);
        this.activeRequest.grandTotal += Number(it.total);
      }
    },
    async editSerialNumbers(item) {
      this.editedIndex = this.activeRequest.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.serialNumberDialog = true;
    },
    async snEntryMethodChanged() {
      this.activeRequest.snEntryMethod = this.serialNumberMethod;
    },
    findInvalidSerialNumbers() {
      if (this.activeRequest.items !== null) {
        for (let i = 0; i < this.activeRequest.items.length; i++) {
          let item = this.activeRequest.items[i];
          for (let j = 0; j < item.serialNumbers.length; j++) {
            let sn = item.serialNumbers[j];
            if (sn.value.trim() === "") {
              this.hasInvalidSerialNumbers = true;
              return;
            }
          }
        }
      }
      this.hasInvalidSerialNumbers = false;

    },
    async quantityChanged(item) {
      this.editedIndex = this.activeRequest.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.calcTotalPrice();

      const oldQty = this.activeRequest.items[this.editedIndex].serialNumbers.length;
      const newQty = item.qtyOnHand;
      const diffQty = newQty - oldQty;

      if (diffQty < 0) {
        for (let i = 0; i < Math.abs(diffQty); ++i) {
          this.activeRequest.items[this.editedIndex].serialNumbers.pop();
        }
      } else {
        for (let i = 0; i < diffQty; ++i) {
          // HACK. Needed to add a fake/temp id to the serial number
          // because the NEW serial numbers kept having the same typed-in
          // value.
          const newSn = {
            value: "",
            requestItemId: this.activeRequest.items[this.editedIndex].id,
            id: randomString.generate(),
            isDirty: true
          }
          this.activeRequest.items[this.editedIndex].serialNumbers.push(newSn)
        }
      }
      this.findInvalidSerialNumbers();
    },
    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeSnDialog() {
      this.serialNumberDialog = false;

      this.$nextTick(() => {
        this.editedSN = Object.assign({}, this.defaultSN);
      })
      this.findInvalidSerialNumbers();
    },
    calcGrandTotal() {
      this.activeRequest.grandTotal = Number(0);
      let items = this.activeRequest.items;

      for (let it of items) {
        it.total = (it.qtyOnHand * it.differencePerUnit).toFixed(2);
        this.activeRequest.grandTotal += Number(it.total);
      }
    },
    async saveRequest(isUploadSaved = true) {
      // persist to the backend
      this.saveDialog = false;
      console.log("Saving as draft...");

      this.calcGrandTotal();

      if (isUploadSaved) {
        // switch to uploadQueue
        if (this.uploadQueue.length > 0) {
          console.log("Let's upload");
          for (let i = 0; i < this.uploadQueue.length; i++) {
            if (await this.dealerStore.uploadAttachment(this.uploadQueue[i])) {
              console.log("Uploading now " + this.uploadQueue[i]);
            } else {
              console.log("Couldn't upload " + this.uploadQueue[i]);
            }
          }
          this.currentFile = null;
          this.uploadQueue = [];
        } else {
          console.log("Couldn't upload files from the queue.");
        }
      }

      await this.dealerStore.saveChangesToActiveRequest();
    },
    /**
     * Submit the request.
     */
    async submitRequest() {
      this.$emit('loading');
      console.log("Submitting request...");
      await this.saveRequest();

      this.submitDialog = false;
      if (await this.dealerStore.submitRequest()) {
        this.$emit('advance-step');
      }
      this.$emit('loaded');
    },
    async deleteActiveRequest() {
      await this.dealerStore.deleteActiveRequest();

      await this.$router.push('my-requests');
    },
    popAttachmentFromQueue(el) {
      let index = _.findIndex(this.uploadQueue, (e) => {
        return e === el;
      }, 0);

      this.uploadQueue = this.uploadQueue.toSpliced(index, 1);
    },
    pushAttachmentToQueue() {
      if (this.currentFile.size > process.env.VUE_APP_UPLOAD_MAX_BYTES) {
        alert('File size cannot exceed 10MB.');
      } else {
        if (!this.uploadQueue.includes(this.currentFile)) {
          this.uploadQueue.push(this.currentFile);
        }
      }
      this.currentFile = null;
      this.serialNumberDialog = false;
    },
    printWindow() {
      window.print();
    },
    async deleteAttachment(file) {
      const t = this.$t.bind(this)
      if (confirm(t('confirm.delete.attachment')) === true) {
        if (await this.dealerStore.deleteAttachment(file)) {
          await this.saveRequest(false);
        }
      }
    },
  }
});
</script>