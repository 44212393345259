<template>
	<v-container fluid app>
		<v-row class="text-center">
			<v-col cols="12" sm="12" class="mt-1 px-5">
				<v-card flat>
					<v-card-title class="pl-1 subtitle-2">
            {{ $t('labels.plans') }}
					</v-card-title>
					<v-data-table
							:headers="getPlanHeaders"
							:items="activePlans"
							:search="search"
							item-key="pid"
							class="elevation-0"
              :sort-by="['pid']"
              :sort-desc="[true]"
					>
						<template v-slot:[`item.lifeTime`]="{ item }">
							<div class="d-flex justify-space-evenly bg-surface-variant">
								{{ item.lifeTime[0] + ' - ' + item.lifeTime[1] }}
							</div>
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<router-link to="/dealer-plan" :title="$t('linkTitles.viewPlan')" class="text-decoration-none">
								<v-icon small @click="setActivePlan(item)">
									mdi-eye
								</v-icon>
							</router-link>
							<router-link to="" :title="$t('linkTitles.createRequestFromPlan')" class="text-decoration-none">
								<v-icon small class="mx-1" @click="createNewRequest(item)">
									mdi-plus-thick
								</v-icon>
							</router-link>
						</template>
					</v-data-table>
				</v-card>
				<v-card flat class="py-5">
					<v-divider></v-divider>
				</v-card>
				<v-card flat>
					<v-card-title class="pl-1 subtitle-2">MY REQUESTS</v-card-title>
					<template>
						<v-tabs
								v-model="tab"
								icons-and-text
								fixed-tabs
						>
							<v-tabs-slider></v-tabs-slider>

							<v-tab
									v-for="(item, i) in requestTypes"
									:key="i"
									class="pl-0"
									@click="setActiveGroup(item.text)"
							>
								{{ item.text }}
								<v-icon v-text="item.icon"></v-icon>
							</v-tab>
						</v-tabs>
					</template>

					<v-card flat class="pt-4">
						<v-data-table
								:headers="getRequestHeaders"
								:items="activeGroup"
								:search="search"
								item-key="id"
								class="elevation-0"
                :sort-by="['id']"
                :sort-desc="[true]"
						>
							<template v-slot:[`item.dateCreated`]="{ item }">
								<div>
									{{ item.dateCreated.split(" ")[0] }}
								</div>
							</template>

							<template v-slot:[`item.grandTotal`]="{ item }">
								<div class="d-flex justify-space-evenly bg-surface-variant">
									{{ item.grandTotal | toCAD }}
								</div>
							</template>

							<template v-slot:[`item.actions`]="{ item }">
								<v-icon small @click="setActiveRequestFromItem(item)">
									mdi-eye
								</v-icon>
							</template>

							<template v-slot:footer>
								<v-divider></v-divider>
								<v-card
										class="d-flex pr-4"
										elevation="0"
								>
									<v-spacer></v-spacer>
									<div>
										<v-chip label color="lightgrey darken-1" text-color="black" class="font-weight-medium my-3">
                      {{ $t('labels.grandTotal') }} {{ sumTotal | toCAD }}
										</v-chip>
									</div>
								</v-card>
							</template>
						</v-data-table>
					</v-card>
				</v-card>
			</v-col>
		</v-row>

		<v-overlay
				:opacity="0.75"
				:value="showOverlay"
		>
			<v-progress-circular indeterminate size="64">
        {{ $t('labels.loading') }}
			</v-progress-circular>
		</v-overlay>
	</v-container>
</template>

<script>
import {defineComponent} from "vue-demi";
import {useDealerStore} from "@/stores/dealer";
import {storeToRefs} from "pinia";
import {useUserStore} from "@/stores/user";

export default defineComponent({
	name: "DealerView",
	setup() {
		const dealerStore = useDealerStore();
		const {activePlan, activePlans, activeGroup, drafts} = storeToRefs(dealerStore);

		dealerStore.$reset();

		return {
			activePlans,
			activePlan,
			activeGroup,
			drafts,
			dealerStore,
		};
	},
	async mounted() {
    if (sessionStorage.dealerRequestGroup === undefined) {
      sessionStorage.dealerRequestGroup = 0;
    }

		const userStore = useUserStore();
		const {user} = storeToRefs(userStore);

		if (user.accessToken !== null) {
      await this.dealerStore.getActivePlansFromApi();
      await this.setActiveGroupWithSession();
		} else {
			await this.$router.push('home');
		}
	},
	methods: {
    async setActiveGroupWithSession() {
      const userStore = useUserStore();
      const {user} = storeToRefs(userStore);

      if (user.accessToken !== null) {
        console.log('Request Group in Session ' + sessionStorage.dealerRequestGroup);

        switch (sessionStorage.dealerRequestGroup) {
          case "1":
            await this.setActiveGroup("SUBMITTED");
            break;
          case "2":
            await this.setActiveGroup("AUDITED");
            break;
          case "3":
            await this.setActiveGroup("APPROVED");
            break;
          case "4":
            await this.setActiveGroup("REJECTED");
            break;
          case "0":
          default:
            await this.setActiveGroup("DRAFTS");
            break;
        }
      } else {
        await this.$router.push('/home');
      }
    },
		async setActivePlan(value) {
			const pid = value.pid;
			await this.dealerStore.setActivePlan(pid);
		},
		async setActiveRequestFromItem(item) {
			await this.setActiveRequest(item.id);
			await this.$router.push('dealer-request');
		},
		async setActiveRequest(pprNo) {
			this.activeRequest = await this.dealerStore.setActiveRequest(pprNo);
		},
		async setActiveRequestById(pid) {
			console.log("PID " + pid);
			await this.dealerStore.setActiveRequest(pid);
		},
		async createNewRequest(value) {
			const pid = value.pid;
			await this.dealerStore.createNewDealerRequest(pid);

			await this.setActiveRequestById(this.drafts[this.drafts.length - 1].id);

			await this.$router.push('dealer-request');
		},
		async setActiveGroup(type) {

      this.showOverlay = true;

			switch (type) {
				case "SUBMITTED": {
					await this.dealerStore.getUserSubmittedRequests();
					this.dealerStore.setSubmittedAsActiveGroup(true);
          this.tab = sessionStorage.dealerRequestGroup = 1;
					break;
				}
        case "AUDITED": {
          await this.dealerStore.getUserAuditedRequests();
          this.dealerStore.setAuditedAsActiveGroup(true);
          this.tab = sessionStorage.dealerRequestGroup = 2;
          break;
        }
				case "APPROVED": {
					await this.dealerStore.getUserApprovedRequests();
					this.dealerStore.setApprovedAsActiveGroup(true);
          this.tab = sessionStorage.dealerRequestGroup = 3;
					break;
				}
				case "REJECTED": {
					await this.dealerStore.getUserRejectedRequests();
					this.dealerStore.setRejectedAsActiveGroup(true);
          this.tab = sessionStorage.dealerRequestGroup = 4;
					break;
				}
				case "DRAFTS":
				default: {
					await this.dealerStore.getUserDraftRequests();
					this.dealerStore.setDraftsAsActiveGroup(true);
          this.tab = sessionStorage.dealerRequestGroup = 0;
					break;
				}
			}
			this.sumTotal = this.sumRequestTotals();

      this.showOverlay = false;
		},
		sumRequestTotals() {
			return this.activeGroup.reduce(
					(a, b) =>
							a + (b['grandTotal'] || 0)
					, 0);
		}
	},
	watch: {
		drafts: {
			async handler(newDrafts, oldDrafts) {
				if (oldDrafts.length === 0) {
					// do nothing; drafts just loaded
					console.log("Dealer Request Drafts loaded");
				} else if (newDrafts.length === (oldDrafts.length + 1)) {
					await this.setActiveRequestById(this.drafts[this.drafts.length - 1].id);
					await this.$router.push('dealer-request');
				}
			},
			flush: 'post'
		}
	},
	computed: {
		getPlanHeaders() {
			const t = this.$t.bind(this)
			return [
				{
					text: t('headers.planId'),
					align: "start",
					sortable: false,
					value: "pid",
				},
				{text: t('headers.name'), value: "name"},
				{text: t('headers.activeDates'), value: "lifeTime"},
				{
					text: t('headers.actions'),
					value: "actions",
					sortable: false,
				},
			]
		},
		getRequestHeaders() {
			const t = this.$t.bind(this)
			return [
				{
					text: t('headers.pprNo'),
					align: "start",
					value: "id",
				},
				{text: t('headers.planName'), value: "planName"},
				{text: t('headers.dateCreated'), value: "dateCreated"},
				{text: t('headers.totalCad'), value: "grandTotal"},
				{
					text: t('headers.actions'),
					value: "actions",
					sortable: false,
				},
			]
		}
	},
	data: () => ({
		tab: sessionStorage.dealerRequestGroup,
		search: "",
		showOverlay: true,
		text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
		selectedItem: 0,
		sumTotal: 0,
		requestTypes: [
			{
				text: "DRAFTS",
				icon: "mdi-file-edit"
			},
			{
				text: "SUBMITTED",
				icon: "mdi-file-move"
			},
      {
        text: "AUDITED",
        icon: "mdi-file-check-outline"
      },
			{
				text: "APPROVED",
				icon: "mdi-file-check"
			},
			{
				text: "REJECTED",
				icon: "mdi-file-remove"
			},
		],
	}),
});
</script>