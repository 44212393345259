<template>
  <v-container class="user-profile" fluid app>
    <v-card shaped elevation="2" class="mx-auto my-12 pa-4" max-width="500">
      <v-card-title class="black--text">
        <v-row class="mb-1" justify="center" align="center">
          <v-col cols="3">
            <v-avatar color="grey" size="70">
              <span>{{ user.customerCode }}</span>
            </v-avatar>
          </v-col>

          <v-col cols="9">
            <v-row>
              {{ user.firstName }} {{ user.lastName }} {{ getRole }}
            </v-row>
            <v-row class="text-body-1">
              {{ user.dealerName }}
            </v-row>
            <v-row class="text-body-2">
              {{ user.email }}
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-subtitle class="mt-3">
        <v-row>
          <v-btn @click="logout">LOG OUT</v-btn>
        </v-row>
      </v-card-subtitle>
    </v-card>
  </v-container>
</template>

<script>
import {storeToRefs} from "pinia";
import {useUserStore} from "@/stores/user";
import {defineComponent} from "vue-demi";

export default defineComponent({
  name: "UserProfile",
  setup() {
    const userStore = useUserStore();
    const {user} = storeToRefs(userStore);

    return {
      user,
      userStore
    };
  },
  methods: {
    logout() {
      this.userStore.$reset();

      this.$keycloak.logout({redirectUri: this.redirectUrl}).then((success) => {
        console.log("--> log: logout success ", success);
      }).catch((error) => {
        console.log("--> log: logout error ", error);
      });
    }
  },
  computed: {
    getRole() {
      let role = '';

      this.userStore.isApprover ? role = 'approver' : this.userStore.isAuditor ? role = 'auditor' : this.userStore.isDealer ? role = 'dealer' : '';

      if (role !== '') {
        role = '(' + role + ')';
      }

      return role;
    },
  }
});
</script>