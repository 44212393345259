<template>
  <div v-if="activePlan !== null">
    <v-form
        ref="form"
        v-model="valid">
      <v-card class="my-4" elevation="0">
        <v-list-item>
          <v-list-item-content class="pb-0">
            <div class="text-overline mb-1">Price Protection Plan</div>

            <v-list-item-subtitle class="mt-0">
              Plan ID {{ activePlan.pid }}
            </v-list-item-subtitle>
            <v-list-item-title>
              <v-text-field
                  v-model="activePlan.name"
                  :rules="[rules.required]"
              ></v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="py-0 my-0 mx-0 px-0">
            <v-row>
              <v-col cols="12" sm="6">
                <v-menu
                    ref="dateRangeMenu"
                    v-model="dateRangeMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="activePlan.lifeTime[0]"
                        :label="$t('labels.startDate')"
                        prepend-icon="mdi-calendar-month-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="activePlan.lifeTime[0]"
                      @input="dateRangeMenu = false"
                      @change="updateEndDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                    v-model="endDateDialog"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="activePlan.lifeTime[1]"
                        :label="$t('labels.endDate')"
                        prepend-icon="mdi-calendar-month-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="activePlan.lifeTime[1]"
                      @input="endDateDialog = false"
                      :min=calcMinEndDate
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-card>

      <v-data-table
          :headers="getHeaders"
          :items="activePlan.items"
          sort-by="itemCode"
          class="elevation-0 mb-5"
          @current-items="currentItems"
      >
        <template v-slot:top>
          <v-toolbar flat class="pr-0">
            <v-spacer></v-spacer>
            <v-form
                ref="newItemForm"
                v-model="newItemDialogValid"
            >
              <v-dialog v-model="newItemDialog" persistent max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="clear">
                    {{ $t("buttons.newItem") }}
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                    <v-dialog
                        v-model="productSearchAlert"
                        width="500"
                    >
                      <v-card>
                        <v-card-title class="grey lighten-2">
                          {{ $t("vcard.badSearchResults.title") }}
                        </v-card-title>
                        <v-card-text>
                          {{ $t("vcard.badSearchResults.text") }}
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                              v-model="editedItem.itemCode"
                              :rules="[rules.required, rules.itemCode]"
                              type="alphanumeric"
                              :label="$t('labels.itemCode')"
                              @keydown.enter.prevent="getProduct"
                              @blur="getProduct"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                              v-model="editedItem.creditCode"
                              :rules="[rules.required]"
                              :label="$t('labels.creditCode')"
                              readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                              v-model="editedItem.description"
                              :rules="[rules.required]"
                              :label="$t('labels.description')"
                              readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                              v-model="editedItem.lastPromo"
                              type="number"
                              :rules="[rules.required]"
                              :label="$t('labels.lastPromoCad')"
                              min="0"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                              v-model="editedItem.newPromo"
                              type="number"
                              :rules="[rules.required]"
                              :label="$t('labels.newPromoCad')"
                              min="0"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      {{ $t("buttons.cancel") }}
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save" :disabled="!newItemDialogValid">
                      {{ $t("buttons.save") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
            <v-dialog v-model="deleteItemDialog" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                >Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    {{ $t("buttons.cancel") }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    {{ $t("buttons.ok") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item.lastPromo`]="{ item }">
          <div>
            {{ item.lastPromo | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.newPromo`]="{ item }">
          <div>
            {{ item.newPromo | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.unitPrice`]="{ item }">
          <div>
            {{ item.unitPrice | toCAD }}
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" :title="$t('iconTitles.editItem')" @click="editItem(item)">
            mdi-pencil-outline
          </v-icon>
          <v-icon small :title="$t('iconTitles.deleteItem')" @click="deleteItem(item)"> mdi-trash-can-outline
          </v-icon>
        </template>
      </v-data-table>
    </v-form>

    <v-divider></v-divider>

    <v-row class="pt-5 pb-3">
      <v-col cols="12" class="text-center">
        <v-btn @click="printWindow" :titles="$t('linkTitles.printWindow')" class="mr-4 mb-4">
          {{ $t("buttons.print") }}
        </v-btn>
        <v-dialog v-model="saveDialog" persistent max-width="350">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" v-bind="attrs" v-on="on" class="mr-4 mb-4">
              {{ $t("buttons.save") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("vcard.savePlan.title") }}
            </v-card-title>
            <v-card-text>
              {{ $t("vcard.savePlan.text") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="saveDialog = false">
                {{ $t("buttons.cancel") }}
              </v-btn>
              <v-btn color="red darken-1" text @click="savePlan();">
                {{ $t("buttons.yes") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="submitDialog" persistent max-width="350">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on"
                   :disabled="disableSubmit" class="mr-4 mb-4">
              {{ $t("buttons.submit") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("vcard.submitPlanForApproval.title") }}
            </v-card-title>
            <v-card-text>
              {{ $t("vcard.submitPlanForApproval.text") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="submitDialog = false">
                {{ $t("buttons.disagree") }}
              </v-btn>
              <v-btn color="red darken-1" text @click="submitPlan()">
                {{ $t("buttons.agree") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn class="mr-4 mb-4">
          <router-link to="/existing-plans" :title="$t('linkTitles.backToExistingPlans')"
                       class="text-decoration-none">
            {{ $t("buttons.back") }}
          </router-link>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-dialog v-model="deletePlanDialog" persistent max-width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="red darken-2" large :title="$t('iconTitles.deletePlan')" v-bind="attrs"
                    v-on="on">
              mdi-delete-circle
            </v-icon>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("vcard.deletePlan.title") }}
            </v-card-title>
            <v-card-text>
              {{ $t("vcard.deletePlan.text") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="deletePlanDialog = false">
                {{ $t("buttons.cancel") }}
              </v-btn>
              <v-btn color="red darken-1" text @click="deleteActivePlan">
                {{ $t("buttons.delete") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {storeToRefs} from "pinia";
import {useExistingPlansStore} from "@/stores/existingplans";
import {defineComponent} from "vue-demi";
import {useUserStore} from "@/stores/user";
import _ from "underscore";
import compareAsc from 'date-fns/compareAsc';
import add from 'date-fns/add';
import format from 'date-fns/format';


export default defineComponent({
  name: "PlanDraft",
  setup() {
    const plansStore = useExistingPlansStore();
    const {activePlan, approver} = storeToRefs(plansStore);
    const userStore = useUserStore();
    let {approvers} = storeToRefs(userStore);

    console.log("PlanDraft setup");

    return {
      activePlan,
      plansStore,
      approvers,
      approver
    };
  },
  beforeRouteEnter(to, from, next) {
    const userStore = useUserStore();
    const {user} = storeToRefs(userStore);

    const {authorize} = to.meta;
    const roles = user.value.roles;

    if (_.intersection(authorize, roles).length > 0) {
      next();
    } else {
      return false;
    }
  },
  data: () => ({
    productSearchAlert: false,
    dateRangeMenu: false,
    deletePlanDialog: false,
    newItemDialog: false,
    deleteItemDialog: false,
    saveDialog: false,
    submitDialog: false,
    endDateDialog: false,
    valid: true,
    newItemDialogValid: true,
    rowCount: 0,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    headers: [
      {
        text: "Item Code",
        align: "start",
        sortable: false,
        value: "itemCode",
      },
      {text: "Description", value: "description"},
      {text: "Credit Code", value: "creditCode"},
      {text: "Last Promo (CAD)", value: "lastPromo"},
      {text: "New Promo (CAD)", value: "newPromo"},
      {text: "Diff/Unit (CAD)", value: "unitPrice"},
      {text: "Actions", value: "actions", sortable: false},
    ],
    plan: {},
    editedIndex: -1,
    editedItem: {
      itemCode: "0000000000",
      description: "et netus et malesuada fames",
      creditCode: "00000000",
      lastPromo: "0",
      newPromo: "0",
      unitPrice: "0"
    },
    defaultItem: {
      itemCode: "0000000000",
      description: "et netus et malesuada fames",
      creditCode: "00000000",
      lastPromo: "0",
      newPromo: "0",
      unitPrice: "0"
    },
    blankItem: {
      itemCode: "",
      description: "",
      creditCode: "",
      lastPromo: "",
      newPromo: "",
      unitPrice: ""
    },
    rules: {
      required: (value) => !!value || "Required.",
      currency: (value) =>
          (!!value && !isNaN(value)) || "Please enter a valid currency value.",
      itemCode: (value) =>
          /^[a-zA-Z0-9_-]*$/.test(value) ||
          "Item code allows alphanumeric and hyphen characters.",
      creditCode: (value) =>
          /^[a-zA-Z0-9_]*$/.test(value) ||
          "Credit code allows alphanumeric and hyphen characters.",
      lifeTime: () =>
          (this.activePlan.lifeTime.length == 2 && !!this.activePlan.lifeTime[1]) ||
          "Please select an ending date.",
      requiredSelect: v => Boolean(Object.values(v || {})[0]) || 'Please select an approver.',
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    formattedLifeTime() {
      return "[" + this.activePlan.lifeTime[0] + ", " + this.activePlan.lifeTime[1] + "]";
    },
    disableSubmit() {
      return !this.valid || this.rowCount <= 0;
    },
    calcMinEndDate() {
      return format(add(new Date(this.activePlan.lifeTime[0]), {days: 2}), 'yyyy-MM-dd');
    },
    getHeaders() {
      const t = this.$t.bind(this)
      return [
        {
          text: t('headers.itemCode'),
          align: "start",
          sortable: false,
          value: "itemCode",
        },
        {text: t('headers.description'), value: "description"},
        {text: t('headers.creditCode'), value: "creditCode"},
        {text: t('headers.lastPromoCad'), value: "lastPromo"},
        {text: t('headers.newPromoCad'), value: "newPromo"},
        {text: t('headers.diffUnitCad'), value: "unitPrice"},
        {text: t('headers.actions'), value: "actions", sortable: false},
      ]
    }
  },

  watch: {
    newItemDialog(val) {
      val || this.close();
    },
    deleteItemDialog(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.plan = this.activePlan;
    },
    updateEndDate() {
      const startDate = format(new Date(this.activePlan.lifeTime[0]), 'yyyy-MM-dd');
      const endDate = format(new Date(this.activePlan.lifeTime[1]), 'yyyy-MM-dd');

      console.log("start " + startDate);
      console.log("end " + endDate);

      let dateResult = compareAsc(new Date(startDate), new Date(endDate));
      if (dateResult <= 0) {
        this.activePlan.lifeTime[1] = format(add(new Date(this.activePlan.lifeTime[0]), {days: 30}), 'yyyy-MM-dd');
      } else {
        this.activePlan.lifeTime[0] = this.activePlan.lifeTime[1];
        this.activePlan.lifeTime[1] = format(add(new Date(this.activePlan.lifeTime[0]), {days: 30}), 'yyyy-MM-dd');
      }
    },
    currentItems: function (value) {
      this.rowCount = value.length;
    },
    editItem(item) {
      this.editedIndex = this.activePlan.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.newItemDialog = true;
    },
    async getProduct() {
      this.editedItem.itemCode = this.editedItem.itemCode.toUpperCase();

      const itemCode = this.editedItem.itemCode;
      const productResponse = await this.plansStore.getProduct(itemCode);

      try {
        this.editedItem.itemCode = String(productResponse.id.itemCode).trim()
        this.editedItem.description = String(productResponse.itemName).trim();
        this.editedItem.creditCode = String(productResponse.creditCode).trim();
      } catch (e) {
        this.productSearchAlert = true;
        this.editedItem.description = "";
        this.editedItem.creditCode = "";
      }
    },
    async deleteItem(item) {
      this.editedIndex = this.activePlan.items.indexOf(item);

      await this.plansStore.deletePlanItem(item.id);

      this.editedItem = Object.assign({}, item);
      this.deleteItemDialog = true;
    },

    deleteItemConfirm() {
      this.activePlan.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    clear() {
      this.$refs.newItemForm.resetValidation();
      this.$refs.newItemForm.reset();

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.blankItem);
        this.editedIndex = -1;
      });
    },
    close() {
      this.newItemDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.deleteItemDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    /**
     * Save plan item to plan
     */
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.activePlan.items[this.editedIndex], this.editedItem);
      } else {
        this.activePlan.items.push(this.editedItem);
      }

      this.plansStore.addPlanItem(this.editedIndex);

      this.close();
    },
    savePlan() {
      // persist to the backend
      this.saveDialog = false;
      console.log("Saving as draft...");

      this.plansStore.saveChangesToActivePlan();
    },
    /**
     * Submit the plan.
     */
    async submitPlan() {
      this.submitDialog = false;
      console.log("Submitting plan...");

      if (await this.plansStore.submitPlan()) {
        this.$emit('advance-step');
      } else {
        console.log("Cannot submit plan");
      }
    },
    async deleteActivePlan() {
      await this.plansStore.deleteCurrentPlan();
      await this.$router.push('existing-plans');
    },
    printWindow() {
      window.print();
    }
  },
});
</script>