<template>
  <div v-if="activePlan.pid !== undefined">
    <v-card class="my-0" elevation="0">
      <v-list-item class="pr-0">
        <v-list-item-content class="pb-0">
          <div class="text-overline mb-1 pt-2">Price Protection Plan
          </div>
          <v-list-item-title class="text-h6">
            {{ activePlan.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="my-2">
            Plan ID {{ activePlan.pid }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="py-0 my-0 mx-0 px-0">
          <v-row>
            <v-col cols="12" sm="6" class="pl-0">
              <v-menu
                  ref="dateRangeMenu"
                  v-model="dateRangeMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" class="ml-0 pl-1">
                    <v-icon right dark class="mr-2">
                      mdi-calendar-month-outline
                    </v-icon>
                    {{ formattedLifeTime }}
                  </v-btn>
                </template>
                <v-date-picker
                    v-model="activePlan.lifeTime"
                    range
                    readonly
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <v-data-table
        :headers="getHeaders"
        :items="activePlan.items"
        sort-by="itemCode"
        class="elevation-0 mb-5"
    >
      <template v-slot:[`item.lastPromo`]="{ item }">
        <div>
          {{ item.lastPromo | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.newPromo`]="{ item }">
        <div>
          {{ item.newPromo | toCAD }}
        </div>
      </template>

      <template v-slot:[`item.unitPrice`]="{ item }">
        <div>
          {{ item.unitPrice | toCAD }}
        </div>
      </template>
    </v-data-table>
    <v-divider></v-divider>

    <v-row class="pt-5 pb-3">
      <v-col cols="12" class="text-center">
        <v-btn @click="printWindow" :titles="$t('linkTitles.printWindow')" class="mr-4 mb-4">
          {{ $t("buttons.print") }}
        </v-btn>

        <v-btn class="mr-4 mb-4">
          <router-link to="/my-requests" :title="$t('linkTitles.backToMyRequests')"
                       class="text-decoration-none">
            {{ $t("buttons.back") }}
          </router-link>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {storeToRefs} from "pinia";
import {defineComponent} from "vue-demi";
import {useDealerStore} from "@/stores/dealer";

export default defineComponent({
  name: "DealerPlan",
  setup() {
    const dealerStore = useDealerStore();
    let {activePlan} = storeToRefs(dealerStore);

    console.log("DealerPlan setup");

    return {
      activePlan
    };
  },
  data: () => ({
    dateRangeMenu: false,
    submitDialog: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    defaultItem: {
      pid: "0000000",
      name: "",
      lifeTime: ["01-01-1970", "01-31-1970"],
      status: 1,
      items: [],
    },
  }),

  computed: {
    formattedLifeTime() {
      return this.activePlan.lifeTime[0] + ", " + this.activePlan.lifeTime[1];
    },
    getHeaders() {
      const t = this.$t.bind(this);
      return [
        {
          text: t('headers.itemCode'),
          align: "start",
          sortable: false,
          value: "itemCode",
        },
        {text: t('headers.description'), value: "description"},
        {text: t('headers.creditCode'), value: "creditCode"},
        {text: t('headers.lastPromoCad'), value: "lastPromo"},
        {text: t('headers.newPromoCad'), value: "newPromo"},
        {text: t('headers.diffUnitCad'), value: "unitPrice"},
      ]
    }
  },

  methods: {
    printWindow() {
      window.print();
    }
  }
});
</script>