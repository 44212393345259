import {defineStore} from 'pinia'
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import {useUserStore} from "@/stores/user";

export const useDealerStore = defineStore('dealerStore', {
    state: () => ({
        activePlans: [],
        activePlan: {},
        activeGroup: [],
        activeRequest: {},
        drafts: [],
        submitted: [],
        audited: [],
        approved: [],
        rejected: [],
    }),
    data: () => ({
        t: this.$t.bind(this)
    }),
    actions: {
        async deleteAttachment(filename) {
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const id = this.activeRequest.id;

                const response = await axios.delete(
                    userStore.apiUrl + 'requests/' + id + '/attachments?filename=' + encodeURIComponent(filename),
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        },
                        validateStatus: () => true
                    });

                if (response.status === 200) {
                    console.log('Attachment deleted successfully');
                    this.activeRequest.attachmentFilePath = response.data;
                    return true;
                } else {
                    console.log('Delete attachment failed');
                    this.activeRequest.attachmentFilePath = response.data;
                }

            } catch (e) {
                console.log(e);
            }
            return false;
        },
        async downloadAttachment() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                responseType: 'arraybuffer',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'responseType': 'blob'
                }
            };

            try {
                axios
                    .get(
                        userStore.apiUrl + 'requests/' + this.activeRequest.id + '/attachments',
                        options
                    )
                    .then(response => {

                        let blob = new Blob(
                            [response.data],
                            {type: response.headers['content-type']}
                        )

                        let imgUrl = URL.createObjectURL(blob)
                        let a = document.createElement('a');
                        a.href = imgUrl;
                        a.download = "pprno" + this.activeRequest.id + "_download.zip";
                        a.click();
                        window['URL'].revokeObjectURL(imgUrl);
                    })
                    .catch(error => {
                        alert("something goes wrong! Maybe image url broken, try another img url." + error)
                    })
            } catch (e) {
                console.log(e.message);
            }
        },
        async removeAttachment() {
            // this isn't good since we aren't deleting all the attachments on the backend.
            this.activeRequest.attachmentFilePath = null;

            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const id = this.activeRequest.id;
                const response = await axios.put(
                    userStore.apiUrl + 'requests/' + id,
                    this.activeRequest,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });

                if (response.status === 200) {
                    console.log('Filepath updated successfully');
                }
            } catch (e) {
                console.log(e);
                return false;
            }
        },
        async uploadAttachment(attachment) {
            console.log("Uploading attachment " + attachment);
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                let formData = new FormData();

                const id = this.activeRequest.id;

                formData.append("sn-attachment", attachment);

                const filePath = 'requests/' + id + '/attachments';

                const response = await axios.post(
                    userStore.apiUrl + filePath,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            'Authorization': 'Bearer ' + accessToken
                        },
                        validateStatus: () => true
                    });

                if (response.status === 200) {
                    // TODO change the attachment chip to reflect the upload
                    this.activeRequest.attachmentFilePath = response.data;
                    console.log('Attachment uploaded successfully');
                } else {
                    this.activeRequest.attachmentFilePath = response.data;
                    return false;
                }
            } catch (e) {
                console.log(e);
                return false;
            }
            return true;
        },
        async deleteActiveRequest() {
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const id = this.activeRequest.id;
                const type = this.activeRequest.requestStatus;

                const response = await axios.delete(
                    userStore.apiUrl + 'requests/' + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });

                if (response.status === 200) {
                    console.log('Request deleted successfully');

                    try {
                        let response = await axios.get(
                            userStore.apiUrl + 'requests/status/' + type,
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + accessToken
                                }
                            });
                        this.activeGroup = response.data;
                    } catch (e) {
                        console.log(e.message);
                    }

                    const clearFlag = false;
                    this.setDraftsAsActiveGroup(clearFlag);
                }

            } catch (e) {
                console.log(e);
            }
        },
        async createNewDealerRequest(planId) {
            let response;

            moment().format('YYYY-MM-DD');

            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;

                const skeletonRequest = {
                    startDate: moment().toISOString().slice(0, 10),
                    endDate: moment().add(30, 'days').toISOString().slice(0, 10),
                    customerCode: userStore.user.customerCode,
                    dealerName: userStore.user.dealerName
                };

                response = await axios.post(userStore.apiUrl + 'requests?planId=' + planId,
                    skeletonRequest,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });

                if (response.status === 200) {
                    console.log('Request created successfully');

                    this.drafts = _.concat(this.drafts, response.data);

                    const clearFlag = false;
                    this.setDraftsAsActiveGroup(clearFlag);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async getActivePlansFromApi() {
            // only get active National Plans
            let response;
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;

                response = await axios.get(
                    userStore.apiUrl + 'dealer-plans?type=active',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken,
                        }
                    }
                );

                this.activePlans = response.data;
            } catch (e) {
                console.error(e);
            }

            this.activePlan = this.activePlans[0];
        },
        async getUserDraftRequests() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(
                    userStore.apiUrl + 'requests?type=drafts',
                    options);
                this.drafts = response.data;
            } catch (e) {
                console.error(e);
            }
        },
        async getUserAuditedRequests() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(
                    userStore.apiUrl + 'requests?type=audited',
                    options);
                this.audited = response.data;
            } catch (e) {
                console.error(e);
            }
        },
        async getUserSubmittedRequests() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(
                    userStore.apiUrl + 'requests?type=submitted',
                    options);
                this.submitted = response.data;
            } catch (e) {
                console.error(e);
            }
        },
        async getUserApprovedRequests() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(
                    userStore.apiUrl + 'requests?type=allapproved',
                    options);
                this.approved = response.data;
            } catch (e) {
                console.error(e);
            }
        },
        async getUserRejectedRequests() {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(
                    userStore.apiUrl + 'requests?type=rejected',
                    options);
                this.rejected = response.data;
            } catch (e) {
                console.error(e);
            }
        },
        async setActivePlan(pid) {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(
                    userStore.apiUrl + 'plans/' + pid,
                    options
                );
                this.activePlan = response.data;
            } catch (e) {
                console.log(e.message);
                this.activePlan = [];
            }
        },
        async submitRequest() {
            try {
                const id = this.activeRequest.id;
                this.activeRequest.requestStatus = '2';

                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const response = await axios.put(
                    userStore.apiUrl + 'requests/' + id,
                    this.activeRequest,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });

                if (response.status === 200) {
                    // todo maybe do this instead -> call getRequestsFromApi()
                    console.log('Record submitted successfully');

                    _.remove(this.activeGroup, {'id': id});

                    this.submitted = _.concat(this.submitted, response.data);

                    this.activeRequest = response.data;

                    const clearFlag = false;
                    this.setSubmittedAsActiveGroup(clearFlag);

                    return true;
                } else {
                    console.error(JSON.stringify(response));
                    this.activeRequest.status = '1';
                }
            } catch (e) {
                // roll back
                this.activeRequest.status = '1';
                console.log(e.message);
            }
            return false;
        },
        async saveChangesToActiveRequest() {
            try {
                const userStore = useUserStore();
                const accessToken = userStore.user.accessToken;
                const id = this.activeRequest.id;
                let cleanActiveRequest = this.activeRequest;

                cleanActiveRequest.lastModifiedByUser = userStore.user.username;


                // HACK. Needed to add a fake/temp id to the serial number
                // because the NEW serial numbers kept having the same typed-in
                // value.
                for (let i = 0; i < cleanActiveRequest.items.length; i++) {
                    let item = cleanActiveRequest.items[i];
                    for (let j = 0; j < item.serialNumbers.length; j++) {
                        let sn = item.serialNumbers[j];
                        if (sn.isDirty === true) {
                            sn.id = null;
                        }
                    }
                }

                return await axios.put(
                    userStore.apiUrl + 'requests/' + id,
                    cleanActiveRequest,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });
            } catch (e) {
                console.log(e.message);
            }
        },
        async setActiveRequest(requestId) {
            const userStore = useUserStore();
            const accessToken = userStore.user.accessToken;
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let response;
            try {
                response = await axios.get(
                    userStore.apiUrl + 'requests/' + requestId,
                    options
                );
                this.activeRequest = response.data;
            } catch (e) {
                console.log(e.message);
                this.activeRequest = [];
            }
        },
        setDraftsAsActiveGroup(clear) {
            this.activeGroup = this.drafts;
            if (clear) this.clearActiveRequest();
        },
        setSubmittedAsActiveGroup(clear) {
            this.activeGroup = this.submitted;
            if (clear) this.clearActiveRequest();
        },
        setAuditedAsActiveGroup(clear) {
            this.activeGroup = this.audited;
            if (clear) this.clearActiveRequest();
        },
        setApprovedAsActiveGroup(clear) {
            this.activeGroup = this.approved;
            if (clear) this.clearActiveRequest();
        },
        setRejectedAsActiveGroup(clear) {
            this.activeGroup = this.rejected;
            if (clear) this.clearActiveRequest();
        },
        clearActiveRequest() {
            this.activeRequest = null;
        }
    }
})
