/* eslint-disable */
<template>
  <LandingPage />
</template>

<script>
import LandingPage from "@/components/LandingPage.vue";

export default {
  name: "HomeVue",

  components: {
    LandingPage,
  },
};
</script>
